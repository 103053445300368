import React, { useContext, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../Tax.css";
import circle from "../../../assets/icons/circle.png";
import rigth from "../../../assets/icons/rigth.png";
import save from "../../../assets/icons/save.png";
import cancel from "../../../assets/icons/cross.png";
import Sidebar from "../../../Master/layout/Sidebar";
import { Context } from "../../../../../utils/context";

const Add = () => {
  const { getEditData, editData, minLength, maxLength, getData } =
    useContext(Context);
  const navigate = useNavigate();

  const { id } = useParams();
  const [data, setData] = useState();

  const [formData, setFormData] = useState({
    name: "",
  });

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const validateForm = () => {
    let errors = {};

    // const regex = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    // if (!formData.name.trim()) {
    //   errors.name = "Property Type Name is required";
    // } else if (!regex.test(formData.name)) {
    //   errors.name = "Enter Valid Property Type Name";
    // }

    // const regex = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;

    // if (!formData.name) {
    //   errors.name = "property type is required";
    // } else if (!regex.test(formData.name.trim())) {
    //   errors.name = "enter valid property type";
    // } else if (
    //   formData.name.trim().length < minLength ||
    //   formData.name.trim().length > maxLength
    // ) {
    //   errors.name = `property type must be between ${minLength} and ${maxLength} characters`;
    // }

    const regex = /^[\p{L}\p{M}0-9]+(\s[\p{L}\p{M}0-9]+)*$/u;

    countLang.forEach((field) => {
      // Check if the field with the current id is missing in the formData object
      if (!formData[field.id]) {
        // If missing, set an error message for that field in the errors object
        errors[field.id] = "property type is required";
      } else {
        // If the field is present in formData, perform additional validations
        const trimmedName = formData[field.id].trim();

        // if (!regex.test(trimmedName)) {
        //   errors[field.id] = "Enter a valid property type";
        // } else
        if (trimmedName.length < minLength || trimmedName.length > maxLength) {
          errors[
            field.id
          ] = `property type must be between ${minLength} and ${maxLength} characters`;
        }
      }
    });

    return errors;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const response = await editData(
          `/master/taskmaster/propertytype/editpropertytype/${id}`,
          formData
        );

        if (response.success) {
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            navigate("/tax-master/propertytype");
          }, 1000);
        } else {
          setShowErrorModal(true);
          setTimeout(() => {
            setShowErrorModal(false);
            navigate(`/tax-master/editpropertytype/${id}`);
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const GetEditData = async () => {
    const response = await getEditData(
      `/master/taskmaster/propertytype/geteditPropertyType/${id}`
    );
    setData(response);
    setFormData(response?.data);
    getLanguges();
  };

  const [countLang, setCountLang] = useState([]);

  const getLanguges = async () => {
    const response = await getData(`/api/getlanguages`);
    await setCountLang(response.data.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  return (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container">
            <div className="row">
              <Sidebar />
              <div className="col-md-9">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="Back  me-4">
                        <Link to="/tax-master/propertytype">
                          <button type="button" className="btn btn-back">
                            <img src={rigth} className="rigth" alt="" /> Back{" "}
                          </button>
                        </Link>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <form onSubmit={handleSubmit} role="form">
                        <div className="row">
                          <div className="col-lg-8 col-md-10 mt-5 mx-auto Add-content">
                            <div className="row ">
                              <div className="col-md-12">
                                {countLang?.map((data, index) => {
                                  return (
                                    <div
                                      className="main-form-section mt-3 "
                                      key={index}
                                    >
                                      <div className="row justify-content-center ">
                                        <label
                                          htmlFor="inputEmail3"
                                          className="col-sm-3 col-form-label"
                                        >
                                          Edit Property Type {data?.name}
                                        </label>
                                        <div className="col-sm-6">
                                          <input
                                            type="text"
                                            name={data.id}
                                            value={formData[data.id]}
                                            placeholder={`Enter property type in ${data?.name}`}
                                            onChange={handleChange}
                                            className="form-control"
                                            id="inputEmail3"
                                          />
                                          {errors[data.id] && (
                                            <span style={errorStyle}>
                                              {errors[data.id] +
                                                " in " +
                                                data.name}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            <div className="row  mt-5 pb-3">
                              <div className="d-flex justify-content-center">
                                <Link to="/tax-master/propertytype">
                                  <button
                                    type="button"
                                    className="btn btn-cancel me-2"
                                  >
                                    {" "}
                                    <img
                                      src={cancel}
                                      className="cancel-img"
                                      alt=""
                                    />{" "}
                                    Cancel
                                  </button>
                                </Link>
                                <button type="submit" className="btn btn-save">
                                  {" "}
                                  <img
                                    src={save}
                                    className="save-img me-2"
                                    alt=""
                                  />
                                  Save
                                </button>
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Property Type Updated Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Property Type Already Exits
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;
