// import React from "react";
// import LoginPage from "./LoginPage/LoginPage.js";
// import { useContext } from "react";
// import { Context } from "../../../utils/context.js";
// import { useNavigate } from "react-router-dom";
// import { Fixd_Usertype } from "../../../utils/common.js";

// const Login = () => {
//   const { signin, usertype } = useContext(Context);
//   const navigate = useNavigate();

//   return (
//     <div>
//       {signin ? (
//         usertype === Fixd_Usertype.SuperAdmin ? (
//           navigate("/dashboard")
//         ) : usertype === Fixd_Usertype.DataEntry ? (
//           navigate("/generatedbill")
//         ) : (
//           navigate("/data-entry")
//         )
//       ) : (
//         <LoginPage />
//       )}
//     </div>
//   );
// };

// export default Login;

// -------------------------------------------------------------------------------

import React, { useContext, useEffect } from "react";
import LoginPage from "./LoginPage/LoginPage.js";
import { Context } from "../../../utils/context.js";
import { useNavigate } from "react-router-dom";
import { Fixd_Usertype } from "../../../utils/common.js";

const Login = () => {
  const { signin, usertype } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    if (signin) {
      switch (usertype) {
        case Fixd_Usertype.SuperAdmin:
          navigate("/dashboard");
          break;
        case Fixd_Usertype.DataEntry:
          navigate("/generatedbill");
          break;
        default:
          navigate("/data-entry");
      }
    }
  }, [signin, usertype, navigate]);

  return <div>{!signin && <LoginPage />}</div>;
};

export default Login;
