// import React, { useState } from "react";
// import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
// import "../HeaderDashBoard/HeaderDashBoard.css";
// import setting from "../images/setting.png";
// import layer from "../images/layer.png";
// import { Nav } from "react-bootstrap";
// import { useEffect } from "react";
// import arrowleft from "../../assets/icons/arrow-left.png";
// import { useContext } from "react";
// import { Context } from "../../../../utils/context";
// import Cookies from "js-cookie";
// import { Fixd_Usertype } from "../../../../utils/common";

// const HeaderDashBoard = (props) => {
//   const location = useLocation();
//   const [headerText, setHeaderText] = useState(location.pathname);

//   const { setUserData, setUsertype, setSignin, usertype } = useContext(Context);

//   const navigate = useNavigate();

//   useEffect(() => {
//     setHeaderText(location.pathname);
//   });

//   const LogOut = async () => {
//     Cookies.remove("billing_security", { path: "/" });
//     await setUserData("");
//     await setUsertype("");
//     await setSignin(false);
//     navigate("/");
//   };

//   return (
//     <>
//       <section className="Header-main">
//         <div className="container-fluid p-0">
//           <div className="main-header">
//             <div className="white-header"></div>
//             <div className="Green-header">
//               <div className="container-fluid set1">
//                 <div className="main-navbar">
//                   {/* header nav bar started */}
//                   <nav className="navbar navbar-expand-lg">
//                     <div className="container-fluid  ">
//                       <Link className="navbar-brand" href="#"></Link>
//                       <button
//                         className="navbar-toggler"
//                         type="button"
//                         data-bs-toggle="collapse"
//                         data-bs-target="#navbarNavDropdown"
//                         aria-controls="navbarNavDropdown"
//                         aria-expanded="false"
//                         aria-label="Toggle navigation"
//                       >
//                         <span className="navbar-toggler-icon"></span>
//                       </button>
//                       <div
//                         className="collapse navbar-collapse"
//                         id="navbarNavDropdown"
//                       >
//                         <ul className="navbar-nav justify-content-center d-flex">
//                           {usertype && usertype === Fixd_Usertype.SuperAdmin ? (
//                             <NavLink
//                               className={
//                                 headerText.includes("/tax-master")
//                                   ? "butt-decoration active"
//                                   : "butt-decoration"
//                               }
//                               to="/tax-master/languages"
//                             >
//                               <div className="a">
//                                 <li className="nav-item">
//                                   <a
//                                     className="nav-link"
//                                     aria-current="page"
//                                     href="#"
//                                   >
//                                     Master - Tax Management
//                                   </a>
//                                 </li>
//                               </div>
//                             </NavLink>
//                           ) : (
//                             <></>
//                           )}

//                           <NavLink className="butt-decoration" to="/data-entry">
//                             <li className="nav-item">
//                               <a
//                                 className="nav-link menu"
//                                 aria-current="page"
//                                 href="#"
//                               >
//                                 Reports
//                               </a>
//                             </li>
//                           </NavLink>

//                           {usertype && usertype === Fixd_Usertype.SuperAdmin ? (
//                             <NavLink
//                               className="butt-decoration"
//                               to="/counterwise"
//                             >
//                               {" "}
//                               <li className="nav-item">
//                                 <a
//                                   className="nav-link menu"
//                                   aria-current="page"
//                                   href="#"
//                                 >
//                                   Collection Reports
//                                 </a>
//                               </li>
//                             </NavLink>
//                           ) : (
//                             <></>
//                           )}
//                           {usertype && usertype === Fixd_Usertype.SuperAdmin ? (
//                             <NavLink
//                               className="butt-decoration"
//                               to="/rolemanagement"
//                             >
//                               <li className="nav-item">
//                                 <a
//                                   className="nav-link menu"
//                                   aria-current="page"
//                                   href="#"
//                                 >
//                                   Role Management
//                                 </a>
//                               </li>
//                             </NavLink>
//                           ) : (
//                             <></>
//                           )}
//                           <NavLink className="butt-decoration" to="/setting">
//                             {" "}
//                             <li className="nav-item dropdown">
//                               <a
//                                 className="nav-link dropdown-toggle"
//                                 href="#"
//                                 role="button"
//                                 data-bs-toggle="dropdown"
//                                 aria-expanded="false"
//                               >
//                                 Settings
//                               </a>
//                               <ul className="dropdown-menu">
//                                 {usertype &&
//                                 usertype === Fixd_Usertype.SuperAdmin ? (
//                                   <>
//                                     <Link
//                                       className="dropdown-item"
//                                       to="/company"
//                                     >
//                                       {" "}
//                                       <li>
//                                         <img
//                                           src={setting}
//                                           className="nutri me-1"
//                                           alt=""
//                                         />{" "}
//                                         Basic Settings
//                                       </li>
//                                     </Link>
//                                     <div className="border-horizental"></div>
//                                     <li>
//                                       <Link className="dropdown-item" href="#">
//                                         <img
//                                           src={layer}
//                                           className="nutri me-1"
//                                           alt=""
//                                         />
//                                         Advanced Settings
//                                       </Link>
//                                     </li>
//                                   </>
//                                 ) : (
//                                   <></>
//                                 )}

//                                 <li>
//                                   <button
//                                     onClick={LogOut}
//                                     className="dropdown-item"
//                                   >
//                                     <img
//                                       src={layer}
//                                       className="nutri me-1"
//                                       alt=""
//                                     />
//                                     Log Out
//                                   </button>
//                                 </li>
//                               </ul>
//                             </li>
//                           </NavLink>
//                         </ul>
//                       </div>
//                     </div>
//                   </nav>
//                   {/* header nav bar end */}
//                   <header>
//                     <h1>
//                       {usertype && usertype === Fixd_Usertype.SuperAdmin ? (
//                         headerText === "/dashboard" ? (
//                           <p className="text-holder">Super Admin Dashboard</p>
//                         ) : (
//                           <Link to="/dashboard">
//                             <p className="text-holder">
//                               <img src={arrowleft} className="arrow-img me-2" />
//                               Back to Dashboard
//                             </p>{" "}
//                           </Link>
//                         )
//                       ) : headerText === "/data-entry" ? (
//                         <p className="text-holder">Data Entry Reports</p>
//                       ) : (
//                         <Link to="/data-entry">
//                           <p className="text-holder">
//                             <img src={arrowleft} className="arrow-img me-2" />
//                             Back to Data Entry Reports
//                           </p>{" "}
//                         </Link>
//                       )}
//                     </h1>
//                     {/* Other header content */}
//                   </header>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default HeaderDashBoard;

// -----------------------------------------------------------------------------

import React, { useState, useEffect, useContext, useCallback } from "react";
import { NavLink, useLocation, useNavigate, Link } from "react-router-dom";
import "../HeaderDashBoard/HeaderDashBoard.css";
import setting from "../images/setting.png";
import layer from "../images/layer.png";
import arrowleft from "../../assets/icons/arrow-left.png";
import Cookies from "js-cookie";
import { Context } from "../../../../utils/context";
import { Fixd_Usertype } from "../../../../utils/common";

const HeaderDashBoard = () => {
  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);
  const { setUserData, setUsertype, setSignin, usertype } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    setHeaderText(location.pathname);
  }, [location.pathname]);

  const handleLogOut = useCallback(async () => {
    Cookies.remove("billing_security", { path: "/" });
    await setUserData("");
    await setUsertype("");
    await setSignin(false);
    navigate("/");
  }, [navigate, setUserData, setUsertype, setSignin]);

  const renderNavLinks = useCallback(() => {
    const navLinks = [
      {
        path: "/tax-master/languages",
        label: "Master - Tax Management",
        condition: usertype === Fixd_Usertype.SuperAdmin,
      },
      { path: "/data-entry", label: "Reports" },
      {
        path: "/counterwise",
        label: "Collection Reports",
        condition: usertype === Fixd_Usertype.SuperAdmin,
      },
      {
        path: "/rolemanagement",
        label: "Role Management",
        condition: usertype === Fixd_Usertype.SuperAdmin,
      },
    ];

    return navLinks.map((link, index) =>
      link.condition ? (
        <NavLink
          key={index}
          className={
            headerText.includes(link.path)
              ? "butt-decoration active"
              : "butt-decoration"
          }
          to={link.path}
        >
          <li className="nav-item">
            <a className="nav-link menu" aria-current="page" href="#">
              {link.label}
            </a>
          </li>
        </NavLink>
      ) : null
    );
  }, [usertype, headerText]);

  const renderHeaderText = useCallback(() => {
    if (usertype === Fixd_Usertype.SuperAdmin) {
      return headerText === "/dashboard" ? (
        <p className="text-holder">Super Admin Dashboard</p>
      ) : (
        <Link to="/dashboard">
          <p className="text-holder">
            <img src={arrowleft} className="arrow-img me-2" alt="Back" />
            Back to Dashboard
          </p>
        </Link>
      );
    }
    return headerText === "/data-entry" ? (
      <p className="text-holder">Data Entry Reports</p>
    ) : (
      <Link to="/data-entry">
        <p className="text-holder">
          <img src={arrowleft} className="arrow-img me-2" alt="Back" />
          Back to Data Entry Reports
        </p>
      </Link>
    );
  }, [usertype, headerText]);

  return (
    <section className="Header-main">
      <div className="container-fluid p-0">
        <div className="main-header">
          <div className="white-header"></div>
          <div className="Green-header">
            <div className="container-fluid set1">
              <div className="main-navbar">
                <nav className="navbar navbar-expand-lg">
                  <div className="container-fluid">
                    <Link className="navbar-brand" href="#"></Link>
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarNavDropdown"
                      aria-controls="navbarNavDropdown"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                      className="collapse navbar-collapse"
                      id="navbarNavDropdown"
                    >
                      <ul className="navbar-nav justify-content-center d-flex">
                        {renderNavLinks()}
                        <NavLink className="butt-decoration" to="/setting">
                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Settings
                            </a>
                            <ul className="dropdown-menu">
                              {usertype === Fixd_Usertype.SuperAdmin && (
                                <>
                                  <Link className="dropdown-item" to="/company">
                                    <li>
                                      <img
                                        src={setting}
                                        className="nutri me-1"
                                        alt="Settings"
                                      />
                                      Basic Settings
                                    </li>
                                  </Link>
                                  <div className="border-horizental"></div>
                                  <li>
                                    <Link className="dropdown-item" href="#">
                                      <img
                                        src={layer}
                                        className="nutri me-1"
                                        alt="Advanced"
                                      />
                                      Advanced Settings
                                    </Link>
                                  </li>
                                </>
                              )}
                              <li>
                                <button
                                  onClick={handleLogOut}
                                  className="dropdown-item"
                                >
                                  <img
                                    src={layer}
                                    className="nutri me-1"
                                    alt="Log Out"
                                  />
                                  Log Out
                                </button>
                              </li>
                            </ul>
                          </li>
                        </NavLink>
                      </ul>
                    </div>
                  </div>
                </nav>
                <header>
                  <h1>{renderHeaderText()}</h1>
                </header>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(HeaderDashBoard);
