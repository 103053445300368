import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  memo,
} from "react";
import "./BillTab.css";
import Chart from "chart.js/auto";
import download from "../../StepForm/image/download.png";
import exelsheet from "../../StepForm/image/excel.png";
import pdf from "../../StepForm/image/pdf.png";
import { Line, Doughnut, Bar } from "react-chartjs-2";
import GenerateTabFirst from "../generatetab-first/GenerateTabFirst";
import TwoDGraph from "../TwoGraph/TwoDGraph.js";
import AllTabs from "../../create-bill/all-tab/AllTabs";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Recent from "../../GeneratedBill/Recent/Recent/Recent.js";
import Form from "../../Mobile-View/Form/Form";
import MobRecent from "../Recent/MobRecent/MobRecent";
import StepForm from "../../StepForm/StepForm";
import { Context } from "../../../../utils/context";
import Cookies from "js-cookie";
import Billlist from "../Bill_list/Billlist.js";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import circle from "../images/circle.png";

library.add(fas);

const BillTab = memo(() => {
  const { getData, postData } = useContext(Context);
  const [active, setActive] = useState("1");
  const [lable1, setLable1] = useState([]);
  const [lable2, setLable2] = useState([]);
  const [lable3, setLable3] = useState([]);
  const [datacount1, setdatacount1] = useState([]);
  const [datacount2, setdatacount2] = useState([]);
  const [datacount3, setdatacount3] = useState([]);
  const [totalCount1, setTotalCount1] = useState();
  const [totalCount2, setTotalCount2] = useState();
  const [formData, setFormData] = useState({ file: null });
  const [inputeShow, setInputShow] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [success, setSuccess] = useState(true);
  const [isActive2, setIsActive2] = useState(true);
  const [isActive3, setIsActive3] = useState(false);

  const handleTabChange = useCallback((key) => {
    setActive(key);
  }, []);

  const getBarData = useCallback(async () => {
    const [respons1, respons2] = await Promise.all([
      getData("/dashboard/genereatebill/getgeneratebill"),
      getData("/dashboard/genereatebill/getwardcovered"),
    ]);

    if (respons1?.success) {
      const monthNames = respons1.data.recordsCountByMonth.map(
        (element) => element.monthName
      );
      setLable1(monthNames);
      const data = respons1.data.recordsCountByMonth.map(
        (element) => element.recordCount
      );
      setdatacount1(data);
      setTotalCount1(respons1.data.totalCount);
    }

    if (respons2?.success) {
      const monthNames2 = respons2.data.firstHalf.map(
        (element) => element.monthName
      );
      const monthNames3 = respons2.data.secondHalf.map(
        (element) => element.monthName
      );
      setLable2(monthNames2);
      setLable3(monthNames3);
      const data2 = respons2.data.firstHalf.map(
        (element) => element.recordCount
      );
      const data3 = respons2.data.secondHalf.map(
        (element) => element.recordCount
      );
      setdatacount2(data2);
      setdatacount3(data3);
      setTotalCount2(respons2.data.totalCount);
    }
  }, [getData]);

  useEffect(() => {
    getBarData();
  }, [getBarData]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      if (formData.file) {
        const formDataUpload = new FormData();
        formDataUpload.append("file", formData.file);

        try {
          const response = await postData(
            "/createbill/createbillbulk",
            formDataUpload,
            {}
          );
          setShowModal(true);
          setSuccess(true);
          setTimeout(() => {
            setInputShow(!inputeShow);
            setShowModal(false);
          }, 1000);
        } catch (error) {
          console.error(error);
        }
      }
    },
    [formData, postData, inputeShow]
  );

  const handleFileChange = useCallback(
    (e) => {
      setFormData({ ...formData, file: e.target.files[0] });
      setInputShow(!inputeShow);
    },
    [formData, inputeShow]
  );

  const handleClick2 = useCallback(() => {
    setIsActive2(!isActive2);
  }, [isActive2]);

  const handleClick3 = useCallback(
    async (id = null) => {
      setIsActive3(!isActive3);
      if (id) {
        await Cookies.set("createbillid", id, { expires: 2 });
      }
    },
    [isActive3]
  );

  const ExcelhandleDownload = useCallback(() => {
    window.location.href =
      process.env.REACT_APP_BASE_URL +
      "/dashboard/genereatebill/exceldownloadgeneratebill";
  }, []);

  const PdfhandleDownload = useCallback(() => {
    window.location.href =
      process.env.REACT_APP_BASE_URL +
      "/dashboard/genereatebill/pdfdownloadgeneratebill";
  }, []);

  const doughnutData = useCallback(
    () => ({
      labels: lable2,
      datasets: [
        {
          label: "Data",
          data: datacount2,
          fill: false,
          borderColor: "rgba(254, 180, 117, 0.6)",
          backgroundColor: ["rgba(254, 180, 117, 0.6", "#FE7600"],
          hoverBackgroundColor: ["rgba(254, 180, 117, 0.6", "#FE7600"],
          outerWidth: ["30"],
          tension: 0.4,
        },
      ],
    }),
    [lable2, datacount2]
  );

  const doughnutData9 = useCallback(
    () => ({
      labels: lable3,
      datasets: [
        {
          label: "Data",
          data: datacount3,
          fill: false,
          borderColor: "rgba(254, 180, 117, 0.6)",
          backgroundColor: ["rgba(254, 180, 117, 0.6", "#FE7600"],
          hoverBackgroundColor: ["rgba(254, 180, 117, 0.6", "#FE7600"],
          outerWidth: ["30"],
          tension: 0.4,
        },
      ],
    }),
    [lable3, datacount3]
  );

  const splineData = useCallback(
    () => ({
      labels: lable1,
      datasets: [
        {
          label: "Months",
          data: datacount1,
          borderColor: "rgba(254, 180, 117, 0.6)",
          backgroundColor: ["#FE7600"],
        },
      ],
    }),
    [lable1, datacount1]
  );

  const barData = useCallback(
    () => ({
      labels: [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Example Dataset",
          data: [150, 100, 180, 80, 180, 200, 140, 200, 170, 80, 45, 90],
          backgroundColor: [
            "rgba(254, 180, 117, 0.6)",
            "#FE7600",
            "rgba(254, 180, 117, 0.6)",
            "#FE7600",
            "rgba(254, 180, 117, 0.6)",
            "#FE7600",
            "rgba(254, 180, 117, 0.6)",
            "#FE7600",
            "rgba(254, 180, 117, 0.6)",
            "#FE7600",
            "rgba(254, 180, 117, 0.6)",
            "#FE7600",
          ],
        },
      ],
    }),
    []
  );

  return (
    <section className="BillTab">
      <div className="container-fluid p-0">
        <div
          className={isActive2 ? "main-tab-class add-color" : "main-tab-class"}
        >
          <div className="container py-5 pe-0">
            <Tab.Container id="left-tabs-example" activeKey={active}>
              <Nav variant="pills" className="billingTabs_main">
                {[
                  {
                    label: "Total Generated Bill",
                    icon: "fa-solid fa-file",
                    eventKey: "1",
                  },
                  {
                    label: "Total Notice Generated",
                    icon: "fa-solid fa-book",
                    eventKey: "2",
                  },
                  {
                    label: "Total Tax Collection",
                    icon: "fa-solid fa-file-invoice",
                    eventKey: "3",
                  },
                  {
                    label: "Total Ward Covered",
                    icon: "fa-solid fa-map-location-dot",
                    eventKey: "4",
                  },
                  {
                    label: "Total Outstanding Amount",
                    icon: "fa-solid fa-hand-holding-hand",
                    eventKey: "5",
                  },
                  {
                    label: "Recent Activity",
                    icon: "fa-solid fa-file-invoice",
                    eventKey: "6",
                  },
                  {
                    label: "Total Invoices Generated Count Per Month/Day",
                    icon: "fa-solid fa-file-invoice-dollar",
                    eventKey: "7",
                  },
                  {
                    label: "Create Bill",
                    icon: "fa-solid fa-pen-to-square",
                    eventKey: "8",
                  },
                  {
                    label: "Bill List",
                    icon: "fa-solid fa-pen-to-square",
                    eventKey: "9",
                  },
                ].map(({ label, icon, eventKey }) => (
                  <Nav.Item
                    key={eventKey}
                    className={
                      eventKey === "2"
                        ? "notice_txt"
                        : eventKey === "5"
                        ? "outstanding_txt"
                        : ""
                    }
                  >
                    <Nav.Link
                      eventKey={eventKey}
                      onClick={() => handleTabChange(eventKey)}
                    >
                      <div className="card-img-top">
                        <FontAwesomeIcon icon={icon} />
                        <div className="card-body text-center">
                          <p className="total">{label}</p>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="1">
                  <GenerateTabFirst />
                </Tab.Pane>
                <Tab.Pane eventKey="2">
                  <div className="row">
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 text-center mt-md-0 mt-4">
                      <div className="main-total mt-5">
                        <p className="text-center p-3">
                          Notice Generated - {totalCount1}
                        </p>
                      </div>
                      <div className="excel-pdf-tab">
                        <div
                          className="main-total2"
                          onClick={ExcelhandleDownload}
                        >
                          <p className="text-center p-3">
                            <img
                              src={exelsheet}
                              className="excelimg me-5 me-md-3"
                              alt="Excel"
                            />
                            Excelsheet
                            <img
                              src={download}
                              className="excelimg ms-5 ms-md-2"
                              alt="Download"
                            />
                          </p>
                        </div>
                        <div
                          className="main-total2"
                          onClick={PdfhandleDownload}
                        >
                          <p className="text-center p-3">
                            <img
                              src={pdf}
                              className="excelimg me-5 me-md-3"
                              alt="PDF"
                            />
                            PDF File
                            <img
                              src={download}
                              className="excelimg ms-5 ms-md-2"
                              alt="Download"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 mt-5">
                      <div className="graph p-3 mb-5">
                        <div>
                          <p className="text-end text-graph">Generated Bill</p>
                          <Line data={splineData()} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="3">
                  <TwoDGraph />
                </Tab.Pane>
                <Tab.Pane eventKey="4">
                  <div className="row">
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 text-center mt-md-0 ps-md-0 mt-4">
                      <div className="main-total mt-5">
                        <p className="text-center p-3">
                          Total Ward Covered - {totalCount2}
                        </p>
                      </div>
                      <div className="excel-pdf-tab">
                        <div
                          className="main-total2"
                          onClick={ExcelhandleDownload}
                        >
                          <p className="text-center p-3">
                            <img
                              src={exelsheet}
                              className="excelimg me-5 me-md-3"
                              alt="Excel"
                            />
                            Excelsheet
                            <img
                              src={download}
                              className="excelimg ms-5 ms-md-3"
                              alt="Download"
                            />
                          </p>
                        </div>
                        <div
                          className="main-total2"
                          onClick={PdfhandleDownload}
                        >
                          <p className="text-center p-3">
                            <img
                              src={pdf}
                              className="excelimg me-5 me-md-3"
                              alt="PDF"
                            />
                            PDF File
                            <img
                              src={download}
                              className="excelimg ms-5"
                              alt="Download"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                      <div className="row shadow-sm p-3 mb-5 bg-body mt-5">
                        <p className="text-end text-graph-title">
                          Total Ward Covered in months
                        </p>
                        <div className="col-md-6">
                          <Doughnut data={doughnutData()} />
                        </div>
                        <div className="col-md-6">
                          <Doughnut data={doughnutData9()} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="5">
                  <div className="row">
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 text-center mt-md-0 mt-4">
                      <div className="main-total mt-5">
                        <p className="text-center p-3">
                          Total Outstanding Amount
                        </p>
                      </div>
                      <div className="excel-pdf-tab">
                        <div className="main-total2">
                          <p className="text-center p-3">
                            <img
                              src={exelsheet}
                              className="excelimg me-5 me-md-3"
                              alt="Excel"
                            />
                            Excelsheet
                            <img
                              src={download}
                              className="excelimg ms-5 ms-md-2"
                              alt="Download"
                            />
                          </p>
                        </div>
                        <div className="main-total2">
                          <p className="text-center p-3">
                            <img
                              src={pdf}
                              className="excelimg me-5 me-md-3"
                              alt="PDF"
                            />
                            PDF File
                            <img
                              src={download}
                              className="excelimg ms-5 ms-md-2"
                              alt="Download"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 mt-5">
                      <div className="graph p-3 mb-5">
                        <div>
                          <Line data={splineData()} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="6">
                  <div className="col-md-12">
                    <div className="desktop-view">
                      <Recent />
                    </div>
                    <div className="mobile-view">
                      <MobRecent />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="7">
                  <div className="row">
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 text-center mt-md-0 mt-4">
                      <div className="main-total mt-5">
                        <p className="text-center p-3">
                          Total Invoices Generated Count Per Month/Day
                        </p>
                      </div>
                      <div className="excel-pdf-tab">
                        <div className="main-total2">
                          <p className="text-center p-3">
                            <img
                              src={exelsheet}
                              className="excelimg me-5 me-md-3"
                              alt="Excel"
                            />
                            Excelsheet
                            <img
                              src={download}
                              className="excelimg ms-5 ms-md-2"
                              alt="Download"
                            />
                          </p>
                        </div>
                        <div className="main-total2">
                          <p className="text-center p-3">
                            <img
                              src={pdf}
                              className="excelimg me-5 me-md-3"
                              alt="PDF"
                            />
                            PDF File
                            <img
                              src={download}
                              className="excelimg ms-5 ms-md-2"
                              alt="Download"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 mt-5">
                      <div className="graph p-3 mb-5">
                        <div className="main-total1 text-end">
                          <div>
                            <span className="orange-box">...</span> 2023
                          </div>
                          <div>
                            <span className="light-orange">...</span> 2022
                          </div>
                        </div>
                        <div>
                          <Bar data={barData()} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="8">
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-5 p-0">
                    <div className="alltabs-view">
                      <StepForm isActive3={isActive3} />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="9">
                  <div className="bill-list-conttt-div">
                    <Billlist
                      handleTabChange={handleTabChange}
                      handleClick={handleClick3}
                    />
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content created-bill-modal">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="Success" />
                </div>
                <h1 className="add-success text-center">
                  Bulk Uploaded Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default BillTab;
