import React, { useEffect, useState } from "react";
import "./billlist.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import CustomPaginate from "../../../common/CustomPaginate/CustomPaginate";

const Billlist = (props) => {
  const { getData, deleteData, editData, ErrorNotify } = useContext(Context);
  const [data, setData] = useState([]);
  const [ward, setWard] = useState([]);
  const [zone, setZone] = useState([]);
  const [property, setProperty] = useState([]);
  const [formData, setFormData] = useState({
    ward_id: "",
    zone_id: "",
    property_id: "",
  });

  const [reload, setReload] = useState(0);

  const getPropertyData = async (page = 1) => {
    // setFormData({ ward_id: "", zone_id: "", property_id: "" });
    let url = "/billlist/getallpropertydetails?";

    if (formData?.ward_id?.value) {
      url += "&ward_id=" + formData?.ward_id?.value + "&";
    }

    if (formData?.zone_id?.value) {
      url += "&zone_id=" + formData?.zone_id?.value + "&";
    }

    if (formData?.property_id?.value) {
      url += "&property_id=" + formData?.property_id?.value + "&";
    }

    if (typeof page !== "number") {
      page = 1;
    }

    if (page) {
      url += "&page=" + page + "&";
    }
    const response = await getData(url);
    // const response = await getData(
    //   `/billlist/getallpropertydetails?ward_id=${formData?.ward_id?.value}&zone_id=${formData?.zone_id?.value}&property_id=${formData?.property_id?.value}`
    // );
    await setData(response?.data);

    const option = [];
    if (response.success) {
      response?.data?.properties?.map((data) => {
        option.push({
          value: data.id,
          name: "property_id",
          label: data.sequence_start + data.property_no,
        });
      });
    }
    await setProperty(option);
  };

  const handleChange1 = (e) => {
    if (
      e?.name === "zone_id" ||
      e?.name === "ward_id" ||
      e?.name === "property_id"
    ) {
      setFormData({ ...formData, [e.name]: e });
    } else if (e.target.name === "authorised" || e.target.name === "legal") {
      setFormData({ ...formData, [e.target.name]: e.target.checked });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleClicks = async (name) => {
    switch (name) {
      case "ward_id": {
        const res = await getData("/api/getward");
        const option = [];
        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.ward_id,
              name: "ward_id",
              label: data?.ward?.city?.name + " - " + data?.name,
            });
          });
        }
        await setWard(option);
        setFormData({
          ...formData,
          ward_id: "",
          zone_id: "",
        });
        break;
      }

      case "zone_id": {
        const res = await editData("/api/getzone", {
          id: formData.ward_id.value,
        });

        const option = [];
        if (res.success) {
          res?.data?.data?.map((data) => {
            option.push({
              value: data.zone_id,
              name: "zone_id",
              label: data.name,
            });
          });
        }
        await setZone(option);
        setFormData({
          ...formData,
          zone_id: "",
        });
        break;
      }

      default:
        break;
    }
  };

  const Search = async () => {
    const response = await getData(
      `/billlist/getsearchpropertydetails?ward_id=${formData?.ward_id?.value}&zone_id=${formData?.zone_id?.value}&property_id=${formData?.property_id?.value}`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getPropertyData();
  }, [reload]);

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/billlist/deletedata/${recordToDeleteId}`
      );
      ErrorNotify(recordToDeleteName);
      setRecordToDeleteId(null);
      if (response.success) {
        setRecordToDeleteName(null);
        setReload(response);
      }
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  const handlePageChange = (page) => {
    getPropertyData(page);
  };

  return (
    <>
      <section className="bill-list-section">
        <form>
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
              <Select
                name="ward_id"
                className="custom-select"
                value={formData?.ward_id}
                onFocus={() => {
                  handleClicks("ward_id");
                }}
                onChange={(event) => {
                  handleChange1(event);
                }}
                options={ward}
              />
            </div>

            <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
              <Select
                className="custom-select"
                name="zone_id"
                value={formData?.zone_id}
                onFocus={() => {
                  handleClicks("zone_id");
                }}
                onChange={(event) => {
                  handleChange1(event);
                }}
                options={zone}
              />
            </div>

            <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
              <Select
                name="property_id"
                className="custom-select"
                value={formData?.property_id}
                onChange={(event) => {
                  handleChange1(event);
                }}
                options={property}
              />
            </div>

            <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
              <div className="btn-div">
                <button
                  className="btn searrchhh-btn"
                  type="button"
                  onClick={getPropertyData}
                >
                  Search
                </button>
                <button
                  className="btn searrchhh-btn"
                  type="button"
                  // onClick={async () => {
                  //   await setFormData({
                  //     ward_id: "",
                  //     zone_id: "",
                  //     property_id: "",
                  //   });
                  //   await getPropertyData();
                  // }}

                  onClick={async (e) => {
                    try {
                      // Reset form data
                      await setFormData({
                        ward_id: "",
                        zone_id: "",
                        property_id: "",
                      });

                      // Fetch property data after resetting the form data
                      await getPropertyData();
                    } catch (error) {
                      console.error("Error during the onClick handler:", error);
                    }
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </form>

        <div className="pagination-div">
          <CustomPaginate
            currentPage={data?.currentPage}
            totalPages={data?.totalPages}
            handlePageChange={handlePageChange}
          />
        </div>

        <div className="list-table-div table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <td>No</td>
                <td>Ward</td>
                <td>Zone</td>
                <td>Property No</td>
                <td>Action</td>
              </tr>
            </thead>

            <tbody>
              {data?.properties?.map((val, index) => (
                <tr key={index}>
                  <td>
                    {(data?.currentPage - 1) * data?.per_page + index + 1}
                  </td>
                  {/* {++index} */}
                  <td>{val?.ward?.name}</td>
                  <td>{val?.zone?.name}</td>
                  <td>{val?.sequence_start + val?.property_no}</td>
                  <td>
                    <button
                      className="btn edit-action btn-primary me-2"
                      type="button"
                      onClick={() => {
                        props.handleClick(val?.id);
                        props.handleTabChange("8");
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="btn edit-action btn-danger"
                      type="button"
                      onClick={() => {
                        showDeleteRecord(
                          val?.id,
                          val?.sequence_start + val?.property_no
                        );
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>

      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{
            display: showDeleteModal ? "block" : "none",
          }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div
              className="modal-content"
              style={{ backgroundColor: "#fe7600" }}
            >
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button
                    className="btn btn-yes me-2"
                    onClick={handleDeleteRecord}
                  >
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Billlist;
