// import React, { useContext, useEffect, useMemo, useCallback } from "react";
// import { Link, useParams, useNavigate } from "react-router-dom";
// import { useState } from "react";
// import circle from "../../assets/icons/circle.png";
// import rigth from "../../assets/icons/rigth.png";
// import save from "../../assets/icons/save.png";
// import cancel from "../../assets/icons/cross.png";
// import Sidebar from "../layout/Sidebar";
// import { Context } from "../../../../utils/context";
// import Select from "react-select";
// import { useForm, useFieldArray, Controller } from "react-hook-form";
// import "./table.css";
// import { ColorRing } from "react-loader-spinner";

// const Edit = () => {
//   const { getEditData, editData, getData, postData } = useContext(Context);
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const [usagetype, setUsageType] = useState([]);
//   const [usagesubtype, setUsageSubType] = useState([]);
//   const [taxes, setTaxes] = useState([]);
//   const [maintaxes, setMainTaxes] = useState([]);
//   const [city, setCity] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [showErrorModal, setShowErrorModal] = useState(false);
//   const [isLoading, setLoading] = useState(false);

//   const {
//     control,
//     register,
//     handleSubmit,
//     setValue,
//     getValues,
//     reset,
//     formState: { errors },
//     setError,
//   } = useForm({
//     defaultValues: {
//       city_id: "",
//       fields: [],
//     },
//   });

//   const { fields, append, remove } = useFieldArray({
//     control,
//     name: "fields",
//   });

//   const getDataAll = useCallback(async () => {
//     setLoading(true);
//     const [cityRes, usageTypeRes] = await Promise.all([
//       getData("/api/getcity"),
//       getData("/api/getusagetype"),
//     ]);

//     if (cityRes.success) {
//       setCity(
//         cityRes.data.map((data) => ({
//           value: data.city_id,
//           name: "city_id",
//           label: data.name,
//         }))
//       );
//     }

//     if (usageTypeRes.success) {
//       setUsageType(
//         usageTypeRes.data.map((data) => ({
//           value: data.usagetype_id,
//           name: `usage_type_id`,
//           label: data.name,
//         }))
//       );
//     }
//     setLoading(false);
//   }, [getData]);

//   const GetCityTaxes = useCallback(
//     async (id) => {
//       setLoading(true);
//       const [mainTaxesRes, taxesRes] = await Promise.all([
//         getData(`/api/get-main-taxes?city_id=${id}`),
//         getData(`/api/get-taxes?city_id=${id}`),
//       ]);

//       setMainTaxes(mainTaxesRes.data.data);
//       setTaxes(taxesRes.data.data);
//       setLoading(false);
//     },
//     [getData]
//   );

//   const GetEditData = useCallback(async () => {
//     setLoading(true);
//     const response = await getData(`/master/geteditratablevalues/${id}`);
//     setValue("fields", response?.data?.myArray);
//     setValue("city_id", response?.data?.city_id);
//     GetCityTaxes(response?.data?.city_id?.value);
//     setLoading(false);
//   }, [getData, setValue, id, GetCityTaxes]);

//   const GetAllUsageSubType = useCallback(
//     async (data) => {
//       const res = await editData("/api/getusagesubtype", { id: data });
//       if (res.success) {
//         setUsageSubType(
//           res.data.map((data) => ({
//             value: data.usagesubtype_id,
//             name: "usage_subtype_id",
//             label: data.name,
//           }))
//         );
//       }
//     },
//     [editData]
//   );

//   const onSubmit = useCallback(
//     async (data) => {
//       try {
//         if (!data.fields?.length) {
//           setError("city_id", {
//             type: "manual",
//             message: "Add Taxes minimum required one",
//           });
//         } else {
//           setLoading(true);
//           const response = await postData("/master/postratablevalues", data);
//           if (response.success) {
//             setShowModal(true);
//             setTimeout(() => {
//               setShowModal(false);
//               setLoading(false);
//               navigate("/master/ratablevalueslist");
//             }, 1000);
//           } else {
//             setShowErrorModal(true);
//             setTimeout(() => {
//               setShowErrorModal(false);
//               setLoading(false);
//               navigate("/master/ratablevalueslist");
//             }, 1000);
//           }
//         }
//       } catch (error) {
//         console.error("Error submitting form:", error);
//       }
//     },
//     [postData, navigate]
//   );

//   const addNewField = useCallback(() => {
//     append({
//       ratable_value_start: "",
//       ratable_value_end: "",
//       taxes: taxes.map((tax) => ({ id: tax.id, no: "", name: tax?.name })),
//       maintaxes: maintaxes.map((tax) => ({
//         id: tax.id,
//         no: "",
//         name: tax?.name,
//       })),
//       usage_type_id: null,
//       usage_subtype_id: null,
//     });
//   }, [append, taxes, maintaxes]);

//   const removeField = useCallback(
//     (index) => {
//       remove(index);
//     },
//     [remove]
//   );

//   const errorStyle = useMemo(
//     () => ({
//       color: "red",
//       marginLeft: "5px",
//       fontSize: "12px",
//     }),
//     []
//   );

//   useEffect(() => {
//     getDataAll();
//     GetEditData();
//   }, [getDataAll, GetEditData]);

//   return (
//     <React.Fragment>
//       <div>
//         <section className="main-section main-sec-bg">
//           <div className="container-fluid">
//             <div className="row">
//               <Sidebar />
//               <div className="col-xl-9 col-lg-8">
//                 <div className="container taxtopset">
//                   <div className="row mt-5">
//                     <div className="col-xxl-12 col-xl-12 col-md-10">
//                       <div className="Back me-4">
//                         <Link to="/master/ratablevalueslist">
//                           <button type="button" className="btn btn-back">
//                             <img src={rigth} className="rigth" alt="" /> Back
//                           </button>
//                         </Link>
//                       </div>
//                       <div className="border-line1 mt-3"></div>
//                       <div className="row">
//                         <div className="col">
//                           <div className="main-form-section mt-3">
//                             <div className="row justify-content-center">
//                               <label
//                                 htmlFor="inputEmail3"
//                                 className="col-sm-3 col-form-label"
//                               >
//                                 Select City
//                               </label>
//                               <div className="col-sm-6">
//                                 <Controller
//                                   name="city_id"
//                                   control={control}
//                                   rules={{ required: "Please Select City" }}
//                                   render={({ field }) => (
//                                     <Select
//                                       inputStyle={{
//                                         borderColor: errors.city_id
//                                           ? "red"
//                                           : getValues("city_id")
//                                           ? "green"
//                                           : "",
//                                       }}
//                                       {...field}
//                                       options={city}
//                                       onChange={(selectedOption) => {
//                                         field.onChange(selectedOption);
//                                         GetCityTaxes(selectedOption.value);
//                                       }}
//                                     />
//                                   )}
//                                 />
//                                 {errors.city_id && (
//                                   <span style={errorStyle}>
//                                     {errors.city_id.message}
//                                   </span>
//                                 )}
//                               </div>
//                             </div>
//                             {!isLoading ? (
//                               <div className="row mt-5 pb-3">
//                                 <div className="d-flex justify-content-center">
//                                   <Link to="/master/ratablevalueslist">
//                                     <button
//                                       type="button"
//                                       className="btn btn-cancel me-2"
//                                     >
//                                       <img
//                                         src={cancel}
//                                         className="cancel-img"
//                                         alt=""
//                                       />{" "}
//                                       Cancel
//                                     </button>
//                                   </Link>
//                                   <button
//                                     type="submit"
//                                     onClick={handleSubmit(onSubmit)}
//                                     className="btn btn-save"
//                                   >
//                                     <img
//                                       src={save}
//                                       className="save-img me-2"
//                                       alt=""
//                                     />
//                                     Save
//                                   </button>
//                                 </div>
//                               </div>
//                             ) : (
//                               <></>
//                             )}
//                           </div>
//                           {isLoading ? (
//                             <ColorRing
//                               className="blocks-wrapper"
//                               visible={true}
//                               height="80"
//                               width="80"
//                               ariaLabel="blocks-loading"
//                               wrapperStyle={{}}
//                               wrapperClass="blocks-wrapper"
//                               colors={[
//                                 "#e15b64",
//                                 "#f47e60",
//                                 "#f8b26a",
//                                 "#abbd81",
//                                 "#849b87",
//                               ]}
//                             />
//                           ) : (
//                             <div className="table-responsive taxmastertable">
//                               <form onSubmit={handleSubmit(onSubmit)}>
//                                 <table className="table table-bordered align-items-center table-sm mt-5">
//                                   <thead className="thead-light">
//                                     <tr>
//                                       <th>No</th>
//                                       <th>Taxes</th>
//                                       {maintaxes.length > 0 &&
//                                         maintaxes.map((tax, index) => (
//                                           <th key={index}>{tax.name}</th>
//                                         ))}
//                                       {taxes.length > 0 &&
//                                         taxes.map((tax, index) => (
//                                           <th key={index}>{tax.name}</th>
//                                         ))}
//                                       <th>Usage Type</th>
//                                       <th style={{ width: "160px" }}>
//                                         Usage SubType
//                                       </th>
//                                       <th>Remove</th>
//                                     </tr>
//                                     <tr>
//                                       <th></th>
//                                       <th>Ratable Value</th>
//                                       <th></th>
//                                       <th></th>
//                                       <th></th>
//                                       <th style={{ width: "160px" }}></th>
//                                       <th></th>
//                                     </tr>
//                                   </thead>
//                                   <tbody>
//                                     {fields.map((field, index) => (
//                                       <tr key={field.id}>
//                                         <td>{index + 1}</td>
//                                         <td>
//                                           <input
//                                             type="number"
//                                             placeholder="R. V. Start"
//                                             {...register(
//                                               `fields.${index}.ratable_value_start`,
//                                               { required: true }
//                                             )}
//                                             className={`form-control ${
//                                               errors?.fields?.[index]
//                                                 ?.ratable_value_start
//                                                 ? "is-invalid"
//                                                 : ""
//                                             }`}
//                                           />
//                                           To
//                                           <input
//                                             type="number"
//                                             placeholder="R. V. End"
//                                             {...register(
//                                               `fields.${index}.ratable_value_end`,
//                                               { required: true }
//                                             )}
//                                             className={`form-control ${
//                                               errors?.fields?.[index]
//                                                 ?.ratable_value_end
//                                                 ? "is-invalid"
//                                                 : ""
//                                             }`}
//                                           />
//                                         </td>
//                                         {field.maintaxes.map(
//                                           (tax, taxIndex) => (
//                                             <td key={taxIndex}>
//                                               <input
//                                                 placeholder={tax?.name}
//                                                 type="number"
//                                                 {...register(
//                                                   `fields.${index}.maintaxes.${taxIndex}.no`,
//                                                   { required: true }
//                                                 )}
//                                                 className={`form-control ${
//                                                   errors?.fields?.[index]
//                                                     ?.maintaxes?.[taxIndex]?.no
//                                                     ? "is-invalid"
//                                                     : ""
//                                                 }`}
//                                               />
//                                             </td>
//                                           )
//                                         )}
//                                         {field.taxes.map((tax, taxIndex) => (
//                                           <td key={taxIndex}>
//                                             <input
//                                               placeholder={tax?.name}
//                                               type="number"
//                                               {...register(
//                                                 `fields.${index}.taxes.${taxIndex}.no`,
//                                                 { required: true }
//                                               )}
//                                               className={`form-control ${
//                                                 errors?.fields?.[index]
//                                                   ?.taxes?.[taxIndex]?.no
//                                                   ? "is-invalid"
//                                                   : ""
//                                               }`}
//                                             />
//                                           </td>
//                                         ))}
//                                         <td className="td-select">
//                                           <Controller
//                                             name={`fields.${index}.usage_type_id`}
//                                             control={control}
//                                             rules={{
//                                               required:
//                                                 "Please Select Usage Type",
//                                             }}
//                                             render={({ field }) => (
//                                               <Select
//                                                 placeholder="Usage Type"
//                                                 {...field}
//                                                 options={usagetype}
//                                                 className={`custom-select ${
//                                                   errors?.fields?.[index]
//                                                     ?.usage_type_id
//                                                     ? "is-invalid"
//                                                     : ""
//                                                 }`}
//                                                 onChange={(selectedOption) => {
//                                                   field.onChange(
//                                                     selectedOption
//                                                   );
//                                                   GetAllUsageSubType(
//                                                     selectedOption.value
//                                                   );
//                                                   setValue(
//                                                     `fields.${index}.usage_subtype_id`,
//                                                     null
//                                                   );
//                                                 }}
//                                               />
//                                             )}
//                                           />
//                                           {errors?.fields?.[index]
//                                             ?.usage_type_id && (
//                                             <div className="invalid-feedback">
//                                               {
//                                                 errors.fields[index]
//                                                   .usage_type_id.message
//                                               }
//                                             </div>
//                                           )}
//                                         </td>
//                                         <td className="td-select">
//                                           <Controller
//                                             name={`fields.${index}.usage_subtype_id`}
//                                             control={control}
//                                             rules={{
//                                               required:
//                                                 "Please Select Usage Sub Type",
//                                             }}
//                                             render={({ field }) => (
//                                               <Select
//                                                 placeholder="U. Sub Type"
//                                                 {...field}
//                                                 options={usagesubtype}
//                                                 className={`custom-select ${
//                                                   errors?.fields?.[index]
//                                                     ?.usage_subtype_id
//                                                     ? "is-invalid"
//                                                     : ""
//                                                 }`}
//                                                 onChange={(selectedOption) =>
//                                                   field.onChange(selectedOption)
//                                                 }
//                                               />
//                                             )}
//                                           />
//                                           {errors?.fields?.[index]
//                                             ?.usage_subtype_id && (
//                                             <div className="invalid-feedback">
//                                               {
//                                                 errors.fields[index]
//                                                   .usage_subtype_id.message
//                                               }
//                                             </div>
//                                           )}
//                                         </td>
//                                         <td>
//                                           <button
//                                             type="button"
//                                             className="btn btn-danger btn-small"
//                                             onClick={() => removeField(index)}
//                                           >
//                                             &times;
//                                           </button>
//                                         </td>
//                                       </tr>
//                                     ))}
//                                   </tbody>

//                                   <tfoot>
//                                     <tr>
//                                       <td colSpan="4" className="text-right">
//                                         <button
//                                           type="button"
//                                           className="btn btn-info"
//                                           onClick={addNewField}
//                                         >
//                                           + Add Row
//                                         </button>
//                                       </td>
//                                     </tr>
//                                   </tfoot>
//                                 </table>
//                               </form>
//                             </div>
//                           )}
//                           <div className="row mt-5 pb-3">
//                             <div className="d-flex justify-content-center">
//                               <Link to="/master/ratablevalueslist">
//                                 <button
//                                   type="button"
//                                   className="btn btn-cancel me-2"
//                                 >
//                                   <img
//                                     src={cancel}
//                                     className="cancel-img"
//                                     alt=""
//                                   />{" "}
//                                   Cancel
//                                 </button>
//                               </Link>
//                               <button
//                                 type="submit"
//                                 onClick={handleSubmit(onSubmit)}
//                                 className="btn btn-save"
//                               >
//                                 <img
//                                   src={save}
//                                   className="save-img me-2"
//                                   alt=""
//                                 />
//                                 Save
//                               </button>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//       <div className="save-modal">
//         <div
//           className={`modal fade ${showModal ? "show" : ""}`}
//           style={{ display: showModal ? "block" : "none" }}
//           id="exampleModal1"
//           tabIndex="-1"
//           aria-labelledby="exampleModalLabel"
//           aria-hidden="true"
//         >
//           <div className="modal-dialog modal-dialog-centered">
//             <div className="modal-content">
//               <div className="modal-body">
//                 <div className="circle justify-content-end">
//                   <img src={circle} className="circle-img mb-2" alt="" />
//                 </div>
//                 <h1 className="add-success text-center">
//                   Ratable Value Updated Successfully
//                 </h1>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="save-modal">
//         <div
//           className={`modal fade ${showErrorModal ? "show" : ""}`}
//           style={{ display: showErrorModal ? "block" : "none" }}
//           id="exampleModal1"
//           tabIndex="-1"
//           aria-labelledby="exampleModalLabel"
//           aria-hidden="true"
//         >
//           <div className="modal-dialog modal-dialog-centered">
//             <div className="modal-content">
//               <div className="modal-body">
//                 <div className="circle justify-content-end">
//                   <img src={circle} className="circle-img mb-2" alt="" />
//                 </div>
//                 <h1 className="add-success text-center">
//                   Ratable Value Already Exists
//                 </h1>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// };

// export default Edit;

// -----------------------------------------------------------------------------------------------------------------

import React, {
  useContext,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import circle from "../../assets/icons/circle.png";
import rigth from "../../assets/icons/rigth.png";
import save from "../../assets/icons/save.png";
import cancel from "../../assets/icons/cross.png";
import Sidebar from "../layout/Sidebar";
import { Context } from "../../../../utils/context";
import Select from "react-select";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import "./table.css";
import { ColorRing } from "react-loader-spinner";

const Edit = () => {
  const { getEditData, editData, getData, postData } = useContext(Context);
  const navigate = useNavigate();
  const { id } = useParams();
  const [usagetype, setUsageType] = useState([]);
  const [usagesubtype, setUsageSubType] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [maintaxes, setMainTaxes] = useState([]);
  const [city, setCity] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [citySelected, setCitySelected] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      city_id: "",
      fields: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "fields",
  });

  const getDataAll = useCallback(async () => {
    setLoading(true);
    try {
      const [cityRes, usageTypeRes] = await Promise.all([
        getData("/api/getcity"),
        getData("/api/getusagetype"),
      ]);

      if (cityRes.success) {
        setCity(
          cityRes.data.map((data) => ({
            value: data.city_id,
            name: "city_id",
            label: data.name,
          }))
        );
      }

      if (usageTypeRes.success) {
        setUsageType(
          usageTypeRes.data.map((data) => ({
            value: data.usagetype_id,
            name: `usage_type_id`,
            label: data.name,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [getData]);

  const GetCityTaxes = useCallback(
    async (id) => {
      setLoading(true);
      try {
        const [mainTaxesRes, taxesRes] = await Promise.all([
          getData(`/api/get-main-taxes?city_id=${id}`),
          getData(`/api/get-taxes?city_id=${id}`),
        ]);

        setMainTaxes(mainTaxesRes.data.data);
        setTaxes(taxesRes.data.data);
        setCitySelected(true);
      } catch (error) {
        console.error("Error fetching taxes:", error);
      } finally {
        setLoading(false);
      }
    },
    [getData]
  );

  const GetEditData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getData(`/master/geteditratablevalues/${id}`);
      setValue("fields", response?.data?.myArray);
      setValue("city_id", response?.data?.city_id);
      GetCityTaxes(response?.data?.city_id?.value);
    } catch (error) {
      console.error("Error fetching edit data:", error);
    } finally {
      setLoading(false);
    }
  }, [getData, setValue, id, GetCityTaxes]);

  const GetAllUsageSubType = useCallback(
    async (data) => {
      try {
        const res = await editData("/api/getusagesubtype", { id: data });
        if (res.success) {
          setUsageSubType(
            res.data.map((data) => ({
              value: data.usagesubtype_id,
              name: "usage_subtype_id",
              label: data.name,
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching usage subtypes:", error);
      }
    },
    [editData]
  );

  const onSubmit = useCallback(
    async (data) => {
      try {
        if (!data.fields?.length) {
          setError("city_id", {
            type: "manual",
            message: "Add Taxes minimum required one",
          });
        } else {
          setLoading(true);
          const response = await postData("/master/postratablevalues", data);
          if (response.success) {
            setShowModal(true);
            setTimeout(() => {
              setShowModal(false);
              setLoading(false);
              navigate("/master/ratablevalueslist");
            }, 1000);
          } else {
            setShowErrorModal(true);
            setTimeout(() => {
              setShowErrorModal(false);
              setLoading(false);
              navigate("/master/ratablevalueslist");
            }, 1000);
          }
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
    [postData, navigate]
  );

  const addNewField = useCallback(() => {
    append({
      ratable_value_start: "",
      ratable_value_end: "",
      taxes: taxes.map((tax) => ({ id: tax.id, no: "", name: tax?.name })),
      maintaxes: maintaxes.map((tax) => ({
        id: tax.id,
        no: "",
        name: tax?.name,
      })),
      usage_type_id: null,
      usage_subtype_id: null,
    });
  }, [append, taxes, maintaxes]);

  const removeField = useCallback(
    (index) => {
      remove(index);
    },
    [remove]
  );

  const errorStyle = useMemo(
    () => ({
      color: "red",
      marginLeft: "5px",
      fontSize: "12px",
    }),
    []
  );

  useEffect(() => {
    getDataAll();
    GetEditData();
  }, [getDataAll, GetEditData]);

  const handleWheel = (e) => {
    e.target.blur();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <React.Fragment>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container-fluid">
            <div className="row">
              <Sidebar />
              <div className="col-xl-9 col-lg-8">
                <div className="container taxtopset">
                  <div className="row mt-5">
                    <div className="col-xxl-12 col-xl-12 col-md-10">
                      <div className="Back me-4">
                        <Link to="/master/ratablevalueslist">
                          <button type="button" className="btn btn-back">
                            <img src={rigth} className="rigth" alt="" /> Back
                          </button>
                        </Link>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <div className="row">
                        <div className="col">
                          <div className="main-form-section mt-3">
                            <div className="row justify-content-center">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-3 col-form-label"
                              >
                                Select City
                              </label>
                              <div className="col-sm-6">
                                <Controller
                                  name="city_id"
                                  control={control}
                                  rules={{ required: "Please Select City" }}
                                  render={({ field }) => (
                                    <Select
                                      inputStyle={{
                                        borderColor: errors.city_id
                                          ? "red"
                                          : getValues("city_id")
                                          ? "green"
                                          : "",
                                      }}
                                      {...field}
                                      options={city}
                                      onChange={(selectedOption) => {
                                        field.onChange(selectedOption);
                                        GetCityTaxes(selectedOption.value);
                                      }}
                                    />
                                  )}
                                />
                                {errors.city_id && (
                                  <span style={errorStyle}>
                                    {errors.city_id.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            {!isLoading ? (
                              <div className="row mt-5 pb-3">
                                <div className="d-flex justify-content-center">
                                  <Link to="/master/ratablevalueslist">
                                    <button
                                      type="button"
                                      className="btn btn-cancel me-2"
                                    >
                                      <img
                                        src={cancel}
                                        className="cancel-img"
                                        alt=""
                                      />{" "}
                                      Cancel
                                    </button>
                                  </Link>
                                  <button
                                    type="submit"
                                    onClick={handleSubmit(onSubmit)}
                                    className="btn btn-save"
                                  >
                                    <img
                                      src={save}
                                      className="save-img me-2"
                                      alt=""
                                    />
                                    Save
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          {isLoading ? (
                            <ColorRing
                              className="blocks-wrapper"
                              visible={true}
                              height="80"
                              width="80"
                              ariaLabel="blocks-loading"
                              wrapperStyle={{}}
                              wrapperClass="blocks-wrapper"
                              colors={[
                                "#e15b64",
                                "#f47e60",
                                "#f8b26a",
                                "#abbd81",
                                "#849b87",
                              ]}
                            />
                          ) : (
                            <div className="table-responsive taxmastertable">
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <table className="table table-bordered align-items-center table-sm mt-5">
                                  <thead className="thead-light">
                                    <tr>
                                      <th>No</th>
                                      <th>Taxes</th>
                                      {maintaxes.length > 0 &&
                                        maintaxes.map((tax, index) => (
                                          <th key={index}>{tax.name}</th>
                                        ))}
                                      {taxes.length > 0 &&
                                        taxes.map((tax, index) => (
                                          <th key={index}>{tax.name}</th>
                                        ))}
                                      <th>Usage Type</th>
                                      <th style={{ width: "160px" }}>
                                        Usage SubType
                                      </th>
                                      <th>Remove</th>
                                    </tr>
                                    <tr>
                                      <th></th>
                                      <th>Ratable Value</th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th style={{ width: "160px" }}></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {fields.map((field, index) => (
                                      <tr key={field.id}>
                                        <td>{index + 1}</td>
                                        <td>
                                          <input
                                            type="number"
                                            placeholder="R. V. Start"
                                            {...register(
                                              `fields.${index}.ratable_value_start`,
                                              { required: true }
                                            )}
                                            className={`form-control ${
                                              errors?.fields?.[index]
                                                ?.ratable_value_start
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            onWheel={handleWheel}
                                          />
                                          To
                                          <input
                                            type="number"
                                            placeholder="R. V. End"
                                            {...register(
                                              `fields.${index}.ratable_value_end`,
                                              { required: true }
                                            )}
                                            className={`form-control ${
                                              errors?.fields?.[index]
                                                ?.ratable_value_end
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            onWheel={handleWheel}
                                          />
                                        </td>
                                        {field.maintaxes.map(
                                          (tax, taxIndex) => (
                                            <td key={taxIndex}>
                                              <input
                                                placeholder={tax?.name}
                                                type="number"
                                                {...register(
                                                  `fields.${index}.maintaxes.${taxIndex}.no`,
                                                  { required: true }
                                                )}
                                                className={`form-control ${
                                                  errors?.fields?.[index]
                                                    ?.maintaxes?.[taxIndex]?.no
                                                    ? "is-invalid"
                                                    : ""
                                                }`}
                                                onWheel={handleWheel}
                                              />
                                            </td>
                                          )
                                        )}
                                        {field.taxes.map((tax, taxIndex) => (
                                          <td key={taxIndex}>
                                            <input
                                              placeholder={tax?.name}
                                              type="number"
                                              {...register(
                                                `fields.${index}.taxes.${taxIndex}.no`,
                                                { required: true }
                                              )}
                                              className={`form-control ${
                                                errors?.fields?.[index]
                                                  ?.taxes?.[taxIndex]?.no
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              onWheel={handleWheel}
                                            />
                                          </td>
                                        ))}
                                        <td className="td-select">
                                          <Controller
                                            name={`fields.${index}.usage_type_id`}
                                            control={control}
                                            rules={{
                                              required:
                                                "Please Select Usage Type",
                                            }}
                                            render={({ field }) => (
                                              <Select
                                                placeholder="Usage Type"
                                                {...field}
                                                options={usagetype}
                                                className={`custom-select ${
                                                  errors?.fields?.[index]
                                                    ?.usage_type_id
                                                    ? "is-invalid"
                                                    : ""
                                                }`}
                                                onChange={(selectedOption) => {
                                                  field.onChange(
                                                    selectedOption
                                                  );
                                                  GetAllUsageSubType(
                                                    selectedOption.value
                                                  );
                                                  setValue(
                                                    `fields.${index}.usage_subtype_id`,
                                                    null
                                                  );
                                                }}
                                              />
                                            )}
                                          />
                                          {errors?.fields?.[index]
                                            ?.usage_type_id && (
                                            <div className="invalid-feedback">
                                              {
                                                errors.fields[index]
                                                  .usage_type_id.message
                                              }
                                            </div>
                                          )}
                                        </td>
                                        <td className="td-select">
                                          <Controller
                                            name={`fields.${index}.usage_subtype_id`}
                                            control={control}
                                            rules={{
                                              required:
                                                "Please Select Usage Sub Type",
                                            }}
                                            render={({ field }) => (
                                              <Select
                                                placeholder="U. Sub Type"
                                                {...field}
                                                options={usagesubtype}
                                                className={`custom-select ${
                                                  errors?.fields?.[index]
                                                    ?.usage_subtype_id
                                                    ? "is-invalid"
                                                    : ""
                                                }`}
                                                onChange={(selectedOption) =>
                                                  field.onChange(selectedOption)
                                                }
                                              />
                                            )}
                                          />
                                          {errors?.fields?.[index]
                                            ?.usage_subtype_id && (
                                            <div className="invalid-feedback">
                                              {
                                                errors.fields[index]
                                                  .usage_subtype_id.message
                                              }
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          <button
                                            type="button"
                                            className="btn btn-danger btn-small"
                                            onClick={() => removeField(index)}
                                          >
                                            &times;
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>

                                  <tfoot>
                                    <tr>
                                      <td colSpan="4" className="text-right">
                                        <button
                                          type="button"
                                          className="btn btn-info"
                                          onClick={addNewField}
                                          disabled={!citySelected}
                                        >
                                          + Add Row
                                        </button>
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </form>
                            </div>
                          )}
                          <div className="row mt-5 pb-3">
                            <div className="d-flex justify-content-center">
                              <Link to="/master/ratablevalueslist">
                                <button
                                  type="button"
                                  className="btn btn-cancel me-2"
                                >
                                  <img
                                    src={cancel}
                                    className="cancel-img"
                                    alt=""
                                  />{" "}
                                  Cancel
                                </button>
                              </Link>
                              <button
                                type="submit"
                                onClick={handleSubmit(onSubmit)}
                                className="btn btn-save"
                              >
                                <img
                                  src={save}
                                  className="save-img me-2"
                                  alt=""
                                />
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Ratable Value Updated Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Ratable Value Already Exists
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Edit;
