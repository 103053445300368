import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  memo,
} from "react";
import Step1 from "../StepForm/Step/Step1.js";
import Step2 from "../StepForm/Step/Step2.js";
import Step3 from "../StepForm/Step/Step3.js";
import Step4 from "../StepForm/Step/Step4.js";
import { Link } from "react-router-dom";
import "../create-bill/all-tab/alltabs.css";
import view from "./image/view.png";
import restore from "./image/restore.png";
import arrowimg from "./image/arrow-circle-right.png";
import { Context } from "../../../utils/context.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas);

const StepForm = memo((props) => {
  const { getData, deleteData } = useContext(Context);
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(0);
  const [formData, setFormData] = useState({});
  const [billinHistory, setBillinHistory] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const getPropertyData = useCallback(async () => {
    const response = await getData(
      "/createbill/propertyinfo/getallpropertydetails"
    );
    setData(response?.data);
  }, [getData]);

  useEffect(() => {
    getPropertyData();
  }, [reload, getPropertyData]);

  const handleClick = useCallback((e) => {
    setCurrentStep(Number(e.target.value));
  }, []);

  const HandleChangesss = useCallback((e) => {
    setCurrentStep(Number(e.target.value));
  }, []);

  const handleChange = useCallback((eventOrStep, value) => {
    if (typeof eventOrStep === "string") {
      if (eventOrStep === "start") {
        setCurrentStep(1);
      } else {
        setCurrentStep((prevStep) =>
          eventOrStep === "next" ? prevStep + 1 : prevStep - 1
        );
      }
    } else {
      const { name, value } = eventOrStep.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  }, []);

  const renderStep = useCallback(() => {
    switch (currentStep) {
      case 1:
        return (
          <Step1
            reload={props.isActive3}
            formData={formData}
            handleChange={handleChange}
            setFormData={setFormData}
          />
        );
      case 2:
        return (
          <Step2
            formData={formData}
            handleChange={handleChange}
            setFormData={setFormData}
          />
        );
      case 3:
        return (
          <Step3
            formData={formData}
            handleChange={handleChange}
            setFormData={setFormData}
          />
        );
      case 4:
        return (
          <Step4
            formData={formData}
            handleChange={handleChange}
            setFormData={setFormData}
          />
        );
      default:
        return null;
    }
  }, [currentStep, formData, handleChange, props.isActive3]);

  const showDeleteRecord = useCallback((id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  }, []);

  const handleDeleteRecord = useCallback(async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/createbill/propertyinfo/deletedata/${recordToDeleteId}`
      );
      if (response.success) {
        setRecordToDeleteId(null);
        setRecordToDeleteName(null);
        setReload(response);
      }
    }
  }, [deleteData, recordToDeleteId]);

  const handleDeleteCancel = useCallback(() => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  }, []);

  return (
    <>
      <section className="create-bill">
        <div className="container">
          <div className="row mt-4">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 p-0">
              <div
                className="nav nav-pills yellow-background me-3"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <div className="tabsverticalscroll mt-3">
                  {[
                    { label: "Property Info tab", value: 1 },
                    { label: "Construction Details tab", value: 2 },
                    { label: "Flat Details tab", value: 3 },
                    { label: "Room Details tab", value: 4 },
                  ].map(({ label, value }) => (
                    <button
                      key={value}
                      onClick={handleClick}
                      className={`nav-link ${
                        currentStep === value ? "active" : ""
                      } mb-3`}
                      value={value}
                      id={`v-pills-${label.split(" ")[0]}-tab`}
                      data-bs-toggle="pill"
                      data-bs-target={`#v-pills-${label.split(" ")[0]}`}
                      type="button"
                      role="tab"
                      aria-controls={`v-pills-${label.split(" ")[0]}`}
                      aria-selected={currentStep === value}
                    >
                      {label}{" "}
                      <img src={arrowimg} className="arrow-img" alt="Arrow" />
                    </button>
                  ))}
                  <button
                    className="show-bill-tab nav-link"
                    id="v-pills-Bill-tab"
                    type="button"
                    role="tab"
                    onClick={() => setBillinHistory(!billinHistory)}
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Bill History{" "}
                    <img src={arrowimg} className="arrow-img" alt="Arrow" />
                  </button>
                  <div
                    className={`collapse ${billinHistory ? "show" : ""} mt-3`}
                    id="collapseExample"
                  >
                    <div className="card history">
                      <div className="main p-3">
                        {data?.map((item, index) => (
                          <div key={index} className="row">
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-5 col-5">
                              <p className="kumar">
                                {item?.propertyownerdetails?.[0]?.first_name}{" "}
                                {item?.propertyownerdetails?.[0]?.last_name}
                              </p>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-5 col-5">
                              {item?.data_entry_head_accept_rejects?.length ? (
                                item?.data_entry_head_accept_rejects[0]
                                  ?.accept_reject_id !== null ? (
                                  <p
                                    className={
                                      item?.data_entry_head_accept_rejects[0]
                                        ?.accept_reject_id === 1
                                        ? "accept"
                                        : "reject"
                                    }
                                  >
                                    {
                                      item?.data_entry_head_accept_rejects[0]
                                        ?.accept_reject?.name
                                    }
                                  </p>
                                ) : (
                                  <p className="none">New</p>
                                )
                              ) : (
                                <p className="none">New</p>
                              )}
                            </div>
                            <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1">
                              <Link to={`/historyview/${item.id}`}>
                                <img
                                  src={view}
                                  className="view-img"
                                  alt="View"
                                />
                              </Link>
                            </div>
                            <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1">
                              <Link to={`/history/${item.id}`}>
                                <img
                                  src={restore}
                                  className="restore-img"
                                  alt="Restore"
                                />
                              </Link>
                            </div>
                            <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1">
                              <Link
                                onClick={() =>
                                  showDeleteRecord(
                                    item?.id,
                                    item?.propertyownerdetails[0]?.first_name
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  icon="fa-solid fa-trash-can"
                                  style={{ color: "#000000" }}
                                  className="restore-img mt-1"
                                />
                              </Link>
                            </div>
                            <hr />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12">
              <div>
                {renderStep()}
                {currentStep > 1 && (
                  <button
                    onClick={() => handleChange("prev")}
                    className="back-button-class"
                  ></button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div
              className="modal-content"
              style={{ backgroundColor: "#fe7600" }}
            >
              <div className="modal-body">
                <h1 className="add-success text-center mt-2">Are you sure?</h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br />
                  This Process Cannot Be Undone
                </p>
                <div className="button-holder text-center mt-2">
                  <button
                    className="btn btn-yes me-2"
                    onClick={handleDeleteRecord}
                  >
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default StepForm;
