// import { useState, useEffect } from "react";
// import { Link, useLocation } from "react-router-dom";
// import tax from "../../assets/icons/tax.png";
// import "../../Master/layout/Sidebar.css";
// import sub from "../../assets/icons/sub.png";
// import add1 from "../../assets/icons/add1.png";
// import icon from "../../assets/icons/Icon.png";
// import back from "../../assets/icons/back.png";
// import group from "../../assets/icons/Group.png";
// import document from "../../assets/icons/document.png";
// import notice from "../../assets/icons/Notice.png";
// import payment from "../../assets/icons/payement.png";
// import fine from "../../assets/icons/fine.png";
// import setting from "../../assets/icons/setting.png";
// import layer from "../../assets/icons/Layer.png";
// // import Tabels from '../Tabels/Tabels'
// import countries from "../../assets/icons/countries.png";
// import state from "../../assets/icons/state (2).png";
// import District from "../../assets/icons/District.png";
// import City from "../../assets/icons/city.png";
// import Block from "../../assets/icons/Block.png";
// import Ward from "../../assets/icons/ward.png";
// import pincode from "../../assets/icons/pincode.png";
// import property from "../../assets/icons/property.png";
// import Title from "../../assets/icons/Title.png";
// import PropertyHome from "../../assets/icons/PropertyHome.png";
// import usage from "../../assets/icons/usage.png";
// import Billing from "../../assets/icons/Billing.png";
// import land from "../../assets/icons/land.png";
// import tree from "../../assets/icons/tree.png";
// import master from "../../assets/icons/master.png";
// import room from "../../assets/icons/room.png";
// import Glyph from "../../assets/icons/Glyph.png";
// import lisence from "../../assets/icons/lisence.png";
// import occupation from "../../assets/icons/occupation.png";
// import construction from "../../assets/icons/construction.png";
// // import CustomerList from "../customer-master/List";
// // import DepartmentList from "../department/List";
// // import TaxMasterList from "../Tax-master/List";
// // import SubTaxList from "../sub-tax-master/List";
// // import TaxList from "../Tax/List";

// const Sidebar = () => {
//   const [isActive, setIsActive] = useState(false);
//   const handleClick = () => {
//     setIsActive(!isActive);
//   };

//   const location = useLocation();
//   const [headerText, setHeaderText] = useState(location.pathname);

//   useEffect(() => {
//     setHeaderText(location.pathname);
//   });

//   return (
//     <>
//       <div className=" col-xl-3 col-lg-4 col-md-12 background-col p-0">
//         <div
//           className="tax-tabs nav mt-5 Menu-List nav-pills"
//           id="v-pills-tab"
//           role="tablist"
//           aria-orientation="vertical"
//         >
//           <button
//             onClick={handleClick}
//             className={
//               headerText.includes("/tax-master") ||
//               headerText.includes("/master/ratablevalueslist")
//                 ? "nav-link active"
//                 : "nav-link mb-2"
//             }
//             data-bs-toggle="pill"
//             type="button"
//             role="tab"
//             aria-selected="true"
//           >
//             <Link className="butt-decoration" to="/tax-master/languages">
//               <img src={add1} className="tab-image me-4" alt="" />
//               Tax Master <img src={back} className="back me-4" alt="" />
//             </Link>
//           </button>

//           {/* Menu-list started */}
//           <div className="Menu-section">
//             <div
//               className={
//                 isActive ? "show-bill-tab  Menu-add" : " hide-bill-tab  "
//               }
//             >
//               <div className="row">
//                 <button
//                   className="nav-link"
//                   id="v-pills-Country-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Country"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Country"
//                   aria-selected="false"
//                 >
//                   <Link className="butt-decoration" to="/tax-master/languages">
//                     <div className="icon-background">
//                       {" "}
//                       <img src={countries} className="tab-image me-4" alt="" />
//                       Languages
//                     </div>
//                   </Link>
//                 </button>

//                 <button
//                   className="nav-link"
//                   id="v-pills-Country-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Country"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Country"
//                   aria-selected="false"
//                 >
//                   <Link className="butt-decoration" to="/tax-master/main-taxes">
//                     <div className="icon-background">
//                       {" "}
//                       <img src={countries} className="tab-image me-4" alt="" />
//                       Main Taxes
//                     </div>
//                   </Link>
//                 </button>

//                 <button
//                   className="nav-link"
//                   id="v-pills-Country-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Country"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Country"
//                   aria-selected="false"
//                 >
//                   <Link className="butt-decoration" to="/tax-master/taxes">
//                     <div className="icon-background">
//                       {" "}
//                       <img src={countries} className="tab-image me-4" alt="" />
//                       Taxes Types
//                     </div>
//                   </Link>
//                 </button>

//                 <button
//                   className="nav-link"
//                   id="v-pills-Country-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Country"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Country"
//                   aria-selected="false"
//                 >
//                   <Link
//                     className="butt-decoration"
//                     to="/tax-master/download-report"
//                   >
//                     <div className="icon-background">
//                       {" "}
//                       <img src={countries} className="tab-image me-4" alt="" />
//                       Download Report
//                     </div>
//                   </Link>
//                 </button>

//                 <button
//                   className="nav-link"
//                   id="v-pills-Country-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Country"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Country"
//                   aria-selected="false"
//                 >
//                   <Link
//                     className="butt-decoration"
//                     to="/tax-master/special-notice-report"
//                   >
//                     <div className="icon-background">
//                       {" "}
//                       <img src={countries} className="tab-image me-4" alt="" />
//                       Special Notice Report
//                     </div>
//                   </Link>
//                 </button>

//                 <button
//                   className="nav-link"
//                   id="v-pills-Country-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Country"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Country"
//                   aria-selected="false"
//                 >
//                   <Link className="butt-decoration" to="/tax-master/country">
//                     <div className="icon-background">
//                       {" "}
//                       <img src={countries} className="tab-image me-4" alt="" />
//                       Country
//                     </div>
//                   </Link>
//                 </button>

//                 <button
//                   className="nav-link"
//                   id="v-pills-State-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-State"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-State"
//                   aria-selected="false"
//                 >
//                   <Link className="butt-decoration" to="/tax-master/state">
//                     <div className="icon-background">
//                       {" "}
//                       <img src={state} className="tab-image me-4" alt="" />
//                       State
//                     </div>
//                   </Link>
//                 </button>

//                 {/* <button
//                   className="nav-link"
//                   id="v-pills-District-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-District"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-District"
//                   aria-selected="false"
//                 >
//                   <Link className="butt-decoration" to="/tax-master/zone">
//                     <div className="icon-background">
//                       {" "}
//                       <img src={District} className="tab-image me-4" alt="" />
//                       Zone
//                     </div>
//                   </Link>
//                 </button> */}

//                 <button
//                   className="nav-link"
//                   id="v-pills-City-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-City"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-City"
//                   aria-selected="false"
//                 >
//                   <Link to={"/tax-master/district"} className="butt-decoration">
//                     <div className="icon-background">
//                       {" "}
//                       <img src={City} className="tab-image me-4" alt="" />
//                       District
//                     </div>
//                   </Link>
//                 </button>

//                 <button
//                   className="nav-link"
//                   id="v-pills-Block-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Block"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Block"
//                   aria-selected="false"
//                 >
//                   <Link to={"/tax-master/taluka"} className="butt-decoration">
//                     <div className="icon-background">
//                       {" "}
//                       <img src={Block} className="tab-image me-4" alt="" />
//                       Taluka
//                     </div>
//                   </Link>
//                 </button>

//                 <button
//                   className="nav-link"
//                   id="v-pills-District-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-District"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-District"
//                   aria-selected="false"
//                 >
//                   <Link className="butt-decoration" to="/tax-master/city">
//                     <div className="icon-background">
//                       {" "}
//                       <img src={District} className="tab-image me-4" alt="" />
//                       City
//                     </div>
//                   </Link>
//                 </button>

//                 <button
//                   className="nav-link"
//                   id="v-pills-Block-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Block"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Block"
//                   aria-selected="false"
//                 >
//                   <Link to={"/tax-master/ward"} className="butt-decoration">
//                     <div className="icon-background">
//                       {" "}
//                       <img src={Block} className="tab-image me-4" alt="" />
//                       Ward
//                     </div>
//                   </Link>
//                 </button>

//                 <button
//                   className="nav-link"
//                   id="v-pills-Ward-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Ward"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Ward"
//                   aria-selected="false"
//                 >
//                   <Link to={"/tax-master/zone"} className="butt-decoration">
//                     <div className="icon-background">
//                       {" "}
//                       <img src={Ward} className="tab-image me-4" alt="" />
//                       Zone
//                     </div>
//                   </Link>
//                 </button>

//                 {/* <button
//                   className="nav-link"
//                   id="v-pills-Pincode-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Pincode"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Pincode"
//                   aria-selected="false"
//                 >
//                   <Link to={"/tax-master/pincode"} className="butt-decoration">
//                     <div className="icon-background">
//                       {" "}
//                       <img src={Ward} className="tab-image me-4" alt="" />
//                       Pincode
//                     </div>
//                   </Link>
//                 </button> */}

//                 <button
//                   className="nav-link"
//                   id="v-pills-Auth-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Auth"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Auth"
//                   aria-selected="false"
//                 >
//                   <Link className="butt-decoration" to="/tax-master/authstatus">
//                     {" "}
//                     <div className="icon-background">
//                       {" "}
//                       <img src={icon} className="tab-image me-4" alt="" />
//                       Auth. Status
//                     </div>{" "}
//                   </Link>
//                 </button>

//                 {/* <button
//                   className="nav-link"
//                   id="v-pills-Property-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills- Property"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Property"
//                   aria-selected="false"
//                 >
//                   <Link
//                     className="butt-decoration"
//                     to="/tax-master/propertystatus"
//                   >
//                     {" "}
//                     <div className="icon-background">
//                       {" "}
//                       <img src={property} className="tab-image me-4" alt="" />
//                       Property Status
//                     </div>{" "}
//                   </Link>
//                 </button> */}

//                 <button
//                   className="nav-link"
//                   id="v-pills-Title-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Title"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Title"
//                   aria-selected="false"
//                 >
//                   <Link className="butt-decoration" to="/tax-master/title">
//                     {" "}
//                     <div className="icon-background">
//                       {" "}
//                       <img src={Title} className="tab-image me-4" alt="" />
//                       Title
//                     </div>{" "}
//                   </Link>
//                 </button>

//                 <button
//                   className="nav-link"
//                   id="v-pills-PropertyType-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-PropertyType"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-PropertyType"
//                   aria-selected="false"
//                 >
//                   <Link
//                     className="butt-decoration"
//                     to="/tax-master/propertytype"
//                   >
//                     {" "}
//                     <div className="icon-background">
//                       {" "}
//                       <img
//                         src={PropertyHome}
//                         className="tab-image me-4"
//                         alt=""
//                       />
//                       Property Type
//                     </div>{" "}
//                   </Link>
//                 </button>

//                 <button
//                   className="nav-link"
//                   id="v-pills-PropertyType-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-PropertyType"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-PropertyType"
//                   aria-selected="false"
//                 >
//                   <Link
//                     className="butt-decoration"
//                     to="/tax-master/propertysubtypelist"
//                   >
//                     <div className="icon-background">
//                       <img
//                         src={PropertyHome}
//                         className="tab-image me-4"
//                         alt=""
//                       />
//                       Property SubType
//                     </div>
//                   </Link>
//                 </button>

//                 {/* <button
//                   className="nav-link"
//                   id="v-pills-PropertyType-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-PropertyType"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-PropertyType"
//                   aria-selected="false"
//                 >
//                   <Link className="butt-decoration" to="/tax-master/routelist">
//                     <div className="icon-background">
//                       <img src={District} className="tab-image me-4" alt="" />
//                       Route
//                     </div>
//                   </Link>
//                 </button> */}

//                 <button
//                   className="nav-link"
//                   id="v-pills-PropertyType-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-PropertyType"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-PropertyType"
//                   aria-selected="false"
//                 >
//                   <Link
//                     className="butt-decoration"
//                     to="/tax-master/usagetypelist"
//                   >
//                     <div className="icon-background">
//                       <img src={usage} className="tab-image me-4" alt="" />
//                       Usage Type
//                     </div>
//                   </Link>
//                 </button>

//                 <button
//                   className="nav-link"
//                   id="v-pills-PropertyType-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-PropertyType"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-PropertyType"
//                   aria-selected="false"
//                 >
//                   <Link
//                     className="butt-decoration"
//                     to="/tax-master/usagesubtypelist"
//                   >
//                     <div className="icon-background">
//                       <img src={usage} className="tab-image me-4" alt="" />
//                       Usage SubType
//                     </div>
//                   </Link>
//                 </button>

//                 <button
//                   className="nav-link"
//                   id="v-pills-Floor-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Floor"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Floor"
//                   aria-selected="false"
//                 >
//                   <Link className="butt-decoration" to="/tax-master/floor">
//                     {" "}
//                     <div className="icon-background">
//                       {" "}
//                       <img src={Billing} className="tab-image me-4" alt="" />
//                       Floor
//                     </div>{" "}
//                   </Link>
//                 </button>

//                 {/* <button
//                   className="nav-link"
//                   id="v-pills-Initials-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Initials"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Initials"
//                   aria-selected="false"
//                 >
//                   <Link className="butt-decoration" to="/tax-master/initials">
//                     {" "}
//                     <div className="icon-background">
//                       {" "}
//                       <img src={Billing} className="tab-image me-4" alt="" />
//                       Initials
//                     </div>{" "}
//                   </Link>
//                 </button> */}

//                 {/* <button
//                   className="nav-link"
//                   id="v-pills-BillingStructure-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-BillingStructure"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-BillingStructure"
//                   aria-selected="false"
//                 >
//                   <Link
//                     className="butt-decoration"
//                     to="/tax-master/billingstructure"
//                   >
//                     {" "}
//                     <div className="icon-background">
//                       {" "}
//                       <img src={Billing} className="tab-image me-4" alt="" />
//                       Building Structure
//                     </div>{" "}
//                   </Link>
//                 </button> */}

//                 {/* <button
//                   className="nav-link"
//                   id="v-pills-LandType-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-LandType"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-LandType"
//                   aria-selected="false"
//                 >
//                   <Link className="butt-decoration" to={`/tax-master/landtype`}>
//                     <div className="icon-background">
//                       <img src={land} className="tab-image me-4" alt="" />
//                       Land Type
//                     </div>
//                   </Link>
//                 </button> */}

//                 {/* <button
//                   className="nav-link"
//                   id="v-pills-LandUsage-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-LandUsage"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-LandUsage"
//                   aria-selected="false"
//                 >
//                   <Link
//                     className="butt-decoration"
//                     to={`/tax-master/landusetype`}
//                   >
//                     <div className="icon-background">
//                       {" "}
//                       <img src={tree} className="tab-image me-4" alt="" /> Land
//                       Usage Type
//                     </div>
//                   </Link>
//                 </button> */}

//                 {/* <button
//                   className="nav-link"
//                   id="v-pills-AgencyName-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-AgencyName"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-AgencyName"
//                   aria-selected="false"
//                 >
//                   <Link
//                     className="butt-decoration"
//                     to={`/tax-master/agencyname`}
//                   >
//                     <div className="icon-background">
//                       {" "}
//                       <img src={tree} className="tab-image me-4" alt="" />{" "}
//                       Agency Name
//                     </div>
//                   </Link>
//                 </button> */}

//                 {/* <button
//                   className="nav-link"
//                   id="v-pills-License-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-License"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-License"
//                   aria-selected="false"
//                 >
//                   <Link
//                     className="butt-decoration"
//                     to={`/tax-master/licencetype`}
//                   >
//                     <div className="icon-background">
//                       {" "}
//                       <img
//                         src={lisence}
//                         className="tab-image me-4"
//                         alt=""
//                       />{" "}
//                       License Type
//                     </div>
//                   </Link>
//                 </button> */}

//                 {/* <button
//                   className="nav-link"
//                   id="v-pills-Master-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Master"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Master"
//                   aria-selected="false"
//                 >
//                   <div className="icon-background">
//                     {" "}
//                     <img src={master} className="tab-image me-4" alt="" />
//                     Usage Subtype Master
//                   </div>
//                 </button> */}

//                 <button
//                   className="nav-link"
//                   id="v-pills-Master-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Master"
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Master"
//                   aria-selected="false"
//                 >
//                   <Link
//                     className="butt-decoration"
//                     to={`/tax-master/roadfactor`}
//                   >
//                     <div className="icon-background">
//                       {" "}
//                       <img src={master} className="tab-image me-4" alt="" />
//                       Road Factor
//                     </div>
//                   </Link>
//                 </button>

//                 <button
//                   className="nav-link"
//                   id="v-pills-Room-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Room "
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Room"
//                   aria-selected="false"
//                 >
//                   <Link className="butt-decoration" to={`/tax-master/room`}>
//                     <div className="icon-background">
//                       {" "}
//                       <img src={room} className="tab-image me-4" alt="" />
//                       Room Type
//                     </div>
//                   </Link>
//                 </button>

//                 {/* <button
//                   className="nav-link"
//                   id="v-pills-Company-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Company "
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Company"
//                   aria-selected="false"
//                 >
//                   <Link
//                     className="butt-decoration"
//                     to={`/tax-master/companystatus`}
//                   >
//                     <div className="icon-background">
//                       {" "}
//                       <img src={Glyph} className="tab-image me-4" alt="" />
//                       Company Status
//                     </div>
//                   </Link>
//                 </button> */}

//                 <button
//                   className="nav-link"
//                   id="v-pills-Occupation-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Occupation "
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Occupation"
//                   aria-selected="false"
//                 >
//                   <Link
//                     className="butt-decoration"
//                     to={`/tax-master/occupation`}
//                   >
//                     <div className="icon-background">
//                       {" "}
//                       <img src={occupation} className="tab-image me-4" alt="" />
//                       Occupation
//                     </div>
//                   </Link>
//                 </button>

//                 <button
//                   className="nav-link"
//                   id="v-pills-Construction-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Construction "
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Construction"
//                   aria-selected="false"
//                 >
//                   <Link
//                     className="butt-decoration"
//                     to={`/tax-master/construction`}
//                   >
//                     <div className="icon-background">
//                       {" "}
//                       <img
//                         src={construction}
//                         className="tab-image me-4"
//                         alt=""
//                       />{" "}
//                       Construction
//                     </div>
//                   </Link>
//                 </button>

//                 {/* <button
//                   className="nav-link"
//                   id="v-pills-Construction-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Construction "
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Construction"
//                   aria-selected="false"
//                 >
//                   <Link
//                     className="butt-decoration"
//                     to={`/tax-master/standard_rate/${1}`}
//                   >
//                     <div className="icon-background">
//                       {" "}
//                       <img
//                         src={construction}
//                         className="tab-image me-4"
//                         alt=""
//                       />{" "}
//                       Standard Rate
//                     </div>
//                   </Link>
//                 </button> */}

//                 <button
//                   className="nav-link"
//                   id="v-pills-Construction-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Construction "
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Construction"
//                   aria-selected="false"
//                 >
//                   <Link className="butt-decoration" to={`/tax-master/alv`}>
//                     <div className="icon-background">
//                       {" "}
//                       <img
//                         src={construction}
//                         className="tab-image me-4"
//                         alt=""
//                       />{" "}
//                       ALV
//                     </div>
//                   </Link>
//                 </button>

//                 <button
//                   className="nav-link"
//                   id="v-pills-Construction-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#v-pills-Construction "
//                   type="button"
//                   role="tab"
//                   aria-controls="v-pills-Construction"
//                   aria-selected="false"
//                 >
//                   <Link
//                     className="butt-decoration"
//                     to={`/master/ratablevalueslist`}
//                   >
//                     <div className="icon-background">
//                       {" "}
//                       <img
//                         src={construction}
//                         className="tab-image me-4"
//                         alt=""
//                       />{" "}
//                       {/* Ratable Values Master */}
//                       TAX RATE MASTER
//                     </div>
//                   </Link>
//                 </button>
//               </div>
//             </div>
//           </div>

//           {/* Menu-list end */}

//           {/* <button
//             className={
//               headerText === "/master/ratablevalueslist" ||
//               headerText.includes("/master/ratablevalues")
//                 ? "nav-link active"
//                 : "nav-link mb-2"
//             }
//             data-bs-toggle="pill"
//             type="button"
//             role="tab"
//             aria-selected="false"
//           >
//             <Link className="butt-decoration" to="/master/ratablevalueslist">
//               {" "}
//               <img src={sub} className="tab-image me-4" alt="" /> Ratable Values
//               Master{" "}
//             </Link>
//           </button> */}

//           {/* <button
//             className={
//               headerText === "/sub-tax-master"
//                 ? "nav-link active"
//                 : "nav-link mb-2"
//             }
//             data-bs-toggle="pill"
//             type="button"
//             role="tab"
//             aria-selected="false"
//           >
//             <Link className="butt-decoration" to="/sub-tax-master">
//               {" "}
//               <img src={sub} className="tab-image me-4" alt="" /> Sub-Tax Master{" "}
//             </Link>
//           </button> */}

//           {/* <button
//             className={
//               headerText === "/tax" ? "nav-link active" : "nav-link mb-2"
//             }
//             data-bs-toggle="pill"
//             type="button"
//             role="tab"
//             aria-selected="false"
//           >
//             <Link className="butt-decoration" to="/tax">
//               <img src={tax} className="tab-image me-4" alt="" />
//               Tax{" "}
//             </Link>
//           </button> */}

//           {/* <button
//             className={
//               headerText === "/customer-list"
//                 ? "nav-link active"
//                 : "nav-link mb-2"
//             }
//             data-bs-toggle="pill"
//             type="button"
//             role="tab"
//             aria-selected="false"
//           >
//             <Link className="butt-decoration" to="/customer-list">
//               <img src={group} className="tab-image me-4" alt="" />
//               Customer Master{" "}
//             </Link>
//           </button> */}

//           {/* <button
//             className={
//               headerText === "/document-master"
//                 ? "nav-link active"
//                 : "nav-link mb-2"
//             }
//             data-bs-toggle="pill"
//             type="button"
//             role="tab"
//             aria-selected="false"
//           >
//             <Link className="butt-decoration" to="/document-master">
//               {" "}
//               <img src={document} className="tab-image me-4" alt="" />
//               Document Master{" "}
//             </Link>
//           </button> */}

//           {/* <button
//             className={
//               headerText === "/notice-master"
//                 ? "nav-link active"
//                 : "nav-link mb-2"
//             }
//             data-bs-toggle="pill"
//             type="button"
//             role="tab"
//             aria-selected="false"
//           >
//             <Link className="butt-decoration" to="/notice-master">
//               {" "}
//               <img src={notice} className="tab-image me-4" alt="" />
//               Notice Master{" "}
//             </Link>
//           </button> */}

//           {/* <button
//             className={
//               headerText === "/payment-reminder"
//                 ? "nav-link active"
//                 : "nav-link mb-2"
//             }
//             data-bs-toggle="pill"
//             type="button"
//             role="tab"
//             aria-selected="false"
//           >
//             <Link className="butt-decoration" to="/payment-reminder">
//               {" "}
//               <img src={payment} className="tab-image me-4" alt="" />
//               Payment Remainder Master{" "}
//             </Link>
//           </button> */}

//           {/* <button
//             className={
//               headerText === "/fine-master"
//                 ? "nav-link active"
//                 : "nav-link mb-2"
//             }
//             data-bs-toggle="pill"
//             type="button"
//             role="tab"
//             aria-selected="false"
//           >
//             <Link className="butt-decoration" to="/fine-master">
//               {" "}
//               <img src={fine} className="tab-image me-4" alt="" />
//               Fine Master{" "}
//             </Link>
//           </button> */}

//           {/* <button
//             className={
//               headerText === "/department" ? "nav-link active" : "nav-link mb-2"
//             }
//             data-bs-toggle="pill"
//             type="button"
//             role="tab"
//             aria-selected="false"
//           >
//             <Link className="butt-decoration" to="/department">
//               {" "}
//               <img src={layer} className="tab-image me-4" alt="" />
//               Department
//             </Link>
//           </button> */}

//           {/* <button
//             className={
//               headerText === "/rolemanagement"
//                 ? "nav-link active"
//                 : "nav-link mb-2"
//             }
//             data-bs-toggle="pill"
//             type="button"
//             role="tab"
//             aria-selected="false"
//           >
//             <Link className="butt-decoration" to="/rolemanagement">
//               <img src={setting} className="tab-image me-4" alt="" />
//               Role Management
//             </Link>
//           </button> */}
//         </div>
//       </div>
//     </>
//   );
// };

// export default Sidebar;

// ------------------------------------------------------------------------
import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import tax from "../../assets/icons/tax.png";
import sub from "../../assets/icons/sub.png";
import add1 from "../../assets/icons/add1.png";
import icon from "../../assets/icons/Icon.png";
import back from "../../assets/icons/back.png";
import group from "../../assets/icons/Group.png";
import document from "../../assets/icons/document.png";
import notice from "../../assets/icons/Notice.png";
import payment from "../../assets/icons/payement.png";
import fine from "../../assets/icons/fine.png";
import setting from "../../assets/icons/setting.png";
import layer from "../../assets/icons/Layer.png";
import countries from "../../assets/icons/countries.png";
import state from "../../assets/icons/state (2).png";
import District from "../../assets/icons/District.png";
import City from "../../assets/icons/city.png";
import Block from "../../assets/icons/Block.png";
import Ward from "../../assets/icons/ward.png";
import pincode from "../../assets/icons/pincode.png";
import property from "../../assets/icons/property.png";
import Title from "../../assets/icons/Title.png";
import PropertyHome from "../../assets/icons/PropertyHome.png";
import usage from "../../assets/icons/usage.png";
import Billing from "../../assets/icons/Billing.png";
import land from "../../assets/icons/land.png";
import tree from "../../assets/icons/tree.png";
import master from "../../assets/icons/master.png";
import room from "../../assets/icons/room.png";
import Glyph from "../../assets/icons/Glyph.png";
import lisence from "../../assets/icons/lisence.png";
import occupation from "../../assets/icons/occupation.png";
import construction from "../../assets/icons/construction.png";

const Sidebar = () => {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  const handleClick = useCallback(() => {
    setIsActive((prevState) => !prevState);
  }, []);

  useEffect(() => {
    setHeaderText(location.pathname);
  }, [location.pathname]);

  const menuItems = [
    { path: "/tax-master/languages", label: "Languages", icon: countries },
    { path: "/tax-master/main-taxes", label: "Main Taxes", icon: countries },
    { path: "/tax-master/taxes", label: "Taxes Types", icon: countries },
    {
      path: "/tax-master/download-report",
      label: "Download Report",
      icon: countries,
    },
    {
      path: "/tax-master/special-notice-report",
      label: "Special Notice Report",
      icon: countries,
    },
    { path: "/tax-master/country", label: "Country", icon: countries },
    { path: "/tax-master/state", label: "State", icon: state },
    { path: "/tax-master/district", label: "District", icon: City },
    { path: "/tax-master/taluka", label: "Taluka", icon: Block },
    { path: "/tax-master/city", label: "City", icon: District },
    { path: "/tax-master/ward", label: "Ward", icon: Block },
    { path: "/tax-master/zone", label: "Zone", icon: Ward },
    { path: "/tax-master/authstatus", label: "Auth Status", icon: icon }, // Changed "Auth. Status" to "Auth Status"
    { path: "/tax-master/title", label: "Title", icon: Title },
    {
      path: "/tax-master/propertytype",
      label: "Property Type",
      icon: PropertyHome,
    },
    {
      path: "/tax-master/propertysubtypelist",
      label: "Property SubType",
      icon: PropertyHome,
    },
    { path: "/tax-master/usagetypelist", label: "Usage Type", icon: usage },
    {
      path: "/tax-master/usagesubtypelist",
      label: "Usage SubType",
      icon: usage,
    },
    { path: "/tax-master/floor", label: "Floor", icon: Billing },
    { path: "/tax-master/roadfactor", label: "Road Factor", icon: master },
    { path: "/tax-master/room", label: "Room Type", icon: room },
    { path: "/tax-master/occupation", label: "Occupation", icon: occupation },
    {
      path: "/tax-master/construction",
      label: "Construction",
      icon: construction,
    },
    { path: "/tax-master/alv", label: "ALV", icon: construction },
    {
      path: "/master/ratablevalueslist",
      label: "TAX RATE MASTER",
      icon: construction,
    },
  ];

  // Update the JSX to use the new label without periods
  return (
    <div className="col-xl-3 col-lg-4 col-md-12 background-col p-0">
      <div
        className="tax-tabs nav mt-5 Menu-List nav-pills"
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        <button
          onClick={handleClick}
          className={
            headerText.includes("/tax-master") ||
            headerText.includes("/master/ratablevalueslist")
              ? "nav-link active"
              : "nav-link mb-2"
          }
          data-bs-toggle="pill"
          type="button"
          role="tab"
          aria-selected="true"
        >
          <Link className="butt-decoration" to="/tax-master/languages">
            <img src={add1} className="tab-image me-4" alt="" />
            Tax Master <img src={back} className="back me-4" alt="" />
          </Link>
        </button>

        <div className="Menu-section">
          <div
            className={isActive ? "show-bill-tab Menu-add" : "hide-bill-tab"}
          >
            <div className="row">
              {menuItems.map((item, index) => (
                <button
                  key={index}
                  className="nav-link"
                  id={`v-pills-${item.label.replace(/\s+/g, "-")}-tab`} // Replace spaces with hyphens
                  data-bs-toggle="pill"
                  data-bs-target={`#v-pills-${item.label.replace(/\s+/g, "-")}`}
                  type="button"
                  role="tab"
                  aria-controls={`v-pills-${item.label.replace(/\s+/g, "-")}`}
                  aria-selected="false"
                >
                  <Link className="butt-decoration" to={item.path}>
                    <div className="icon-background">
                      <img src={item.icon} className="tab-image me-4" alt="" />
                      {item.label}
                    </div>
                  </Link>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Sidebar);
