import React, { useContext, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../Tax.css";
import circle from "../../../assets/icons/circle.png";
import Sidebar from "../../../Master/layout/Sidebar";
import { Context } from "../../../../../utils/context";
import { useForm, useFieldArray } from "react-hook-form";
import { getEditData } from "../../../../../utils/api";
import { ColorRing } from "react-loader-spinner";
const Edit = () => {
  const { getData, editData } = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    fontSize: "12px",
  };

  const errorBoarderStyle = {
    border: "1px solid red",
  };

  const relatable = {
    whiteSpace: "pre-wrap",
    width: "15%",
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      count: "",
      fields: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "fields",
  });

  const addNewField = () => {
    clearErrors();
    append({
      value_start: "",
      value_end: "",
      taxmul: "",
    });
  };

  const removeField = (index) => {
    remove(index);
  };

  const [countLang, setCountLang] = useState([]);

  const getLanguges = async () => {
    setLoading(true);
    const response = await getData(`/api/getlanguages`);
    setCountLang(response.data.data);
    setLoading(false);
  };

  useEffect(() => {
    getLanguges();
  }, []);
  const { id } = useParams();
  const GetEditData = async () => {
    setLoading(true);
    const response = await getEditData(
      `/master/taskmaster/authstatus/editauthstatus/${id}`
    );
    reset(response.data);
    setLoading(false);
    getLanguges();
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const onSubmit = async (data) => {
    try {
      if (!data.fields?.length) {
        setError("taxes", {
          type: "manual",
          message: "Add Taxes minimum required one",
        });
      } else {
        setLoading(true);
        const response = await editData(
          `/master/taskmaster/authstatus/editauthstatus/${id}`,
          data
        );
        if (response.success) {
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            setLoading(false);
            navigate("/tax-master/authstatus");
          }, 1000);
        } else {
          setShowErrorModal(true);
          setTimeout(() => {
            setShowErrorModal(false);
            setLoading(false);
            navigate(`/tax-master/authstatus`);
          }, 1000);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const preventMouseWheel = (e) => {
    e.target.blur();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container-fluid">
            <div className="row">
              <Sidebar />
              <div className="col-md-9">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="Back  me-4">
                        <Link to="/tax-master/authstatus">
                          <button type="button" className="btn btn-back">
                            Back{" "}
                          </button>
                        </Link>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <div className="row">
                        <div className="col">
                          <div className="main-form-section mt-3 ">
                            {countLang?.map((data, index) => {
                              return (
                                <div
                                  className="row justify-content-center mb-2 "
                                  key={index}
                                >
                                  <label
                                    htmlFor="inputEmail3"
                                    className="col-sm-3 col-form-label"
                                  >
                                    Auth Status {data?.name}
                                  </label>
                                  <div className="col-sm-6">
                                    <input
                                      {...register(`${data.id}`, {
                                        required: true,
                                      })}
                                      className={`form-control ${
                                        errors?.[data.id] ? "is-invalid" : ""
                                      }`}
                                    />
                                    {errors[data.id] && (
                                      <span style={errorStyle}>
                                        {"Auth Status in " + data.name}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          {errors.taxes && (
                            <span style={errorStyle}>
                              {errors?.taxes?.message}
                            </span>
                          )}
                          {isLoading ? (
                            <ColorRing
                              className="blocks-wrapper"
                              visible={true}
                              height="80"
                              width="80"
                              ariaLabel="blocks-loading"
                              wrapperStyle={{}}
                              wrapperClass="blocks-wrapper"
                              colors={[
                                "#e15b64",
                                "#f47e60",
                                "#f8b26a",
                                "#abbd81",
                                "#849b87",
                              ]}
                            />
                          ) : (
                            <div className="table-responsive">
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <table className="table table-bordered align-items-center table-sm mt-5">
                                  <thead className="thead-light">
                                    <tr>
                                      <th>No</th>
                                      <th>Taxes</th>
                                      <th>Tax Multiple</th>

                                      <th>Remove</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {fields.map((field, index) => (
                                      <tr key={field.id}>
                                        <td>{index + 1}</td>
                                        <td>
                                          <input
                                            type="number"
                                            placeholder="Tax Start"
                                            {...register(
                                              `fields.${index}.value_start`,
                                              { required: true }
                                            )}
                                            className={`form-control ${
                                              errors?.fields?.[index]
                                                ?.value_start
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            onWheel={preventMouseWheel}
                                          />
                                          To
                                          <input
                                            type="number"
                                            placeholder="Tax End"
                                            {...register(
                                              `fields.${index}.value_end`,
                                              { required: true }
                                            )}
                                            className={`form-control ${
                                              errors?.fields?.[index]?.value_end
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            onWheel={preventMouseWheel}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            placeholder="Tax M."
                                            {...register(
                                              `fields.${index}.taxmul`,
                                              { required: true }
                                            )}
                                            className={`form-control ${
                                              errors?.fields?.[index]?.taxmul
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            onWheel={preventMouseWheel}
                                          />
                                        </td>

                                        <td>
                                          <button
                                            type="button"
                                            className="btn btn-danger btn-small"
                                            onClick={() => removeField(index)}
                                          >
                                            &times;
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td colSpan="4" className="text-right">
                                        <button
                                          type="button"
                                          className="btn btn-info"
                                          onClick={addNewField}
                                        >
                                          + Add Row
                                        </button>
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </form>
                            </div>
                          )}
                          <div className="row  mt-5 pb-3">
                            <div className="d-flex justify-content-center">
                              <Link to="/tax-master/authstatus">
                                <button
                                  type="button"
                                  className="btn btn-cancel me-2"
                                >
                                  {" "}
                                  <img
                                    // src={cancel}
                                    className="cancel-img"
                                    alt=""
                                  />{" "}
                                  Cancel
                                </button>
                              </Link>
                              <button
                                type="submit"
                                onClick={handleSubmit(onSubmit)}
                                className="btn btn-save"
                              >
                                {" "}
                                <img
                                  // src={save}
                                  className="save-img me-2"
                                  alt=""
                                />
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Auth Status Updated Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Auth Status Already Exits
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit;
