// import React, { useState, useContext } from "react";
// import "../LoginPage/LoginPage.css";
// import { Link, useNavigate } from "react-router-dom";
// import { Context } from "../../../../utils/context";
// import Cookies from "js-cookie";
// import axios from "axios";
// import { Fixd_Usertype } from "../../../../utils/common";

// const LoginPage = () => {
//   const { postData, signin, setSignin, usertype, setUsertype } =
//     useContext(Context);

//   const navigate = useNavigate();

//   const [formData, setFormData] = useState({
//     email: "",
//     password: "",
//   });

//   const [errors, setErrors] = useState({});
//   const [showErrorModal, setShowErrorModal] = useState();
//   const [Hide, setHide] = useState(false);

//   const validateForm = () => {
//     let errors = {};

//     const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

//     if (!formData.email.trim()) {
//       errors.email = "Email is required";
//     } else if (!emailRegex.test(formData.email)) {
//       errors.email = "Enter a valid email address";
//     }

//     if (!formData.password.trim()) {
//       errors.password = "Password is required";
//     }

//     return errors;
//   };

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const validationErrors = validateForm();

//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//     } else {
//       try {
//         const response = await postData("/login/postlogin", formData);

//         if (response.success) {
//           setSignin(response.success);
//           setUsertype(response?.data?.role);

//           Cookies.set("billing_security", response.data?.token, { expires: 2 });
//           if (response?.data?.role === Fixd_Usertype.SuperAdmin) {
//             navigate("/dashboard");
//           } else if (response?.data?.role === Fixd_Usertype.DataEntryHead) {
//             navigate("/data-entry");
//           } else {
//             navigate(`/generatedbill`);
//           }
//         } else {
//           setShowErrorModal(response);
//         }
//       } catch (error) {
//         console.error("Error submitting form:", error);
//       }
//     }
//   };

//   const errorStyle = {
//     color: "red",
//     marginLeft: "5px",
//   };

//   const successStyle = {
//     color: "green",
//     marginLeft: "5px",
//   };
//   return (
//     <>
//       <section className="Login">
//         <div className="container-fluid p-0">
//           <div className="card text-center login-card p-5">
//             <div className="text-holder-login">
//               <h2>Login</h2>
//               <p>Hey, Enter your details to get sign in to your account</p>

//               {showErrorModal?.success ? (
//                 <></>
//               ) : (
//                 <>
//                   <span style={errorStyle}>{showErrorModal?.message}</span>
//                 </>
//               )}
//               <form onSubmit={handleSubmit} role="form">
//                 <div className="mb-3">
//                   <input
//                     type="email"
//                     className="form-control form-control-email"
//                     id="exampleInputEmail1"
//                     aria-describedby="emailHelp"
//                     placeholder="Enter Email"
//                     name="email"
//                     value={formData.email}
//                     onChange={handleChange}
//                   />
//                   {errors.email && (
//                     <span style={errorStyle}>{errors.email}</span>
//                   )}
//                 </div>
//                 <div className="input-group ">
//                   <input
//                     type={Hide ? "text" : "password"}
//                     className="form-control"
//                     placeholder="Password"
//                     aria-label="Recipient's username"
//                     aria-describedby="basic-addon2"
//                     name="password"
//                     value={formData.password}
//                     onChange={handleChange}
//                   />
//                   <button
//                     type="button"
//                     className="input-group-text hide-name"
//                     id="basic-addon2"
//                     onClick={() => {
//                       setHide(!Hide);
//                     }}
//                   >
//                     {Hide ? "Hide" : "Show"}
//                   </button>
//                 </div>
//                 {errors.password && (
//                   <span style={errorStyle}>{errors.password}</span>
//                 )}

//                 <Link to="/">
//                   <button
//                     type="submit"
//                     onClick={handleSubmit}
//                     className="btn sign-in-button text-center mt-3"
//                   >
//                     Sign in
//                   </button>
//                 </Link>
//               </form>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default LoginPage;

// ----------------------------------------------------------------------------------------

import React, { useState, useContext, useCallback } from "react";
import "../LoginPage/LoginPage.css";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../../utils/context";
import Cookies from "js-cookie";
import axios from "axios";
import { Fixd_Usertype } from "../../../../utils/common";

const LoginPage = () => {
  const { postData, setSignin, setUsertype } = useContext(Context);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [showErrorModal, setShowErrorModal] = useState(null);
  const [hidePassword, setHidePassword] = useState(false);

  const validateForm = useCallback(() => {
    const errors = {};
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      errors.email = "Enter a valid email address";
    }

    if (!formData.password.trim()) {
      errors.password = "Password is required";
    }

    return errors;
  }, [formData]);

  const handleChange = useCallback(
    (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    },
    [formData]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const validationErrors = validateForm();

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        try {
          const response = await postData("/login/postlogin", formData);

          if (response.success) {
            setSignin(true);
            setUsertype(response.data.role);

            Cookies.set("billing_security", response.data.token, {
              expires: 2,
            });

            switch (response.data.role) {
              case Fixd_Usertype.SuperAdmin:
                navigate("/dashboard");
                break;
              case Fixd_Usertype.DataEntryHead:
                navigate("/data-entry");
                break;
              default:
                navigate("/generatedbill");
            }
          } else {
            setShowErrorModal(response);
          }
        } catch (error) {
          console.error("Error submitting form:", error);
        }
      }
    },
    [formData, navigate, postData, setSignin, setUsertype, validateForm]
  );

  const errorStyle = { color: "red", marginLeft: "5px" };

  return (
    <section className="Login">
      <div className="container-fluid p-0">
        <div className="card text-center login-card p-5">
          <div className="text-holder-login">
            <h2>Login</h2>
            <p>Hey, Enter your details to get sign in to your account</p>

            {showErrorModal?.success ? null : (
              <span style={errorStyle}>{showErrorModal?.message}</span>
            )}

            <form onSubmit={handleSubmit} role="form">
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control form-control-email"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <span style={errorStyle}>{errors.email}</span>}
              </div>
              <div className="input-group">
                <input
                  type={hidePassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Password"
                  aria-label="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="input-group-text hide-name"
                  onClick={() => setHidePassword(!hidePassword)}
                >
                  {hidePassword ? "Hide" : "Show"}
                </button>
              </div>
              {errors.password && (
                <span style={errorStyle}>{errors.password}</span>
              )}

              <button
                type="submit"
                className="btn sign-in-button text-center mt-3"
              >
                Sign in
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(LoginPage);
