import React, { useContext, useEffect, useState } from "react";
import "../Step/step.css";
import saveimg from "../image/awesome-save.png";
import preimg from "../image/Icon awesome-backward.png";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { options } from "countup";
import { ColorRing } from "react-loader-spinner";

const Step3 = ({ formData, handleChange }) => {
  const { postData, getData, editData, minLength, maxLength } =
    useContext(Context);
  const [isLoading, setLoading] = useState(false);
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();

  // For Dropdown
  const [usageType, setUsageType] = useState();
  const [occupation, setOccupation] = useState();
  const [floor, setFloor] = useState();
  const [roadfactor, setRoadFactor] = useState();
  const [usageSubType, setUsageSubType] = useState();
  const [title, setTitle] = useState();

  const [formData1, setFormData1] = useState({
    property_no: "",
    sequence_no: "",
    old_property_no: "",
    ref_property_no: "",
    ward_id: "",
    zone_id: "",
    block_id: "",
    route_id: "",
    auth_status: "",
    property_status: "",
    authorised: false,
    legal: false,
    //
    location: "",
    current_ass_date: "",
    fields: [],

    remarks: "",
  });

  const [fields, setFields] = useState([{}]);

  // Function to add a new input field
  const addField = (type) => {
    const newId = getNextId(fields);
    const initial = "";
    const last_name = "";
    const first_name = "";
    const middle_name = "";

    const newField = {
      id: newId,
      // initial: initial,
      title_id: initial,
      type: type,
      last_name: last_name,
      first_name: first_name,
      middle_name: middle_name,
      flat_no: "",
      location: formData1.location,
      occupation_id: "",
      floor_id: "",
      current_ass_date: formData1.current_ass_date,
      property_info_id: formData1?.property_info_id,
      usagetype_id: usageType.find(
        (item) => item.value == formData1.usage_type
      ),
      usagesubtype_id: "",
      construction_class_id: "",
      provision_tenant: "",
      standard_rate: "",
      built_up_area: "",
      assessible_area: "",
      carpet_area: "",
      annual_rent: "",
      construction_cost: "",
      exempt_area: "",
      manual_rv: false,
      auto_rv: false,
      alv: "",
      rv: "",
      calulations_id: null,
    };
    setFields([...fields, newField]);
  };

  // Function to delete an input field by ID
  const deleteField = (idToDelete) => {
    const updatedFields = fields.filter((field) => field.id !== idToDelete);
    setFields(updateIds(updatedFields));
  };

  // Function to get the next available ID
  const getNextId = (fieldArray) => {
    let maxId = 0;
    fieldArray.forEach((field) => {
      if (field.id > maxId) {
        maxId = field.id;
      }
    });
    return maxId + 1;
  };

  // Function to reassign sequential IDs
  const updateIds = (fieldArray) => {
    return fieldArray.map((field, index) => ({
      ...field,
      id: index + 1,
    }));
  };

  // Function to handle input changes
  const handleInputChange = (id, fieldName, value, e = null) => {
    const updatedFields = fields.map((field) => {
      if (field.id === id) {
        if (fieldName == "occupation_id") {
          return {
            ...field,
            [fieldName]: {
              value: value,
              name: "occupation_id",
              label: e?.label,
            },
          };
        } else if (fieldName == "floor_id") {
          return {
            ...field,
            [fieldName]: {
              value: value,
              name: "floor_id",
              label: e?.label,
            },
          };
        } else if (fieldName == "roadfactor_id") {
          return {
            ...field,
            [fieldName]: {
              value: value,
              name: "roadfactor_id",
              label: e?.label,
            },
          };
        } else if (fieldName == "usagetype_id") {
          return {
            ...field,
            [fieldName]: {
              value: value,
              name: "usagetype_id",
              label: e?.label,
            },
            usagesubtype_id: "",
            calulations_id: null,
          };
        } else if (fieldName == "usagesubtype_id") {
          return {
            ...field,
            [fieldName]: {
              value: value,
              name: "usagesubtype_id",
              label: e?.label,
            },
          };
        } else if (fieldName == "construction_class_id") {
          return {
            ...field,
            [fieldName]: {
              value: value,
              name: "construction_class_id",
              label: e?.label,
            },
          };
        } else if (fieldName == "title_id") {
          return {
            ...field,
            [fieldName]: {
              value: value,
              name: "title_id",
              label: e?.label,
            },
          };
        } else {
          if (fieldName === "carpet_area") {
            return {
              ...field,
              [fieldName]: value,
              manual_rv: 0,
              auto_rv: 0,
              calulations_id: null,
            };
          } else if (fieldName === "alv") {
            return {
              ...field,
              [fieldName]: value,
              calulations_id: null,
            };
          } else {
            return {
              ...field,
              [fieldName]: value,
            };
          }
        }
      }
      return field;
    });
    setFields(updatedFields);
  };

  const getAllData = async () => {
    const createbillid = Cookies.get("createbillid");

    if (createbillid) {
      setLoading(true);
      const response = await editData(
        "/createbill/flatdetails/getcreatebill3",
        {
          id: createbillid,
        }
      );

      setFormData1(response?.data);
      setFields(response?.data?.fields);
      setManualVisible(response?.data?.manual_rv);
      setAutoVisible(response?.data?.auto_rv);
      setLoading(false);
    }
  };

  const getUsageType = async () => {
    const res = await getData("/api/getusagetype");
    const option = [];

    if (res.success) {
      res?.data?.map((data) => {
        option.push({
          value: data.usagetype_id,
          name: "usagetype_id",
          label: data.name,
        });
      });
    }

    await setUsageType(option);
  };

  useEffect(() => {
    getAllData();
    getUsageType();
  }, []);

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let errors = {};

    if (!formData1.property_no) {
      errors.property_no = "Property no is required";
    }

    if (!formData1.sequence_no) {
      errors.sequence_no = "Sequence no is required";
    }

    if (!formData1.old_property_no) {
      errors.old_property_no = "Old property no is required";
    }

    if (!formData1.ref_property_no) {
      errors.ref_property_no = "Refrence property no is required";
    }

    if (!formData1.ward_id) {
      errors.ward_id = "Ward is required";
    }

    if (!formData1.zone_id) {
      errors.zone_id = "Zone is required";
    }

    // if (!formData1.route_id) {
    //   errors.route_id = "Route is required";
    // }

    if (fields.length === 0) {
      errors.fields = "Add Owner or Rental is required";
    }

    if (!formData1.remarks) {
      errors.remarks = "Remarks is required";
    } else if (
      formData1.remarks.trim().length < minLength ||
      formData1.remarks.trim().length > maxLength
    ) {
      errors.remarks = `Remarks must be between ${minLength} and ${maxLength} characters`;
    }

    fields.forEach((field, index) => {
      if (!field.manual_rv && !field.auto_rv) {
        errors[`manual_rv-${field.id}`] = `${t(
          `thirdform.flatdetails.manualrv`
        )} /  ${t(`thirdform.flatdetails.autorv`)}`;
      }

      if (!field.flat_no) {
        errors[`flat_no-${field.id}`] = "Flat no is required";
      } else if (
        field.flat_no.trim().length < 1 ||
        field.flat_no.trim().length > maxLength
      ) {
        errors[
          `flat_no-${field.id}`
        ] = `Flat no must be between 1 and ${maxLength} characters`;
      }

      if (!field.floor_id) {
        errors[`floor_id-${field.id}`] = "Please Select Floor";
      }

      if (!field.current_ass_date) {
        errors[`current_ass_date-${field.id}`] = "Current Ass Date is required";
      }

      if (!field.usagetype_id) {
        errors[`usagetype_id-${field.id}`] = "Please Select Usage Type";
      }

      if (!field.usagesubtype_id) {
        errors[`usagesubtype_id-${field.id}`] = "Please Select Usage Sub Type";
      }

      if (!field.construction_class_id) {
        errors[`construction_class_id-${field.id}`] =
          "Please Select Construction Class";
      }

      if (!field.built_up_area) {
        errors[`built_up_area-${field.id}`] = "Built Up Area is required";
      } else if (
        field.built_up_area.trim().length < minLength ||
        field.built_up_area.trim().length > maxLength
      ) {
        errors[
          `built_up_area-${field.id}`
        ] = `Built Up Area must be between ${minLength} and ${maxLength} characters`;
      }

      if (!field.assessible_area) {
        errors[`assessible_area-${field.id}`] = "Assessible Area is required";
      } else if (
        field.assessible_area.trim().length < minLength ||
        field.assessible_area.trim().length > maxLength
      ) {
        errors[
          `assessible_area-${field.id}`
        ] = `Assessible Area must be between ${minLength} and ${maxLength} characters`;
      }

      if (!field.carpet_area.trim()) {
        errors[`carpet_area-${field.id}`] = "Carpet Area is required";
      } else if (
        field.carpet_area.trim().length < minLength ||
        field.carpet_area.trim().length > maxLength
      ) {
        errors[
          `carpet_area-${field.id}`
        ] = `Carpet Area must be between ${minLength} and ${maxLength} characters`;
      }

      if (!field.title_id) {
        errors[`title_id-${field.id}`] = "Please Select Title";
      }

      if (!field.last_name) {
        errors[`last_name-${field.id}`] = "Last Name is required";
      }
      // else if (!regexAlfaNumric.test(field.last_name.trim())) {
      //   errors[`last_name-${field.id}`] = "Enter a valid data";
      // }
      else if (
        field.last_name.trim().length < minLength ||
        field.last_name.trim().length > maxLength
      ) {
        errors[
          `last_name-${field.id}`
        ] = `Last Name must be between ${minLength} and ${maxLength} characters`;
      }

      if (!field.first_name) {
        errors[`first_name-${field.id}`] = "First Name is required";
      } else if (
        field.first_name.trim().length < minLength ||
        field.first_name.trim().length > maxLength
      ) {
        errors[
          `first_name-${field.id}`
        ] = `First Name must be between ${minLength} and ${maxLength} characters`;
      }

      if (!field.calulations_id) {
        errors[`alverror-${field.id}`] =
          "Please Calculate the taxes is required";
      }
    });

    return errors;
  };

  const handleBlur = async (e) => {
    let errors = {};

    switch (e.target.name) {
      case "flat_no":
        if (!e.target.value) {
          errors.flat_no = "Flat no is required";
        } else if (
          e.target.value.trim().length < 1 ||
          e.target.value.trim().length > maxLength
        ) {
          errors.flat_no = `Flat no must be between 1 and ${maxLength} characters`;
        }
        break;

      case "construction_class":
        if (!e.target.value) {
          errors.construction_class = "Construction class is required";
        } else if (
          e.target.value.trim().length < minLength ||
          e.target.value.trim().length > maxLength
        ) {
          errors.construction_class = `Construction class must be between ${minLength} and ${maxLength} characters`;
        }
        break;

      case "built_up_area":
        if (!e.target.value) {
          errors.built_up_area = "Built Up Area is required";
        } else if (
          e.target.value.trim().length < 2 ||
          e.target.value.trim().length > maxLength
        ) {
          errors.built_up_area = `Built Up Area must be between ${2} and ${maxLength}`;
        }
        break;

      case "assessible_area":
        if (!e.target.value) {
          errors.assessible_area = "Assessible Area is required";
        } else if (
          e.target.value.trim().length < 2 ||
          e.target.value.trim().length > maxLength
        ) {
          errors.assessible_area = `Assessible Area must be between ${2} and ${maxLength}`;
        }
        break;

      case "carpet_area":
        if (!e.target.value) {
          errors.carpet_area = "Carpet Area is required";
        } else if (
          e.target.value.trim().length < 2 ||
          e.target.value.trim().length > maxLength
        ) {
          errors.carpet_area = `Carpet Area must be between ${2} and ${maxLength}`;
        }
        break;

      case "remarks":
        if (!e.target.value) {
          errors.remarks = "Remarks is required";
        } else if (
          e.target.value.trim().length < minLength ||
          e.target.value.trim().length > maxLength
        ) {
          errors.remarks = `Remarks must be between ${minLength} and ${maxLength} characters`;
        }
        break;

      default:
        break;
    }

    await setErrors(errors);
  };
  const [construction, setConstruction] = useState();
  const handleChange1 = (e) => {
    if (
      e?.name == "occupation_id" ||
      e?.name == "usagetype_id" ||
      e?.name == "usagesubtype_id" ||
      e?.name == "construction_class_id" ||
      e?.name == "floor_id" ||
      e?.name == "title_id"
    ) {
      setFormData1({ ...formData1, [e.name]: e });
    } else {
      setFormData1({ ...formData1, [e.target.name]: e.target.value });
    }
  };

  const handleClicks = async (name, index) => {
    switch (name) {
      case "occupation_id": {
        const res = await getData("/api/getoccupation");
        const option = [];

        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.occupation_id,
              name: "occupation_id",
              label: data.name,
            });
          });
        }
        await setOccupation(option);
        handleInputChange(fields[index].id, "occupation_id", "", "");
        break;
      }

      case "floor_id": {
        const res = await getData("/api/getfloor");
        const option = [];

        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.floor_id,
              name: "floor_id",
              label: data.name,
            });
          });
        }
        await setFloor(option);
        handleInputChange(fields[index].id, "floor_id", "", "");
        break;
      }

      case "roadfactor_id": {
        const res = await getData("/api/get-road-factor");
        const option = [];

        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.roadfactor_id,
              name: "roadfactor_id",
              label: data.name,
            });
          });
        }
        await setRoadFactor(option);
        handleInputChange(fields[index].id, "roadfactor_id", "", "");
        break;
      }

      case "construction_class_id": {
        const res = await getData("/api/getconstruction");
        const option = [];

        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.construction_id,
              name: "construction_class_id",
              label: data.name,
            });
          });
        }
        await setConstruction(option);
        handleInputChange(fields[index].id, "construction_class_id", "", "");
        break;
      }

      case "usagesubtype_id": {
        const res = await editData("/api/getusagesubtype", {
          id: fields[index].usagetype_id?.value,
        });

        const option = [];
        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.usagesubtype_id,
              name: "usagesubtype_id",
              label: data.name,
            });
          });
        }

        await setUsageSubType(option);
        await handleInputChange(fields[index].id, "usagesubtype_id", "", "");
        break;
      }

      case "title_id": {
        const res = await getData("/api/gettitle");
        const option = [];
        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.title_id,
              name: "title_id",
              label: data.name,
            });
          });
        }
        await setTitle(option);
        setFormData1({
          ...formData1,
          title_id: "",
        });
        break;
      }

      default:
        break;
    }
  };

  useEffect(() => {
    setFormData1({ ...formData1, fields: fields });
  }, [fields]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors1 = validateForm();

    if (Object.keys(validationErrors1).length > 0) {
      setErrors(validationErrors1);
    } else {
      try {
        setLoading(true);
        formData1.fields = fields;

        const response = await postData(
          "/createbill/flatdetails/addflatdetails",
          formData1
        );
        if (response.success) {
          setLoading(false);
          handleChange("next");
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    fontSize: "11px",
  };

  const successStyle = {
    color: "green",
    marginLeft: "5px",
    fontSize: "11px",
  };

  const [isManualVisible, setManualVisible] = useState(false);
  const [isAutoVisible, setAutoVisible] = useState(false);
  const handleCheckboxChange = async (id, fieldName, value, e, index) => {
    if (fieldName === "auto_rv") {
      if (e.target.checked) {
        const errors = {};
        if (!formData1.property_no) {
          errors.property_no = "Property no is required";
        }

        if (!fields[index].usagesubtype_id) {
          errors[`usagesubtype_id-${id}`] = "Subtype is required";
          setErrors(errors);
        }

        if (fields[index].usagesubtype_id?.value === "") {
          errors[`usagesubtype_id-${id}`] = "Subtype is required";
          setErrors(errors);
        }

        if (!fields[index].carpet_area.trim()) {
          errors[`carpet_area-${id}`] = "Carpet Area is required";
          setErrors(errors);
        } else if (
          fields[index].carpet_area.trim().length < minLength ||
          fields[index].carpet_area.trim().length > maxLength
        ) {
          errors[
            `carpet_area-${id}`
          ] = `Carpet Area must be between ${minLength} and ${maxLength} characters`;
          setErrors(errors);
        } else {
          setErrors(errors);
          const updatedFields = fields.map((field) => {
            if (field.id === id) {
              if (fieldName === "manual_rv") {
                return {
                  ...field,
                  manual_rv: field.manual_rv === 1 ? 0 : 1,
                  auto_rv: 0,
                };
              } else if (fieldName === "auto_rv") {
                return {
                  ...field,
                  auto_rv: field.auto_rv === 1 ? 0 : 1,
                  manual_rv: 0,
                };
              }
            }
            return field;
          });

          await setFields(updatedFields);
          const data = await editData("/createbill/flatdetails/caldetails", {
            property_info_id: formData1?.property_info_id,
            r_id: fields[index].id,
            carpet_area: fields[index].carpet_area,
            usagetype_id: fields[index].usagetype_id?.value,
            usagesubtype_id: fields[index].usagesubtype_id?.value,
            usage_type: formData1.usage_type,
            type: fieldName,
          });
          if (data.success) {
            setFormData1({
              ...formData1,
              auto_rv: true,
              alv: data.data.alv,
              rv: data.data.rv,
              property_tax: data.data.property_tax,
              residential: data.data.residential,
              non_residential: data.data.non_residential,
              employment_tax_on_nonresidential_property:
                data.data.employment_tax_on_nonresidential_property,
              tree_tax: data.data.tree_tax,
              fire_tax: data.data.fire_tax,
              taxes: data.data.taxes,
              maintaxes: data.data.maintaxes,
            });
            setFields((prevFields) => {
              const newFields = [...prevFields];
              newFields[index] = {
                ...newFields[index],
                calulations_id: data.data.calulations_id,
              };
              return newFields;
            });

            setShowPopup(!showPopup);
            setAutoVisible(!isAutoVisible);
          } else {
            setFormData1({
              ...formData1,
              auto_rv: true,
              rv: "",
              property_tax: "",
              residential: "",
              non_residential: "",
              employment_tax_on_nonresidential_property: "",
              tree_tax: "",
              fire_tax: "",
            });

            if (data.code) {
              const errors = {};
              errors[`alverror-${fields[index].id}`] = data.message;
              setErrors(errors);
            }
          }
        }
      }
    } else {
      const updatedFields = fields.map((field) => {
        if (field.id === id) {
          if (fieldName === "manual_rv") {
            return {
              ...field,
              manual_rv: field.manual_rv === 1 ? 0 : 1,
              auto_rv: 0,
            };
          } else if (fieldName === "auto_rv") {
            return {
              ...field,
              auto_rv: field.auto_rv === 1 ? 0 : 1,
              manual_rv: 0,
            };
          }
        }
        return field;
      });

      await setFields(updatedFields);
    }
  };

  const CalculateManual = async (id, index, e) => {
    const errors = {};
    const regexNumber = /^\d/;

    if (!formData1.property_no) {
      errors.property_no = "Property no is required";
      setErrors(errors);
    }
    if (!fields[index].alv) {
      if (fields[index].alv == 0) {
      } else {
        errors[`alv-${id}`] = "ALV is required";
        setErrors({ ...errors }); // Always use setErrors with a new object to trigger re-render
      }
    } else {
      setErrors(errors);
      const data = await editData("/createbill/flatdetails/caldetails", {
        property_info_id: formData1?.property_info_id,
        alv: fields[index].alv,
        r_id: fields[index].id,
        usagetype_id: fields[index].usagetype_id?.value,
        usagesubtype_id: fields[index].usagesubtype_id?.value,
        usage_type: formData1.usage_type,
        type: "manual_rv",
      });

      if (data.success) {
        setFormData1({
          ...formData1,
          manual_rv: true,
          alv: data.data.alv,
          rv: data.data.rv,
          property_tax: data.data.property_tax,
          residential: data.data.residential,
          non_residential: data.data.non_residential,
          employment_tax_on_nonresidential_property:
            data.data.employment_tax_on_nonresidential_property,
          tree_tax: data.data.tree_tax,
          fire_tax: data.data.fire_tax,
          taxes: data.data.taxes,
          maintaxes: data.data.maintaxes,
        });
        setFields((prevFields) => {
          const newFields = [...prevFields];
          newFields[index] = {
            ...newFields[index],
            calulations_id: data.data.calulations_id,
          };
          return newFields;
        });
        setShowPopup(!showPopup);
        setAutoVisible(!isAutoVisible);
      } else {
        setFormData1({
          ...formData1,
          manual_rv: true,
          rv: "",
          property_tax: "",
          residential: "",
          non_residential: "",
          employment_tax_on_nonresidential_property: "",
          tree_tax: "",
          fire_tax: "",
        });

        if (data.code) {
          const errors = {};
          errors[`alverror-${fields[index].id}`] = data.message;
          setErrors(errors);
        }
      }
    }
  };

  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="row">
      <div className="tabepane-start">
        <div className="scroll-tab d-flex justify-content-between">
          <div className="button-holder-2">
            <button
              className=" btn text-start ms-2 previous-button"
              onClick={() => handleChange("prev")}
            >
              <img src={preimg} /> Back
            </button>
          </div>
          <div className="button-holder me-2">
            <button
              className="btn btn-save "
              onClick={(e) => !isLoading && handleSubmit(e)}
              disabled={isLoading}
              aria-label="Save"
            >
              <img src={saveimg} className="save-img me-2" alt="Save Icon" />
              Save
            </button>
          </div>
        </div>
        {isLoading ? (
          <ColorRing
            className="blocks-wrapper"
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        ) : (
          <form className="" role="form">
            <div className="Property-Form">
              <div className="container">
                <div className="row mt-4">
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                    <label htmlFor="inputPassword5" className="form-label">
                      {t("firstform.propertyinfo.propertyno")}{" "}
                      <span className="reuiredstart">*</span>
                    </label>
                    <input
                      type="text"
                      id="inputPassword5"
                      name="property_no"
                      className="form-control"
                      value={formData1?.property_no}
                      aria-describedby="passwordHelpBlock"
                      disabled
                    />
                    {errors.property_no && (
                      <span style={errorStyle}>{errors.property_no}</span>
                    )}
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                    <label htmlFor="inputPassword5" className="form-label">
                      {t("firstform.propertyinfo.sequenceno")}{" "}
                      <span className="reuiredstart">*</span>
                    </label>
                    <input
                      type="text"
                      name="sequence_no"
                      id="inputPassword5"
                      className="form-control"
                      value={formData1?.sequence_no}
                      aria-describedby="passwordHelpBlock"
                      disabled
                    />
                    {errors.sequence_no && (
                      <span style={errorStyle}>{errors.sequence_no}</span>
                    )}
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                    <label htmlFor="inputPassword5" className="form-label">
                      {t("firstform.propertyinfo.oldpropno")}{" "}
                      <span className="reuiredstart">*</span>
                    </label>
                    <input
                      type="text"
                      name="old_property_no"
                      id="inputPassword5"
                      value={formData1?.old_property_no}
                      className="form-control"
                      aria-describedby="passwordHelpBlock"
                      disabled
                    />
                    {errors.old_property_no && (
                      <span style={errorStyle}>{errors.old_property_no}</span>
                    )}
                  </div>

                  <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                    <label htmlFor="inputPassword5" className="form-label">
                      {t("firstform.propertyinfo.refprono")}{" "}
                      <span className="reuiredstart">*</span>
                    </label>
                    <input
                      type="text"
                      name="ref_property_no"
                      id="inputPassword5"
                      className="form-control"
                      value={formData1?.ref_property_no}
                      aria-describedby="passwordHelpBlock"
                      placeholder=" "
                      disabled
                    />
                    {errors.ref_property_no && (
                      <span style={errorStyle}>{errors.ref_property_no}</span>
                    )}
                  </div>

                  <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                    <label htmlFor="inputPassword5" className="form-label">
                      {t("firstform.propertyinfo.ward")}{" "}
                      <span className="reuiredstart">*</span>
                    </label>
                    <input
                      type="text"
                      name="ward_id"
                      id="inputPassword5"
                      className="form-control"
                      value={formData1?.ward_id}
                      aria-describedby="passwordHelpBlock"
                      placeholder=" "
                      disabled
                    />
                    {errors.ward_id && (
                      <span style={errorStyle}>{errors.ward_id}</span>
                    )}
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                    <label htmlFor="inputPassword5" className="form-label">
                      {t("firstform.propertyinfo.zone")}{" "}
                      <span className="reuiredstart">*</span>
                    </label>
                    <input
                      type="text"
                      name="zone_id"
                      id="inputPassword5"
                      className="form-control"
                      value={formData1?.zone_id}
                      aria-describedby="passwordHelpBlock"
                      placeholder=" "
                      disabled
                    />

                    {errors.zone_id && (
                      <span style={errorStyle}>{errors.zone_id}</span>
                    )}
                  </div>

                  <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                    <label htmlFor="inputPassword5" className="form-label">
                      {t("firstform.propertyinfo.route")}{" "}
                      {/* <span className="reuiredstart">*</span> */}
                    </label>
                    <input
                      type="text"
                      name="route_id"
                      id="inputPassword5"
                      className="form-control"
                      value={formData1?.route_id}
                      aria-describedby="passwordHelpBlock"
                      placeholder=" "
                      disabled
                    />
                    {errors.route_id && (
                      <span style={errorStyle}>{errors.route_id}</span>
                    )}
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                    <label htmlFor="inputPassword5" className="form-label">
                      {t("firstform.propertyinfo.authstatus")}
                    </label>
                    <input
                      type="text"
                      name="auth_status"
                      id="inputPassword5"
                      className="form-control"
                      value={formData1?.auth_status}
                      aria-describedby="passwordHelpBlock"
                      placeholder=" "
                      disabled
                    />
                    {errors.auth_status && (
                      <span style={errorStyle}>{errors.auth_status}</span>
                    )}
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                    <label htmlFor="inputPassword5" className="form-label">
                      {t("firstform.propertyinfo.propertystatus")}
                    </label>
                    <input
                      type="text"
                      name="property_status"
                      id="inputPassword5"
                      className="form-control"
                      value={formData1?.property_status}
                      aria-describedby="passwordHelpBlock"
                      placeholder=" "
                      disabled
                    />
                    {errors.property_status && (
                      <span style={errorStyle}>{errors.property_status}</span>
                    )}
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                    <label className="form-label">
                      {t("firstform.propertyinfo.authorised")} ?
                    </label>
                    <div className="col-2">
                      <input
                        type="checkbox"
                        aria-describedby="passwordHelpBlock"
                        name="authorised"
                        checked={formData1?.authorised}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                    <label htmlFor="inputPassword5" className="form-label">
                      {t("firstform.propertyinfo.legal")}
                    </label>
                    <div className="col-2">
                      <input
                        type="checkbox"
                        name="legal"
                        checked={formData1?.legal}
                        aria-describedby="passwordHelpBlock"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="line-bottom-class mt-3 "></div>

                {/* Residential Flat Details */}
                <div>
                  <div>
                    <h2 className="mt-3">Residential Flat Details</h2>
                  </div>

                  <div className="row">
                    {fields?.map((field, index) => (
                      <>
                        {field.type == 1 ? (
                          <>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.flatno")}{" "}
                                <span className="reuiredstart">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={field?.flat_no}
                                name={`flat_no-${field?.id}`}
                                id={`flat_no-${field?.id}`}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "flat_no",
                                    e.target.value
                                  )
                                }
                                aria-describedby="passwordHelpBlock"
                              />

                              {errors[`flat_no-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`flat_no-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.floor_id")}{" "}
                                <span className="reuiredstart">*</span>
                              </label>
                              <Select
                                className="custom-select mb-2"
                                value={field?.floor_id}
                                onFocus={() => {
                                  handleClicks("floor_id", index);
                                }}
                                name={`floor_id-${field?.id}`}
                                id={`floor_id-${field?.id}`}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "floor_id",
                                    e.value,
                                    e
                                  )
                                }
                                options={floor}
                              />
                              {errors[`floor_id-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`floor_id-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("firstform.propertyaddress.location")}{" "}
                                <span className="reuiredstart">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                value={field?.location}
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors[`location-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`location-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.occupancystatus")}{" "}
                                {/* <span className="reuiredstart">*</span> */}
                              </label>
                              <Select
                                className="custom-select mb-2"
                                value={field?.occupation_id}
                                onFocus={() => {
                                  handleClicks("occupation_id", index);
                                }}
                                name={`occupation_id-${field?.id}`}
                                id={`occupation_id-${field?.id}`}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "occupation_id",
                                    e.value,
                                    e
                                  )
                                }
                                options={occupation}
                              />
                              {errors[`occupation_id-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`occupation_id-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t(
                                  "firstform.assessmentdatedetails.currentassdate"
                                )}{" "}
                                <span className="reuiredstart">*</span>
                              </label>

                              <input
                                type="date"
                                id="inputPassword5"
                                max={new Date().toISOString().split("T")[0]}
                                className="form-control"
                                name="current_ass_date"
                                disabled={true}
                                value={
                                  formData1?.current_ass_date
                                    ? new Date(formData1.current_ass_date)
                                        .toISOString()
                                        .split("T")[0]
                                    : ""
                                }
                                onChange={handleChange1}
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors.current_ass_date && (
                                <span style={errorStyle}>
                                  {errors.current_ass_date}
                                </span>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("firstform.propertydetails.usagetype")}{" "}
                                <span className="reuiredstart">*</span>
                              </label>
                              <Select
                                className="custom-select mb-2"
                                value={field?.usagetype_id}
                                // isDisabled
                                onFocus={() => {
                                  handleClicks("usagetype_id", index);
                                }}
                                name={`usagetype_id-${field?.id}`}
                                id={`usagetype_id-${field?.id}`}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "usagetype_id",
                                    e.value,
                                    e
                                  )
                                }
                                options={usageType}
                              />

                              {errors[`usagetype_id-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`usagetype_id-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("firstform.propertydetails.Subtype")}{" "}
                                <span className="reuiredstart">*</span>
                              </label>
                              <Select
                                className="custom-select mb-2"
                                onFocus={() => {
                                  handleClicks("usagesubtype_id", index);
                                }}
                                value={field?.usagesubtype_id}
                                name={`usagesubtype_id-${field?.id}`}
                                id={`usagesubtype_id-${field?.id}`}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "usagesubtype_id",
                                    e.value,
                                    e
                                  )
                                }
                                options={usageSubType}
                              />

                              {errors[`usagesubtype_id-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`usagesubtype_id-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-3 col-sm-3 col-4 p-1">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.constructionclass")}{" "}
                                <span className="reuiredstart">*</span>
                              </label>
                              <Select
                                className="custom-select mb-2"
                                onFocus={() => {
                                  handleClicks("construction_class_id", index);
                                }}
                                value={field?.construction_class_id}
                                name={`construction_class_id-${field?.id}`}
                                id={`construction_class_id-${field?.id}`}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "construction_class_id",
                                    e.value,
                                    e
                                  )
                                }
                                options={construction}
                              />

                              {errors[`construction_class_id-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`construction_class_id-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t(
                                  "thirdform.flatdetails.provisiontoentertenant"
                                )}{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id={`provision_tenant-${field?.id}`}
                                name={`provision_tenant-${field?.id}`}
                                value={field?.provision_tenant}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "provision_tenant",
                                    e.target.value
                                  )
                                }
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors.provision_tenant && (
                                <span style={errorStyle}>
                                  {errors.provision_tenant}
                                </span>
                              )}
                            </div>

                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                              <div className="row">
                                <p className="mt-4">Owner Name</p>

                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                  <label
                                    htmlFor="inputPassword5"
                                    className="form-label"
                                  ></label>

                                  <Select
                                    name={`title_id-${field?.id}`}
                                    className="custom-select"
                                    value={field?.title_id}
                                    onFocus={() => {
                                      handleClicks("title_id");
                                    }}
                                    onChange={(e) => {
                                      handleInputChange(
                                        field?.id,
                                        "title_id",
                                        e.value,
                                        e
                                      );
                                    }}
                                    options={title}
                                  />
                                  {errors[`title_id-${field?.id}`] && (
                                    <div style={errorStyle}>
                                      {errors[`title_id-${field?.id}`]}
                                    </div>
                                  )}
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                  <label
                                    htmlFor="inputPassword5"
                                    className="form-label"
                                  ></label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t(
                                      "firstform.propertyownerdetails.lastname"
                                    )}
                                    id={`last_name-${field?.id}`}
                                    name={`last_name-${field?.id}`}
                                    value={field?.last_name}
                                    onChange={(e) =>
                                      handleInputChange(
                                        field?.id,
                                        "last_name",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <div
                                    id="passwordHelpBlock"
                                    className="form-text"
                                  >
                                    <p className="text-center">
                                      {t(
                                        "firstform.propertyownerdetails.lastname"
                                      )}{" "}
                                      <span className="reuiredstart">*</span>
                                    </p>
                                  </div>
                                  {errors[`last_name-${field?.id}`] && (
                                    <div style={errorStyle}>
                                      {errors[`last_name-${field?.id}`]}
                                    </div>
                                  )}
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                  <label
                                    htmlFor="inputPassword5"
                                    className="form-label"
                                  ></label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name={`first_name-${field?.id}`}
                                    id={`first_name-${field?.id}`}
                                    placeholder={t(
                                      "firstform.propertyownerdetails.firstname"
                                    )}
                                    value={field?.first_name}
                                    onChange={(e) =>
                                      handleInputChange(
                                        field?.id,
                                        "first_name",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <div
                                    id="passwordHelpBlock"
                                    className="form-text"
                                  >
                                    <p className="text-center">
                                      {t(
                                        "firstform.propertyownerdetails.firstname"
                                      )}{" "}
                                      <span className="reuiredstart">*</span>
                                    </p>
                                  </div>
                                  {errors[`first_name-${field?.id}`] && (
                                    <div style={errorStyle}>
                                      {errors[`first_name-${field?.id}`]}
                                    </div>
                                  )}
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                  <label
                                    htmlFor="inputPassword5"
                                    className="form-label"
                                  ></label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name={`middle_name-${field?.id}`}
                                    id={`middle_name-${field?.id}`}
                                    placeholder={t(
                                      "firstform.propertyownerdetails.middlename"
                                    )}
                                    value={field?.middle_name}
                                    onChange={(e) =>
                                      handleInputChange(
                                        field?.id,
                                        "middle_name",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <div
                                    id="passwordHelpBlock"
                                    className="form-text"
                                  >
                                    <p className="text-center">
                                      {" "}
                                      {t(
                                        "firstform.propertyownerdetails.middlename"
                                      )}
                                    </p>
                                  </div>
                                  {errors[`middle_name-${field?.id}`] && (
                                    <div style={errorStyle}>
                                      {errors[`middle_name-${field?.id}`]}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label "
                              >
                                {t("thirdform.flatdetails.standardrate")}
                              </label>
                              <input
                                type="text"
                                name={`standard_rate-${field?.id}`}
                                id={`standard_rate-${field?.id}`}
                                value={field?.standard_rate}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "standard_rate",
                                    e.target.value
                                  )
                                }
                                className="form-control"
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors[`standard_rate-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`standard_rate-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.roadfactor_id")}{" "}
                                {/* <span className="reuiredstart">*</span> */}
                              </label>
                              <Select
                                className="custom-select mb-2"
                                value={field?.roadfactor_id}
                                onFocus={() => {
                                  handleClicks("roadfactor_id", index);
                                }}
                                name={`roadfactor_id-${field?.id}`}
                                id={`roadfactor_id-${field?.id}`}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "roadfactor_id",
                                    e.value,
                                    e
                                  )
                                }
                                options={roadfactor}
                              />
                              {errors[`roadfactor_id-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`roadfactor_id-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.builtuparea")}{" "}
                                <span className="reuiredstart">*</span>
                              </label>
                              <input
                                type="number"
                                name={`built_up_area-${field?.id}`}
                                id={`built_up_area-${field?.id}`}
                                value={field?.built_up_area}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "built_up_area",
                                    e.target.value
                                  )
                                }
                                className="form-control"
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors[`built_up_area-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`built_up_area-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.carpetarea")}{" "}
                                <span className="reuiredstart">*</span>
                              </label>
                              <input
                                type="number"
                                name={`carpet_area-${field?.id}`}
                                id={`carpet_area-${field?.id}`}
                                value={field?.carpet_area}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "carpet_area",
                                    e.target.value
                                  )
                                }
                                className="form-control"
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors[`carpet_area-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`carpet_area-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.annualrent")}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name={`annual_rent-${field?.id}`}
                                id={`annual_rent-${field?.id}`}
                                value={field?.annual_rent}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "annual_rent",
                                    e.target.value
                                  )
                                }
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors[`annual_rent-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`annual_rent-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.constructioncost")}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name={`construction_cost-${field?.id}`}
                                id={`construction_cost-${field?.id}`}
                                value={field?.construction_cost}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "construction_cost",
                                    e.target.value
                                  )
                                }
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors[`construction_cost-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`construction_cost-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.exemptarea")}
                              </label>
                              <input
                                type="text"
                                name={`exempt_area-${field?.id}`}
                                id={`exempt_area-${field?.id}`}
                                value={field?.exempt_area}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "exempt_area",
                                    e.target.value
                                  )
                                }
                                className="form-control"
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors[`exempt_area-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`exempt_area-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.assessiblearea")}{" "}
                                <span className="reuiredstart">*</span>
                              </label>
                              <input
                                type="number"
                                name={`assessible_area-${field?.id}`}
                                id={`assessible_area-${field?.id}`}
                                value={field?.assessible_area}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "assessible_area",
                                    e.target.value
                                  )
                                }
                                className="form-control"
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors[`assessible_area-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`assessible_area-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.manualrv")}
                              </label>
                              <div className="col-2">
                                <input
                                  type="checkbox"
                                  id="inputPassword5"
                                  name="manual_rv"
                                  onClick={(e) =>
                                    handleCheckboxChange(
                                      field?.id,
                                      "manual_rv",
                                      e.target.checked,
                                      e,
                                      index
                                    )
                                  }
                                  checked={field?.manual_rv}
                                  aria-describedby="passwordHelpBlock"
                                />
                              </div>
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.autorv")}
                              </label>
                              <div className="col-2">
                                <input
                                  type="checkbox"
                                  id="inputPassword5"
                                  name="auto_rv"
                                  onClick={(e) =>
                                    handleCheckboxChange(
                                      field?.id,
                                      "auto_rv",
                                      e.target.checked,
                                      e,
                                      index
                                    )
                                  }
                                  checked={field?.auto_rv}
                                  aria-describedby="passwordHelpBlock"
                                />
                              </div>
                            </div>

                            {errors[`manual_rv-${field?.id}`] && (
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <label
                                  htmlFor="inputPassword5"
                                  className="form-label"
                                ></label>

                                <div className="col-6" style={errorStyle}>
                                  {errors[`manual_rv-${field?.id}`]}
                                </div>
                              </div>
                            )}

                            {field?.manual_rv == 1 && (
                              <>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                  <label
                                    htmlFor="inputPassword5"
                                    className="form-label"
                                  >
                                    {t("thirdform.flatdetails.alv")}
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    required
                                    name={`alv-${field?.id}`}
                                    id={`alv-${field?.id}`}
                                    value={field?.alv}
                                    onChange={(e) =>
                                      handleInputChange(
                                        field?.id,
                                        "alv",
                                        e.target.value
                                      )
                                    }
                                    aria-describedby="passwordHelpBlock"
                                  />
                                  {errors[`alv-${field?.id}`] && (
                                    <div style={errorStyle}>
                                      {errors[`alv-${field?.id}`]}
                                    </div>
                                  )}
                                </div>

                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                  <div className="mt-4 ">
                                    <button
                                      type="button"
                                      className="form-button-class mt-2 me-5"
                                      onClick={(e) => {
                                        CalculateManual(field?.id, index, e);
                                      }}
                                    >
                                      Calculate Tax
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}

                            {errors[`alverror-${field?.id}`] && (
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <label
                                  htmlFor="inputPassword5"
                                  className="form-label"
                                ></label>

                                <div className="col-6" style={errorStyle}>
                                  {errors[`alverror-${field?.id}`]}
                                </div>
                              </div>
                            )}

                            {/* {field?.id == 1 ? (
                            <></>
                          ) : ( */}
                            <div className="text-end">
                              <button
                                type="button"
                                className="form-button-class me-5"
                                onClick={() => deleteField(field?.id)}
                              >
                                Remove
                              </button>
                            </div>
                            {/* )} */}

                            <div className="line-bottom-class mt-2 "></div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}

                    {formData1.usage_type == 3 || formData1.usage_type ? (
                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                        <div className="row">
                          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            {errors.fields && (
                              <span style={errorStyle}>{errors.fields}</span>
                            )}

                            <div className="text-end">
                              <button
                                type="button"
                                className="form-button-class mt-2 me-5"
                                onClick={() => {
                                  addField(1);
                                }}
                              >
                                Additional Owner
                              </button>

                              {/* <button
                              type="button"
                              className="form-button-class mt-2 me-5"
                              onClick={() => {
                                addField(1);
                              }}
                            >
                              Additional Owner
                            </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="line-bottom-class mt-2 "></div>

                    {/* <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                    <label htmlFor="inputPassword5" className="form-label">
                      {t("firstform.buildingstructure.remarks")}
                      <span className="reuiredstart">*</span>
                    </label>

                    <input
                      type="text"
                      id="inputPassword5"
                      name="remarks"
                      onChange={handleChange1}
                      onInput={handleBlur}
                      value={formData1?.remarks}
                      className="form-control"
                      aria-describedby="passwordHelpBlock"
                    />
                    {errors.remarks && (
                      <span style={errorStyle}>{errors.remarks}</span>
                    )}
                  </div> */}
                  </div>
                </div>

                <div>
                  <div>
                    <h2 className="mt-3">Non Residential Flat Details</h2>
                  </div>

                  <div className="row">
                    {fields?.map((field, index) => (
                      <>
                        {field.type == 2 ? (
                          <>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.flatno")}{" "}
                                <span className="reuiredstart">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={field?.flat_no}
                                name={`flat_no-${field?.id}`}
                                id={`flat_no-${field?.id}`}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "flat_no",
                                    e.target.value
                                  )
                                }
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors[`flat_no-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`flat_no-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.floor_id")}{" "}
                                <span className="reuiredstart">*</span>
                              </label>
                              <Select
                                className="custom-select mb-2"
                                value={field?.floor_id}
                                onFocus={() => {
                                  handleClicks("floor_id", index);
                                }}
                                name={`floor_id-${field?.id}`}
                                id={`floor_id-${field?.id}`}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "floor_id",
                                    e.value,
                                    e
                                  )
                                }
                                options={floor}
                              />
                              {errors[`floor_id-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`floor_id-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("firstform.propertyaddress.location")}{" "}
                                <span className="reuiredstart">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                value={field?.location}
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors[`location-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`location-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.occupancystatus")}{" "}
                                {/* <span className="reuiredstart">*</span> */}
                              </label>
                              <Select
                                className="custom-select mb-2"
                                value={field?.occupation_id}
                                onFocus={() => {
                                  handleClicks("occupation_id", index);
                                }}
                                name={`occupation_id-${field?.id}`}
                                id={`occupation_id-${field?.id}`}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "occupation_id",
                                    e.value,
                                    e
                                  )
                                }
                                options={occupation}
                              />
                              {errors[`occupation_id-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`occupation_id-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t(
                                  "firstform.assessmentdatedetails.currentassdate"
                                )}{" "}
                                <span className="reuiredstart">*</span>
                              </label>

                              <input
                                type="date"
                                id="inputPassword5"
                                max={new Date().toISOString().split("T")[0]}
                                className="form-control"
                                name="current_ass_date"
                                disabled={true}
                                value={
                                  formData1?.current_ass_date
                                    ? new Date(formData1.current_ass_date)
                                        .toISOString()
                                        .split("T")[0]
                                    : ""
                                }
                                onChange={handleChange1}
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors.current_ass_date && (
                                <span style={errorStyle}>
                                  {errors.current_ass_date}
                                </span>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("firstform.propertydetails.usagetype")}{" "}
                                <span className="reuiredstart">*</span>
                              </label>
                              <Select
                                className="custom-select mb-2"
                                value={field?.usagetype_id}
                                // isDisabled
                                onFocus={() => {
                                  handleClicks("usagetype_id", index);
                                }}
                                name={`usagetype_id-${field?.id}`}
                                id={`usagetype_id-${field?.id}`}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "usagetype_id",
                                    e.value,
                                    e
                                  )
                                }
                                options={usageType}
                              />

                              {errors[`usagetype_id-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`usagetype_id-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("firstform.propertydetails.Subtype")}{" "}
                                <span className="reuiredstart">*</span>
                              </label>
                              <Select
                                className="custom-select mb-2"
                                onFocus={() => {
                                  handleClicks("usagesubtype_id", index);
                                }}
                                value={field?.usagesubtype_id}
                                name={`usagesubtype_id-${field?.id}`}
                                id={`usagesubtype_id-${field?.id}`}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "usagesubtype_id",
                                    e.value,
                                    e
                                  )
                                }
                                options={usageSubType}
                              />

                              {errors[`usagesubtype_id-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`usagesubtype_id-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-3 col-sm-3 col-4 p-1">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.constructionclass")}{" "}
                                <span className="reuiredstart">*</span>
                              </label>
                              <Select
                                className="custom-select mb-2"
                                onFocus={() => {
                                  handleClicks("construction_class_id", index);
                                }}
                                value={field?.construction_class_id}
                                name={`construction_class_id-${field?.id}`}
                                id={`construction_class_id-${field?.id}`}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "construction_class_id",
                                    e.value,
                                    e
                                  )
                                }
                                options={construction}
                              />

                              {errors[`construction_class_id-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`construction_class_id-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t(
                                  "thirdform.flatdetails.provisiontoentertenant"
                                )}{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id={`provision_tenant-${field?.id}`}
                                name={`provision_tenant-${field?.id}`}
                                value={field?.provision_tenant}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "provision_tenant",
                                    e.target.value
                                  )
                                }
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors.provision_tenant && (
                                <span style={errorStyle}>
                                  {errors.provision_tenant}
                                </span>
                              )}
                            </div>

                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                              <div className="row">
                                <p className="mt-4">Owner Name</p>

                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                  <label
                                    htmlFor="inputPassword5"
                                    className="form-label"
                                  ></label>
                                  <Select
                                    name={`title_id-${field?.id}`}
                                    className="custom-select"
                                    value={field?.title_id}
                                    onFocus={() => {
                                      handleClicks("title_id");
                                    }}
                                    onChange={(e) => {
                                      handleInputChange(
                                        field?.id,
                                        "title_id",
                                        e.value,
                                        e
                                      );
                                    }}
                                    options={title}
                                  />
                                  {errors[`title_id-${field?.id}`] && (
                                    <div style={errorStyle}>
                                      {errors[`title_id-${field?.id}`]}
                                    </div>
                                  )}
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                  <label
                                    htmlFor="inputPassword5"
                                    className="form-label"
                                  ></label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t(
                                      "firstform.propertyownerdetails.lastname"
                                    )}
                                    id={`last_name-${field?.id}`}
                                    name={`last_name-${field?.id}`}
                                    value={field?.last_name}
                                    onChange={(e) =>
                                      handleInputChange(
                                        field?.id,
                                        "last_name",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <div
                                    id="passwordHelpBlock"
                                    className="form-text"
                                  >
                                    <p className="text-center">
                                      {t(
                                        "firstform.propertyownerdetails.lastname"
                                      )}{" "}
                                      <span className="reuiredstart">*</span>
                                    </p>
                                  </div>
                                  {errors[`last_name-${field?.id}`] && (
                                    <div style={errorStyle}>
                                      {errors[`last_name-${field?.id}`]}
                                    </div>
                                  )}
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                  <label
                                    htmlFor="inputPassword5"
                                    className="form-label"
                                  ></label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name={`first_name-${field?.id}`}
                                    id={`first_name-${field?.id}`}
                                    placeholder={t(
                                      "firstform.propertyownerdetails.firstname"
                                    )}
                                    value={field?.first_name}
                                    onChange={(e) =>
                                      handleInputChange(
                                        field?.id,
                                        "first_name",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <div
                                    id="passwordHelpBlock"
                                    className="form-text"
                                  >
                                    <p className="text-center">
                                      {t(
                                        "firstform.propertyownerdetails.firstname"
                                      )}{" "}
                                      <span className="reuiredstart">*</span>
                                    </p>
                                  </div>
                                  {errors[`first_name-${field?.id}`] && (
                                    <div style={errorStyle}>
                                      {errors[`first_name-${field?.id}`]}
                                    </div>
                                  )}
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                  <label
                                    htmlFor="inputPassword5"
                                    className="form-label"
                                  ></label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name={`middle_name-${field?.id}`}
                                    id={`middle_name-${field?.id}`}
                                    placeholder={t(
                                      "firstform.propertyownerdetails.middlename"
                                    )}
                                    value={field?.middle_name}
                                    onChange={(e) =>
                                      handleInputChange(
                                        field?.id,
                                        "middle_name",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <div
                                    id="passwordHelpBlock"
                                    className="form-text"
                                  >
                                    <p className="text-center">
                                      {" "}
                                      {t(
                                        "firstform.propertyownerdetails.middlename"
                                      )}
                                    </p>
                                  </div>
                                  {errors[`middle_name-${field?.id}`] && (
                                    <div style={errorStyle}>
                                      {errors[`middle_name-${field?.id}`]}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label "
                              >
                                {t("thirdform.flatdetails.standardrate")}
                              </label>
                              <input
                                type="text"
                                name={`standard_rate-${field?.id}`}
                                id={`standard_rate-${field?.id}`}
                                value={field?.standard_rate}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "standard_rate",
                                    e.target.value
                                  )
                                }
                                className="form-control"
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors[`standard_rate-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`standard_rate-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.roadfactor_id")}{" "}
                                {/* <span className="reuiredstart">*</span> */}
                              </label>
                              <Select
                                className="custom-select mb-2"
                                value={field?.roadfactor_id}
                                onFocus={() => {
                                  handleClicks("roadfactor_id", index);
                                }}
                                name={`roadfactor_id-${field?.id}`}
                                id={`roadfactor_id-${field?.id}`}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "roadfactor_id",
                                    e.value,
                                    e
                                  )
                                }
                                options={roadfactor}
                              />
                              {errors[`roadfactor_id-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`roadfactor_id-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.builtuparea")}{" "}
                                <span className="reuiredstart">*</span>
                              </label>
                              <input
                                type="number"
                                name={`built_up_area-${field?.id}`}
                                id={`built_up_area-${field?.id}`}
                                value={field?.built_up_area}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "built_up_area",
                                    e.target.value
                                  )
                                }
                                className="form-control"
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors[`built_up_area-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`built_up_area-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.carpetarea")}{" "}
                                <span className="reuiredstart">*</span>
                              </label>
                              <input
                                type="number"
                                name={`carpet_area-${field?.id}`}
                                id={`carpet_area-${field?.id}`}
                                value={field?.carpet_area}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "carpet_area",
                                    e.target.value
                                  )
                                }
                                className="form-control"
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors[`carpet_area-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`carpet_area-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.annualrent")}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name={`annual_rent-${field?.id}`}
                                id={`annual_rent-${field?.id}`}
                                value={field?.annual_rent}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "annual_rent",
                                    e.target.value
                                  )
                                }
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors[`annual_rent-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`annual_rent-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.constructioncost")}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name={`construction_cost-${field?.id}`}
                                id={`construction_cost-${field?.id}`}
                                value={field?.construction_cost}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "construction_cost",
                                    e.target.value
                                  )
                                }
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors[`construction_cost-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`construction_cost-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.exemptarea")}
                              </label>
                              <input
                                type="text"
                                name={`exempt_area-${field?.id}`}
                                id={`exempt_area-${field?.id}`}
                                value={field?.exempt_area}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "exempt_area",
                                    e.target.value
                                  )
                                }
                                className="form-control"
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors[`exempt_area-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`exempt_area-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.assessiblearea")}{" "}
                                <span className="reuiredstart">*</span>
                              </label>
                              <input
                                type="number"
                                name={`assessible_area-${field?.id}`}
                                id={`assessible_area-${field?.id}`}
                                value={field?.assessible_area}
                                onChange={(e) =>
                                  handleInputChange(
                                    field?.id,
                                    "assessible_area",
                                    e.target.value
                                  )
                                }
                                className="form-control"
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors[`assessible_area-${field?.id}`] && (
                                <div style={errorStyle}>
                                  {errors[`assessible_area-${field?.id}`]}
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.manualrv")}
                              </label>
                              <div className="col-2">
                                <input
                                  type="checkbox"
                                  id="inputPassword5"
                                  name="manual_rv"
                                  onClick={(e) =>
                                    handleCheckboxChange(
                                      field?.id,
                                      "manual_rv",
                                      e.target.checked,
                                      e,
                                      index
                                    )
                                  }
                                  checked={field?.manual_rv}
                                  aria-describedby="passwordHelpBlock"
                                />
                              </div>
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("thirdform.flatdetails.autorv")}
                              </label>
                              <div className="col-2">
                                <input
                                  type="checkbox"
                                  id="inputPassword5"
                                  name="auto_rv"
                                  onClick={(e) =>
                                    handleCheckboxChange(
                                      field?.id,
                                      "auto_rv",
                                      e.target.checked,
                                      e,
                                      index
                                    )
                                  }
                                  checked={field?.auto_rv}
                                  aria-describedby="passwordHelpBlock"
                                />
                              </div>
                            </div>

                            {errors[`manual_rv-${field?.id}`] && (
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <label
                                  htmlFor="inputPassword5"
                                  className="form-label"
                                ></label>

                                <div className="col-6" style={errorStyle}>
                                  {errors[`manual_rv-${field?.id}`]}
                                </div>
                              </div>
                            )}

                            {field?.manual_rv == 1 && (
                              <>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                  <label
                                    htmlFor="inputPassword5"
                                    className="form-label"
                                  >
                                    {t("thirdform.flatdetails.alv")}
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    required
                                    name={`alv-${field?.id}`}
                                    id={`alv-${field?.id}`}
                                    value={field?.alv}
                                    onChange={(e) =>
                                      handleInputChange(
                                        field?.id,
                                        "alv",
                                        e.target.value
                                      )
                                    }
                                    aria-describedby="passwordHelpBlock"
                                  />
                                  {errors[`alv-${field?.id}`] && (
                                    <div style={errorStyle}>
                                      {errors[`alv-${field?.id}`]}
                                    </div>
                                  )}
                                </div>

                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                  <div className="mt-4 ">
                                    <button
                                      type="button"
                                      className="form-button-class mt-2 me-5"
                                      onClick={(e) => {
                                        CalculateManual(field?.id, index, e);
                                      }}
                                    >
                                      Calculate Tax
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}

                            {errors[`alverror-${field?.id}`] && (
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <label
                                  htmlFor="inputPassword5"
                                  className="form-label"
                                ></label>

                                <div className="col-6" style={errorStyle}>
                                  {errors[`alverror-${field?.id}`]}
                                </div>
                              </div>
                            )}

                            {/* {field?.id == 1 ? (
                            <></>
                          ) : ( */}
                            <div className="text-end">
                              <button
                                type="button"
                                className="form-button-class me-5"
                                onClick={() => deleteField(field?.id)}
                              >
                                Remove
                              </button>
                            </div>
                            {/* )} */}

                            <div className="line-bottom-class mt-2 "></div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}

                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                      <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          {errors.fields && (
                            <span style={errorStyle}>{errors.fields}</span>
                          )}
                          <div className="text-end">
                            <button
                              type="button"
                              className="form-button-class mt-2 me-5"
                              onClick={() => {
                                addField(2);
                              }}
                            >
                              Additional Owner
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.buildingstructure.remarks")}
                        <span className="reuiredstart">*</span>
                      </label>

                      <input
                        type="text"
                        id="inputPassword5"
                        name="remarks"
                        onChange={handleChange1}
                        onInput={handleBlur}
                        value={formData1?.remarks}
                        className="form-control"
                        aria-describedby="passwordHelpBlock"
                      />
                      {errors.remarks && (
                        <span style={errorStyle}>{errors.remarks}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>

      {showPopup && (
        <div
          className={`modal fade ${showPopup ? "show" : ""}`}
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          style={{ display: showPopup ? "block" : "none" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">
                  Calculated Tax
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={togglePopup}
                ></button>
              </div>
              <div className="modal-body">
                {/* Modal Form start */}
                <section className="ModalForm">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("thirdform.flatdetails.alv")}
                        </label>
                        <input
                          type="number"
                          id="inputPassword5"
                          className="form-control"
                          name="alv"
                          value={formData1?.alv}
                          disabled
                          aria-describedby="passwordHelpBlock"
                        />
                      </div>

                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("thirdform.flatdetails.rv")}
                        </label>
                        <input
                          type="number"
                          id="inputPassword5"
                          className="form-control"
                          name="rv"
                          required
                          disabled
                          value={formData1?.rv}
                          aria-describedby="passwordHelpBlock"
                        />
                      </div>

                      {formData1?.maintaxes?.map((tax, index) => (
                        <div
                          className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3"
                          key={index}
                        >
                          <div className="">
                            <label
                              htmlFor="inputPassword5"
                              className="form-label"
                            >
                              {tax.name}
                            </label>
                            <input
                              id="inputPassword5"
                              className="form-control"
                              required
                              disabled
                              value={tax?.no}
                              aria-describedby="passwordHelpBlock"
                            />
                          </div>
                        </div>
                      ))}

                      {formData1?.taxes?.map((tax, index) => (
                        <div
                          className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3"
                          key={index}
                        >
                          <div className="">
                            <label
                              htmlFor="inputPassword5"
                              className="form-label"
                            >
                              {tax.name}
                            </label>
                            <input
                              id="inputPassword5"
                              className="form-control"
                              required
                              disabled
                              value={tax?.no}
                              aria-describedby="passwordHelpBlock"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
                {/* Modal Form ends */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  // onClick={handleCheckboxChange}
                  onClick={togglePopup}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Step3;
