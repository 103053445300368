import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import arrow from "../../../assets/icons/arrow.png";
import plus from "../../../assets/icons/a1.png";
import colunms from "../../../assets/icons/LINES.png";
import pen from "../../../assets/icons/pen.png";
import basket from "../../../assets/icons/basket.png";
import search1 from "../../../assets/icons/search.png";
import top from "../../../assets/icons/top.png";
import blackeye from "../../../assets/icons/blackeye.png";
import { Context } from "../../../../../utils/context";
import { all } from "axios";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
library.add(fas);

const Table = () => {
  const { getData, editStatusData, deleteData, ErrorNotify } =
    useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();

  const [changeStatus, setChangeStatus] = useState();

  // const ErrorNotify = () => {
  //   toast.error("Floor deleted successfully.", {
  //     position: "top-right",
  //     autoClose: 100,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "dark",
  //     style: { background: "red", color: "white" },
  //   });
  // };
  const [option, setOption] = useState();
  const getDataFloor = async () => {
    const response = await getData(
      `/master/taskmaster/floor/getfloor?page=${currentPage}&per_page=${perPage}&term=${search}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    // setCurrentPage(response?.data?.);

    const a = [];
    for (let i = 5; i <= response?.data?.totalEntries; i *= 2) {
      a.push(i);
    }
    a.push(response?.data?.totalEntries);

    setOption(a);
    const newData = response?.data?.floor;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    getDataFloor();
  }, [changeStatus, perPage, reset]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(
      `/master/taskmaster/floor/editstatusfloor/${id}`
    );
    setChangeStatus(response);
  };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/master/taskmaster/floor/deletefloor/${recordToDeleteId}`
      );

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  return (
    <>
      <div className="col-xxl-12 col-xl-12  ">
        <section className="Tabels tab-radio tab-radio mt-5">
          <div className="container">
            <div className="row">
              <div className="d-flex">
                <div className="add me-3">
                  <Link
                    to="/tax-master/flooradd"
                    type="button"
                    className="btn btn-add pe-3"
                  >
                    {" "}
                    <img src={plus} className="plus me-2 ms-0" alt="" /> Add
                    Floor{" "}
                  </Link>
                </div>

                <div className="add">
                  <div className="dropdown">
                    <button
                      className="btn btn-columns dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={colunms} className="columns " alt="" /> Column
                      Selection <img src={top} className="top ms-1" alt="" />{" "}
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <Link
                          className="dropdown-item"
                          onClick={(event) => toggleColumn(event, "col0")}
                          href="#"
                        >
                          Check Boxs
                          {visible?.col0 ? (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye"
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye-slash"
                            />
                          )}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          onClick={(event) => toggleColumn(event, "col1")}
                          href="#"
                        >
                          Sr. No.
                          {visible?.col1 ? (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye"
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye-slash"
                            />
                          )}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          onClick={(event) => toggleColumn(event, "col2")}
                          href="#"
                        >
                          Floor
                          {visible?.col2 ? (
                            <FontAwesomeIcon
                              className="ms-2 eye-icon-img"
                              icon="fa-solid fa-eye"
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="ms-2 eye-icon-img"
                              icon="fa-solid fa-eye-slash"
                            />
                          )}
                        </Link>
                      </li>

                      <li>
                        <Link
                          className="dropdown-item"
                          onClick={(event) => toggleColumn(event, "col3")}
                          href="#"
                        >
                          Status
                          {visible?.col3 ? (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye"
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye-slash"
                            />
                          )}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          onClick={(event) => toggleColumn(event, "col4")}
                          href="#"
                        >
                          Action
                          {visible?.col4 ? (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye"
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye-slash"
                            />
                          )}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-line mt-3"></div>
            <div className="row mt-3">
              <div className="data table-responsive">
                <table className="table table-bordered  tableborder">
                  <thead>
                    <tr className="">
                      {visible.col0 && (
                        <th className="check round-check">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="selectAll"
                              checked={
                                allChecked.length === selectAllChecked.length
                              }
                              onChange={handleChange}
                              id="selectAll"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            ></label>
                          </div>
                        </th>
                      )}
                      {visible.col1 && <th className="sr">Sr. No.</th>}
                      {visible.col2 && <th className="tax-name">Floor</th>}
                      {visible.col3 && <th className="tax-name">Status</th>}
                      {visible.col4 && <th className="active">Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.floor?.map((d, index) => {
                      return (
                        <tr className="" key={index}>
                          {visible.col0 && (
                            <td>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={d?.id}
                                  name="perselected"
                                  checked={selectAllChecked.includes(d?.id)}
                                  onChange={handleChange}
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                ></label>
                              </div>
                            </td>
                          )}
                          {visible.col1 && <td>{++index}.</td>}
                          {visible.col2 && <td>{d?.name}</td>}
                          {visible.col3 && (
                            <td>
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  checked={d.status}
                                  onChange={() => {
                                    ChangeStatus(d.id);
                                  }}
                                  id={`flexSwitchCheckDefault${d?.id}`}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                >
                                  {d.status ? "Active" : "Inactive"}
                                </label>
                              </div>
                            </td>
                          )}

                          {visible.col4 && (
                            <td>
                              <div className="d-flex">
                                <Link
                                  to={`/tax-master/flooredit/${d?.id}`}
                                  type="button"
                                  className="btn btn-primary me-1"
                                >
                                  <img src={pen} className="pen" alt="" />
                                </Link>

                                <button
                                  onClick={() => {
                                    showDeleteRecord(d?.id, d?.name);
                                  }}
                                  type="button"
                                  className="btn btn-danger"
                                >
                                  <img src={basket} className="pen" alt="" />
                                </button>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {data?.data?.floor?.length === 0 ||
                data?.data?.floor?.length === undefined ? (
                  <div className="text-center">
                    <h4>No Records Found</h4>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="row mt-4">
              <div className="  col-lg-4 col-md-4 col-12">
                <div className="d-flex">
                  <div className="show me-2">
                    <p className="show mt-2">Show</p>
                  </div>
                  <div className="number me-2">
                    <select
                      className="form-select form-select-sm"
                      aria-label=".form-select-sm example"
                      onChange={(e) => setperPage(e.target.value)}
                    >
                      {option?.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="entries">
                    <p className="show mt-2">entries</p>
                  </div>
                </div>
              </div>
              <div className="  col-lg-8  col-md-8 col-12">
                <div className="d-flex justify-content-end">
                  <div className="sowing me-2">
                    <p className="show mt-2">{`Showing ${Math.min(
                      (currentPage - 1) * perPage + 1
                    )} to ${Math.min(
                      currentPage * perPage,
                      data?.data?.totalEntries
                    )} of ${data?.data?.totalEntries} entries`}</p>
                  </div>
                  <div className="num me-2">
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                  <div className="Search me-2">
                    <button
                      type="button"
                      onClick={getDataFloor}
                      className="btn btn-search"
                    >
                      <img src={search1} className="search" alt="" />
                    </button>
                  </div>

                  <div className="Search-1">
                    <button
                      type="button"
                      onClick={() => {
                        setSearch("");
                        setReset(!reset);
                      }}
                      className="btn btn-reset"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Toaster position="top-right" />

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button
                    className="btn btn-yes me-2"
                    onClick={handleDeleteRecord}
                  >
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
