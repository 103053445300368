import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import "../Tax.css";

import circle from "../../../assets/icons/circle.png";
import rigth from "../../../assets/icons/rigth.png";
import save from "../../../assets/icons/save.png";
import cancel from "../../../assets/icons/cross.png";
import Sidebar from "../../layout/Sidebar";
import { Context } from "../../../../../utils/context";
import Select from "react-select";
import { getDownloadDataPDF } from "../../../../../utils/api";
import { ColorRing } from "react-loader-spinner";

const Add = () => {
  const { postData, getData, IMG_URL } = useContext(Context);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [ward, setWard] = useState([]);
  const [city, setCity] = useState([]);
  const [wardCount, setWardCount] = useState(0);
  const [option, setOption] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const getSelectedWard = async (city_id) => {
    const response = await getData(
      `/master/taskmaster/ward/getperticularward?city=${city_id}`
    );
    const option = response.success
      ? response.data.ward.map((data) => ({
          value: data.id,
          label: data.name,
        }))
      : [];
    setWard(option);
  };

  const getWardCount = async (id) => {
    const count = await postData("/api/get-count-city-ward-property-info", {
      ward_id: id,
    });
    setWardCount(count?.data);
    setOption(await Per_Page_Dropdown(count?.data));
  };

  const Per_Page_Dropdown = async (data) => {
    const option = [];
    let count = 1;
    for (let i = 1; i <= data; i += 500) {
      option.push({
        value: count,
        label: `${i} - ${i + 499}`,
      });
      ++count;
    }
    return option;
  };

  const onSubmit = async (data) => {
    try {
      const response = await postData(
        "/master/taskmaster/agencyname/postagencyname",
        data
      );
      if (response.success) {
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          navigate("/tax-master/agencyname");
        }, 1000);
      } else {
        setShowErrorModal(true);
        setTimeout(() => {
          setShowErrorModal(false);
          navigate("/tax-master/agencyname");
        }, 1000);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleDownload = async (data) => {
    setLoading(true);
    const res = await getDownloadDataPDF(
      `/master/taskmaster/download/report-one/${data.city_id}/${data.ward_id}/${data.page}`,
      `Assessment Notice Reports - ${data.city_id}`,
      IMG_URL
    );
    setLoading(res);
  };

  const getCities = async () => {
    const res = await getData("/api/getcity");
    const option = res.success
      ? res.data.map((data) => ({
          value: data.city_id,
          label: data.name,
        }))
      : [];
    setCity(option);
  };

  useEffect(() => {
    getCities();
  }, []);

  return (
    <div>
      <section className="main-section main-sec-bg">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="container">
                <div className="row mt-5">
                  <div className="col-xxl-12 col-xl-12 col-md-10">
                    <div className="Back d-flex me-4">
                      <Link to="/tax-master/languages">
                        <button type="button" className="btn btn-back">
                          <img src={rigth} className="rigth" alt="Back" /> Back
                        </button>
                      </Link>
                      <div className="heading-holder text-center">
                        <h1 style={{ marginLeft: "230px", fontSize: "30px" }}>
                          Download Report
                        </h1>
                      </div>
                    </div>
                    <div className="border-line1 mt-3"></div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-lg-8 col-md-10 mt-5 mx-auto Add-content">
                          {isLoading ? (
                            <ColorRing
                              className="blocks-wrapper"
                              visible={true}
                              height="80"
                              width="80"
                              ariaLabel="blocks-loading"
                              wrapperStyle={{}}
                              wrapperClass="blocks-wrapper"
                              colors={[
                                "#e15b64",
                                "#f47e60",
                                "#f8b26a",
                                "#abbd81",
                                "#849b87",
                              ]}
                            />
                          ) : (
                            <>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="main-form-section mt-3">
                                    <div className="row justify-content-center">
                                      <label
                                        htmlFor="city_id"
                                        className="col-sm-3 col-form-label"
                                      >
                                        City
                                      </label>
                                      <div className="col-sm-6">
                                        <Select
                                          {...register("city_id", {
                                            required: "Please Select City",
                                          })}
                                          onChange={(e) => {
                                            setValue("city_id", e.value);
                                            setValue("ward_id", "");
                                            setWard([]);
                                            getSelectedWard(e.value);
                                          }}
                                          options={city}
                                        />
                                        {errors.city_id && (
                                          <span style={{ color: "red" }}>
                                            {errors.city_id.message}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="main-form-section mt-3">
                                    <div className="row justify-content-center">
                                      <label
                                        htmlFor="ward_id"
                                        className="col-sm-3 col-form-label"
                                      >
                                        Ward
                                      </label>
                                      <div className="col-sm-6">
                                        <Select
                                          {...register("ward_id", {
                                            required: "Please Select Ward",
                                          })}
                                          onChange={(e) => {
                                            setValue("ward_id", e.value);
                                            getWardCount(e.value);
                                          }}
                                          options={ward}
                                        />
                                        {errors.ward_id && (
                                          <span style={{ color: "red" }}>
                                            {errors.ward_id.message}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="main-form-section mt-3">
                                    <div className="row justify-content-center">
                                      <label
                                        htmlFor="page"
                                        className="col-sm-3 col-form-label"
                                      >
                                        Page
                                      </label>
                                      <div className="col-sm-6">
                                        <Select
                                          {...register("page", {
                                            required: "Please Select Page",
                                          })}
                                          onChange={(e) => {
                                            setValue("page", e.value);
                                          }}
                                          options={option}
                                        />
                                        {errors.page && (
                                          <span style={{ color: "red" }}>
                                            {errors.page.message}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-5 pb-3">
                                <div className="d-flex justify-content-center">
                                  <Link to="/tax-master/languages">
                                    <button
                                      type="button"
                                      className="btn btn-cancel me-2"
                                    >
                                      <img
                                        src={cancel}
                                        className="cancel-img"
                                        alt="Cancel"
                                      />{" "}
                                      Cancel
                                    </button>
                                  </Link>
                                  <button
                                    type="button"
                                    onClick={handleSubmit(handleDownload)}
                                    className="btn btn-save"
                                  >
                                    <img
                                      src={save}
                                      className="save-img me-2"
                                      alt="Download"
                                    />{" "}
                                    Download
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal for Success */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="Success" />
                </div>
                <h1 className="add-success text-center">
                  Agency Name Added Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Error */}
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="Error" />
                </div>
                <h1 className="add-success text-center">
                  Agency Name Already Exists
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
