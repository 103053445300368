import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import "../Tax.css";
import circle from "../../../assets/icons/circle.png";
import rigth from "../../../assets/icons/rigth.png";
import save from "../../../assets/icons/save.png";
import cancel from "../../../assets/icons/cross.png";
import Select from "react-select";
import Sidebar from "../../layout/Sidebar";
import { Context } from "../../../../../utils/context";
import { ColorRing } from "react-loader-spinner";

const Add = () => {
  const { postData, getData, editData } = useContext(Context);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm();
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [city, setCity] = useState([]);
  const [ward, setWardId] = useState([]);
  const [zone, setZone] = useState([]);
  const [usagetype, setUsagetype] = useState([]);

  useEffect(() => {
    handleClicks("city_id");
    handleClicks("usagetype_id");
  }, []);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const response = await postData("/master/taskmaster/alv/postalv", data);

      if (response.success) {
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          setLoading(false);
          navigate("/tax-master/alv");
        }, 1000);
      } else {
        setShowErrorModal(true);
        setTimeout(() => {
          setShowErrorModal(false);
          setLoading(false);
          navigate("/tax-master/alv");
        }, 1000);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleClicks = async (name) => {
    switch (name) {
      case "city_id": {
        const res = await getData("/api/getcity");
        const option = res.success
          ? res.data.map((data) => ({
              value: data.city_id,
              name: `city_id`,
              label: data.name,
            }))
          : [];
        setCity(option);
        break;
      }

      case "ward_id": {
        const res = await getData(
          `/master/taskmaster/ward/getperticularward?city=${
            getValues("city_id")?.value
          }`
        );
        const option = res.success
          ? res.data.ward.map((data) => ({
              value: data.id,
              name: "ward_id",
              label: data.name,
            }))
          : [];
        setWardId(option);
        break;
      }

      case "zone_id": {
        const res = await editData("/api/getzone", {
          id: getValues("ward_id").value,
        });
        const option = res.success
          ? res.data.data.map((data) => ({
              value: data.zone_id,
              name: "zone_id",
              label: data.name,
            }))
          : [];
        setZone(option);
        break;
      }

      case "usagetype_id": {
        const res = await getData("/master/taskmaster/usagetype/allusagetype");
        const option = res.success
          ? res.data.usagetype.map((data) => ({
              value: data.id,
              name: "usagetype_id",
              label: data.name,
            }))
          : [];
        setUsagetype(option);
        break;
      }

      default:
        break;
    }
  };

  return (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container-fluid">
            <div className="row">
              <Sidebar />

              <div className="col-md-9">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="Back  me-4">
                        <Link to="/tax-master/alv">
                          <button type="button" className="btn btn-back">
                            <img src={rigth} className="rigth" alt="" /> Back{" "}
                          </button>
                        </Link>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                          <div className="col-lg-8 col-md-10 mt-5 mx-auto Add-content">
                            {isLoading ? (
                              <ColorRing
                                className="blocks-wrapper"
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                                colors={[
                                  "#e15b64",
                                  "#f47e60",
                                  "#f8b26a",
                                  "#abbd81",
                                  "#849b87",
                                ]}
                              />
                            ) : (
                              <>
                                <div className="row ">
                                  <div className="col-md-12">
                                    <div className="main-form-section mt-3 ">
                                      <div className="row justify-content-center mt-2">
                                        <label
                                          htmlFor="city_id"
                                          className="col-sm-3 col-form-label"
                                        >
                                          City{" "}
                                        </label>
                                        <div className="col-sm-6">
                                          <Select
                                            {...register("city_id", {
                                              required: "City is required",
                                            })}
                                            onFocus={() =>
                                              handleClicks("city_id")
                                            }
                                            onChange={(event) =>
                                              setValue("city_id", event)
                                            }
                                            options={city}
                                          />
                                          {errors.city_id && (
                                            <span
                                              style={{
                                                color: "red",
                                                marginLeft: "5px",
                                              }}
                                            >
                                              {errors.city_id.message}
                                            </span>
                                          )}
                                        </div>
                                      </div>

                                      <div className="row justify-content-center mt-2">
                                        <label
                                          htmlFor="usagetype_id"
                                          className="col-sm-3 col-form-label"
                                        >
                                          Usage Type{" "}
                                        </label>
                                        <div className="col-sm-6">
                                          <Select
                                            {...register("usagetype_id", {
                                              required:
                                                "Usage type is required",
                                            })}
                                            onFocus={() =>
                                              handleClicks("usagetype_id")
                                            }
                                            onChange={(event) =>
                                              setValue("usagetype_id", event)
                                            }
                                            options={usagetype}
                                          />
                                          {errors.usagetype_id && (
                                            <span
                                              style={{
                                                color: "red",
                                                marginLeft: "5px",
                                              }}
                                            >
                                              {errors.usagetype_id.message}
                                            </span>
                                          )}
                                        </div>
                                      </div>

                                      <div className="row justify-content-center mt-2">
                                        <label
                                          htmlFor="ward_id"
                                          className="col-sm-3 col-form-label"
                                        >
                                          Ward Select{" "}
                                        </label>
                                        <div className="col-sm-6">
                                          <Select
                                            {...register("ward_id", {
                                              required: "Ward is required",
                                            })}
                                            onFocus={() =>
                                              handleClicks("ward_id")
                                            }
                                            onChange={(event) =>
                                              setValue("ward_id", event)
                                            }
                                            options={ward}
                                          />
                                          {errors.ward_id && (
                                            <span
                                              style={{
                                                color: "red",
                                                marginLeft: "5px",
                                              }}
                                            >
                                              {errors.ward_id.message}
                                            </span>
                                          )}
                                        </div>
                                      </div>

                                      <div className="row justify-content-center mt-2">
                                        <label
                                          htmlFor="zone_id"
                                          className="col-sm-3 col-form-label"
                                        >
                                          Zone Select{" "}
                                        </label>
                                        <div className="col-sm-6">
                                          <Select
                                            {...register("zone_id", {
                                              required: "Zone is required",
                                            })}
                                            onFocus={() =>
                                              handleClicks("zone_id")
                                            }
                                            onChange={(event) =>
                                              setValue("zone_id", event)
                                            }
                                            options={zone}
                                          />
                                          {errors.zone_id && (
                                            <span
                                              style={{
                                                color: "red",
                                                marginLeft: "5px",
                                              }}
                                            >
                                              {errors.zone_id.message}
                                            </span>
                                          )}
                                        </div>
                                      </div>

                                      <div className="row justify-content-center mt-2">
                                        <label
                                          htmlFor="name"
                                          className="col-sm-3 col-form-label"
                                        >
                                          ALV Percentage{" "}
                                        </label>
                                        <div className="col-sm-6">
                                          <input
                                            type="number"
                                            {...register("name", {
                                              required:
                                                "ALV Percentage is required",
                                            })}
                                            placeholder="Enter ALV Percentage name"
                                            className="form-control"
                                          />
                                          {errors.name && (
                                            <span
                                              style={{
                                                color: "red",
                                                marginLeft: "5px",
                                              }}
                                            >
                                              {errors.name.message}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row  mt-5 pb-3">
                                  <div className="d-flex justify-content-center">
                                    <Link to="/tax-master/alv">
                                      <button
                                        type="button"
                                        className="btn btn-cancel me-2"
                                      >
                                        {" "}
                                        <img
                                          src={cancel}
                                          className="cancel-img"
                                          alt=""
                                        />{" "}
                                        Cancel
                                      </button>
                                    </Link>
                                    <button
                                      type="submit"
                                      className="btn btn-save"
                                    >
                                      {" "}
                                      <img
                                        src={save}
                                        className="save-img me-2"
                                        alt=""
                                      />
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  ALV Updated Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  ALV Updated Already Exits
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;
