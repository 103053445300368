// import React, { useContext } from "react";
// import { Context } from "./utils/context.js";
// import { useNavigate } from "react-router-dom";
// import Login from "./Components/DataEntry/Login/Login.js";
// import { Fixd_Usertype } from "./utils/common.js";

// const Middleware = ({ component: Component }) => {
//   // Destructure `component` prop
//   const { signin, usertype } = useContext(Context);
//   const navigate = useNavigate();
//   if (signin) {
//     if (
//       usertype ===
//       (Fixd_Usertype.SuperAdmin ||
//         Fixd_Usertype.DataEntry ||
//         Fixd_Usertype.DataEntryHead)
//     ) {
//       return <Component />;
//     } else {
//       return <Component />;
//     }
//   } else {
//     return <Login />;
//   }
// };

// export default Middleware;

// ---------------------------------------------------------------

import React, { useContext } from "react";
import { Context } from "./utils/context.js";
// import { useNavigate } from "react-router-dom";
import Login from "./Components/DataEntry/Login/Login.js";
import { Fixd_Usertype } from "./utils/common.js";

const Middleware = ({ component: Component }) => {
  const { signin, usertype } = useContext(Context);
  // const navigate = useNavigate();

  if (signin) {
    const allowedUserTypes = [
      Fixd_Usertype.SuperAdmin,
      Fixd_Usertype.DataEntry,
      Fixd_Usertype.DataEntryHead,
    ];

    if (allowedUserTypes.includes(usertype)) {
      return <Component />;
    }
  }

  return <Login />;
};

export default Middleware;
