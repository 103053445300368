// import { createContext, useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import Cookies from "js-cookie";
// import { useNavigate } from "react-router-dom";
// import toast, { Toaster } from "react-hot-toast";

// import {
//   getData,
//   postData,
//   getEditData,
//   editData,
//   editStatusData,
//   deleteData,
//   getDownloadDataExcel,
//   getDownloadDataPDF,
// } from "./api";

// export const Context = createContext();

// const AppContext = ({ children }) => {
//   const IMG_URL = "http://127.0.0.1:6001";
//   // Live
//   // const IMG_URL = "http://billingsoftware.node.profcymabackend.com:6001";
//   // Testing
//   // const IMG_URL = "http://billingsoftwarenode.profcymabackend.com:6010";

//   const navigate = useNavigate();

//   const [signin, setSignin] = useState(false);
//   const [language, setLanguage] = useState(1);
//   const [usertype, setUsertype] = useState("");
//   const [userdata, setUserData] = useState({});
//   const [token, setToken] = useState("");

//   const minLength = 1; // Set your desired minimum length
//   const maxLength = 30; // Set your desired maximum length

//   useEffect(() => {
//     isTokenValid();
//   }, [signin]);

//   const getuserData = async (id) => {
//     const response = await getData(`/login/getdata`, { id: id });
//     await setUserData(response?.data);
//   };

//   const isTokenValid = async () => {
//     const token = Cookies.get("billing_security");

//     if (token) {
//       // Decode the token to get the expiration time
//       const decodedToken = JSON.parse(atob(token.split(".")[1]));
//       const currentTime = Date.now() / 1000;

//       // Check if the token has expired
//       if (decodedToken.exp < currentTime) {
//         Cookies.remove("billing_security", { path: "/" });
//         setSignin(false);
//         setUsertype("");
//         navigate("/");
//       } else {
//         getuserData(decodedToken.user);
//         setUsertype(decodedToken.role);
//         setSignin(true);
//       }
//     } else {
//       setSignin(false);
//       setUsertype("");
//       navigate("/");
//     }
//   };

//   const ErrorNotify = (name) => {
//     toast.error(`${name} deleted successfully.`, {
//       position: "top-right",
//       autoClose: 100,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "dark",
//       style: { background: "red", color: "white" },
//     });
//   };

//   return (
//     <Context.Provider
//       value={{
//         minLength,
//         maxLength,
//         getData,
//         postData,
//         getEditData,
//         editData,
//         editStatusData,
//         deleteData,
//         signin,
//         setSignin,
//         usertype,
//         setUsertype,
//         userdata,
//         setUserData,
//         ErrorNotify,
//         token,
//         setToken,
//         language,
//         getDownloadDataExcel,
//         getDownloadDataPDF,
//         IMG_URL,
//       }}
//     >
//       {children}
//     </Context.Provider>
//   );
// };

// export default AppContext;

// ------------------------------------------------------------------------------------------

import {
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
import {
  getData,
  postData,
  getEditData,
  editData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
  getDownloadDataPDF,
} from "./api";

export const Context = createContext();

const AppContext = ({ children }) => {
  // const IMG_URL = "http://127.0.0.1:6001";
  // New Live
  const IMG_URL = "https://node.anniprotax.com:6002";
  // Live
  // const IMG_URL = "http://billingsoftware.node.profcymabackend.com:6001";
  // Testing
  // const IMG_URL = "http://billingsoftwarenode.profcymabackend.com:6010";

  const navigate = useNavigate();

  const [signin, setSignin] = useState(false);
  const [language, setLanguage] = useState(1);
  const [usertype, setUsertype] = useState("");
  const [userdata, setUserData] = useState({});
  const [token, setToken] = useState("");

  const minLength = 1; // Set your desired minimum length
  const maxLength = 30; // Set your desired maximum length

  useEffect(() => {
    isTokenValid();
  }, [signin]);

  const getuserData = useCallback(async (id) => {
    try {
      const response = await getData(`/login/getdata`, { id });
      setUserData(response?.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, []);

  const isTokenValid = useCallback(async () => {
    const token = Cookies.get("billing_security");

    if (token) {
      try {
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
          Cookies.remove("billing_security", { path: "/" });
          setSignin(false);
          setUsertype("");
          navigate("/");
        } else {
          await getuserData(decodedToken.user);
          setUsertype(decodedToken.role);
          setSignin(true);
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        Cookies.remove("billing_security", { path: "/" });
        setSignin(false);
        setUsertype("");
        navigate("/");
      }
    } else {
      setSignin(false);
      setUsertype("");
      navigate("/");
    }
  }, [getuserData, navigate]);

  const ErrorNotify = useCallback((name) => {
    toast.error(`${name} deleted successfully.`, {
      position: "top-right",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { background: "red", color: "white" },
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      minLength,
      maxLength,
      getData,
      postData,
      getEditData,
      editData,
      editStatusData,
      deleteData,
      signin,
      setSignin,
      usertype,
      setUsertype,
      userdata,
      setUserData,
      ErrorNotify,
      token,
      setToken,
      language,
      getDownloadDataExcel,
      getDownloadDataPDF,
      IMG_URL,
    }),
    [
      minLength,
      maxLength,
      getData,
      postData,
      getEditData,
      editData,
      editStatusData,
      deleteData,
      signin,
      setSignin,
      usertype,
      setUsertype,
      userdata,
      setUserData,
      ErrorNotify,
      token,
      setToken,
      language,
      getDownloadDataExcel,
      getDownloadDataPDF,
      IMG_URL,
    ]
  );

  return (
    <Context.Provider value={contextValue}>
      {children}
      <Toaster />
    </Context.Provider>
  );
};

export default AppContext;
