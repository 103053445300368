// Step1.js
import React, { useState, useEffect } from "react";
import "../Step/step.css";
import saveimg from "../image/awesome-save.png";
import { Context } from "../../../../utils/context";
import { useContext } from "react";
import Cookies from "js-cookie";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ColorRing } from "react-loader-spinner";
const Step1 = ({ reload, formData, handleChange, setFormData }) => {
  const { postData, getData, editData, minLength, maxLength, IMG_URL } =
    useContext(Context);
  const [isLoading, setLoading] = useState(false);
  const [t, i18n] = useTranslation("global");

  const [formData1, setFormData1] = useState({
    property_no: "",
    sequence_no: "",
    old_property_no: "",
    old_property_number: "",
    ref_property_no: "",
    ward_id: "",
    zone_id: "",
    // pincode_id: "",
    // block_id: "",
    route_id: "",
    auth_status: "",
    property_status: "",
    authorised: false,
    legal: false,
    current_asses_date: "",
    order_date: "",
    first_asses_date: "",
    primary_asses_date: "",
    construction_end_date: "",
    last_asses_date: "",
    revised_asses_date: "",
    updated_asses_date: "",
    property_type: "",
    property_sub_type: "",
    usage_type: "",
    usage_sub_type: "",
    title_id: "",
    last_name: "",
    first_name: "",
    mid_name: "",
    org_name: "",
    contact_number: "",
    pan_number: "",
    email: "",
    aadhar: "",
    plot_no: "",
    house_no: "",
    street_name: "",
    building_name: "",
    address: "",
    location: "",
    country_id: "",
    state_id: "",
    district_id: "",
    taluka_id: "",
    city_id: "",
    floors: "",
    total_floors: "",
    remarks: "",
    building_structure: "",
  });

  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [district, setDistrict] = useState();
  const [taluka, setTaluka] = useState();
  const [city, setCity] = useState();
  const [ward, setWardId] = useState();
  const [zone, setZone] = useState();
  // const [block, setBlock] = useState();
  // const [pincode, setPincode] = useState();
  const [route, setRoute] = useState();

  const [authstatus, setAuthStatus] = useState();
  const [propertystatus, setProStatus] = useState();
  const [propertytype, setProType] = useState();
  const [propertysubtype, setProSubType] = useState();
  const [usagetype, setUsageType] = useState();
  const [title, setTitle] = useState();
  const [usagesubtype, setUsageSubType] = useState();
  const [billingstructure, setbillingType] = useState();

  const getAllData = async () => {
    const createbillid = Cookies.get("createbillid");
    if (createbillid) {
      setLoading(true);
      const response = await editData(
        "/createbill/propertyinfo/getcreatebill1",
        {
          id: createbillid,
        }
      );

      setFormData1(response.data);
      setLoading(false);
    }
  };

  const handleClicks = async (name) => {
    switch (name) {
      case "country_id": {
        const res = await getData("/api/getcountry");
        const option = [];
        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.country_id,
              name: "country_id",
              label: data.name,
            });
          });
        }
        setCountry(option);
        setFormData1({
          ...formData1,
          country_id: "",
          state_id: "",
          district_id: "",
          taluka_id: "",
          city_id: "",
        });
        break;
      }

      case "state_id": {
        const res = await editData("/api/getstate", {
          id: formData1.country_id.value,
        });
        const option = [];
        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.state_id,
              name: "state_id",
              label: data.name,
            });
          });
        }
        await setState(option);
        setFormData1({
          ...formData1,
          state_id: "",
          district_id: "",
          taluka_id: "",
          city_id: "",
        });
        break;
      }

      case "district_id": {
        const res = await editData("/api/getdistrict", {
          id: formData1.state_id.value,
        });
        const option = [];
        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.district_id,
              name: "district_id",
              label: data.name,
            });
          });
        }
        await setDistrict(option);
        setFormData1({
          ...formData1,
          district_id: "",
          taluka_id: "",
          city_id: "",
        });
        break;
      }

      case "taluka_id": {
        const res = await editData("/api/gettaluka", {
          id: formData1.district_id.value,
        });
        const option = [];
        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.taluka_id,
              name: "taluka_id",
              label: data.name,
            });
          });
        }
        await setTaluka(option);
        setFormData1({
          ...formData1,
          taluka_id: "",
          city_id: "",
        });
        break;
      }

      case "city_id": {
        const res = await editData("/api/getcity", {
          id: formData1.taluka_id.value,
        });
        const option = [];
        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.city_id,
              name: "city_id",
              label: data.name,
            });
          });
        }
        await setCity(option);
        setFormData1({
          ...formData1,
          city_id: "",
        });
        break;
      }

      // next

      case "ward_id": {
        const res = await getData("/api/getward");
        const option = [];

        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.ward_id,
              name: "ward_id",
              label: data?.ward?.city?.name + " - " + data?.name,
            });
          });
        }
        await setWardId(option);
        setFormData1({
          ...formData1,
          ward_id: "",
          zone_id: "",
        });
        break;
      }

      case "zone_id": {
        const res = await editData("/api/getzone", {
          id: formData1.ward_id.value,
        });

        const option = [];
        if (res.success) {
          res?.data?.data?.map((data) => {
            option.push({
              value: data.zone_id,
              name: "zone_id",
              label: data.name,
            });
          });
        }
        await setZone(option);
        setFormData1({
          ...formData1,
          zone_id: "",
          country_id: res?.data?.country_id,
          state_id: res?.data?.state_id,
          district_id: res?.data?.district_id,
          taluka_id: res?.data?.taluka_id,
          city_id: res?.data?.city_id,
        });
        break;
      }

      case "route_id": {
        const res = await getData("/api/getroute");
        const option = [];
        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.route_id,
              name: "route_id",
              label: data.name,
            });
          });
        }
        await setRoute(option);
        setFormData1({
          ...formData1,
          // usage_type: "",
          route_id: "",
        });
        break;
      }

      case "auth_status": {
        const res = await getData("/api/getauthstatus");
        const option = [];
        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.authstatus_id,
              name: "auth_status",
              label: data.name,
            });
          });
        }
        await setAuthStatus(option);
        setFormData1({
          ...formData1,
          // usage_type: "",
          auth_status: "",
        });
        break;
      }

      case "property_status": {
        const res = await getData("/api/getprop");
        const option = [];
        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.property_status,
              name: "property_status",
              label: data.name,
            });
          });
        }
        await setProStatus(option);
        setFormData1({
          ...formData1,
          property_status: "",
        });
        break;
      }

      case "property_type": {
        const res = await getData("/api/gettype");
        const option = [];
        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.propertytype_id,
              name: "property_type",
              label: data.name,
            });
          });
        }
        await setProType(option);
        setFormData1({
          ...formData1,
          property_type: "",
          property_sub_type: "",
        });
        break;
      }

      case "property_sub_type": {
        const res = await editData("/api/getsubtype", {
          id: formData1.property_type.value,
        });
        const option = [];

        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.propertysubtype_id,
              name: "property_sub_type",
              label: data.name,
            });
          });
        }
        // const resp = await getData("/api/getsubtype");
        await setProSubType(option);
        setFormData1({
          ...formData1,
          property_sub_type: "",
        });
        break;
      }

      case "usage_type": {
        const res = await getData("/api/getusagetype");
        const option = [];

        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.usagetype_id,
              name: "usage_type",
              label: data.name,
            });
          });
        }
        // const resp = await getData("/api/getsubtype");
        await setUsageType(option);
        setFormData1({
          ...formData1,
          usage_type: "",
          usage_sub_type: "",
        });
        break;
      }
      case "usage_sub_type": {
        const res = await editData("/api/getusagesubtype", {
          id: formData1.usage_type.value,
        });

        const option = [];
        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.usagesubtype_id,
              name: "usage_sub_type",
              label: data.name,
            });
          });
        }
        // const resp = await getData("/api/getsubtype");
        await setUsageSubType(option);
        setFormData1({
          ...formData1,
          // usage_type: "",
          usage_sub_type: "",
        });
        break;
      }

      case "building_structure": {
        const res = await getData("/api/getbilltype");
        const option = [];
        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.billingstructure_id,
              name: "building_structure",
              label: data.name,
            });
          });
        }
        // const resp = await getData("/api/getsubtype");
        await setbillingType(option);
        setFormData1({
          ...formData1,
          // usage_type: "",
          building_structure: "",
        });
        break;
      }

      case "title_id": {
        const res = await getData("/api/gettitle");
        const option = [];
        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.title_id,
              name: "title_id",
              label: data.name,
            });
          });
        }
        await setTitle(option);
        setFormData1({
          ...formData1,
          title_id: "",
        });
        break;
      }

      default:
        break;
    }
  };

  useEffect(() => {
    getAllData();
  }, [reload]);

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const validateForm = () => {
    let errors = {};

    const regexNumber = /^\d/;
    const regexContact = /^\d{10}$/;
    const regexAdhar = /^\d{12}$/;
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData1.sequence_no) {
      errors.sequence_no = "Sequence no is required";
    }

    // else if (!regexAlfaNumric.test(formData1.sequence_no.trim())) {
    //   errors.sequence_no = "Enter a valid data";
    // }

    // if (!formData1.sequence_no) {
    //   errors.sequence_no = "Sequence no is required";
    // } else if (!regexNumber.test(formData1.sequence_no.trim())) {
    //   errors.sequence_no = "Enter a valid data";
    // }

    if (
      formData1.sequence_start?.length > 0 &&
      (!regexNumber.test(formData1.sequence_start.trim()) ||
        formData1.sequence_start?.length != 6)
    ) {
      errors.sequence_start = "Enter a valid data";
    } else {
      delete errors.sequence_start;
    }

    if (!formData1.ref_property_no) {
      errors.ref_property_no = "Refrence property no is required";
    }
    // else if (!regexAlfaNumric.test(formData1.ref_property_no.trim())) {
    //   errors.ref_property_no = "Enter a valid data";
    // }

    // if (!formData1.pincode_id) {
    //   errors.pincode_id = "pincode is required";
    // }

    if (!formData1.ward_id) {
      errors.ward_id = "Ward is required";
    }
    if (!formData1.zone_id) {
      errors.zone_id = "Zone is required";
    }

    if (!formData1.old_property_no) {
      errors.old_property_no = "property type is required";
    }

    if (!formData1.old_property_number) {
      errors.old_property_number = "Old property no is required";
    }

    if (!formData1.current_asses_date.trim()) {
      errors.current_asses_date = "Currect asset date is required";
    }

    if (!formData1.property_type) {
      errors.property_type = "Property type is required";
    }
    if (!formData1.property_sub_type) {
      errors.property_sub_type = "Property Sub type is required";
    }
    if (!formData1.usage_type) {
      errors.usage_type = "Usage type is required";
    }

    if (!formData1.usage_sub_type) {
      errors.usage_sub_type = "Usage Sub type is required";
    }

    if (!formData1.title_id) {
      errors.title_id = "title is required";
    }

    if (!formData1.last_name) {
      errors.last_name = "Last name is required";
    }
    //  else if (!regexAlfa.test(formData1.last_name.trim())) {
    //   errors.last_name = "Enter a valid data";
    // }
    else if (
      formData1.last_name.trim().length < minLength ||
      formData1.last_name.trim().length > maxLength
    ) {
      errors.last_name = `Last name must be between ${minLength} and ${maxLength} characters`;
    }

    if (!formData1.first_name) {
      errors.first_name = "First name is required";
    }

    // else if (!regexAlfa.test(formData1.first_name.trim())) {
    //   errors.first_name = "Enter a valid data";
    // }
    else if (
      formData1.first_name.trim().length < minLength ||
      formData1.first_name.trim().length > maxLength
    ) {
      errors.first_name = `First name must be between ${minLength} and ${maxLength} characters`;
    }

    if (!formData1.address) {
      errors.address = "Address is required";
    }

    // else if (!regexAlfaNumric.test(formData1.address.trim())) {
    //   errors.address = "Enter a valid data";
    // }
    else if (
      formData1.address.trim().length < minLength ||
      formData1.address.trim().length > 30
    ) {
      errors.address = `Address name must be between ${minLength} and 30 characters`;
    }

    if (!formData1.location) {
      errors.location = "Location name is required";
    }

    // else if (!regexAlfaNumric.test(formData1.location.trim())) {
    //   errors.location = "Enter a valid data";
    // }
    else if (
      formData1.location.trim().length < minLength ||
      formData1.location.trim().length > 30
    ) {
      errors.location = `Location name must be between ${minLength} and 30 characters`;
    }

    if (!formData1.country_id) {
      errors.country_id = "Country Name is required";
    }
    if (!formData1.state_id) {
      errors.state_id = "State Name is required";
    }
    if (!formData1.district_id) {
      errors.district_id = "District Name is required";
    }

    if (!formData1.taluka_id) {
      errors.taluka_id = "Taluka Name is required";
    }

    if (!formData1.city_id) {
      errors.city_id = "City is required";
    }

    if (!formData1.remarks) {
      errors.remarks = "Remarks is required";
    }

    // else if (!regexAlfaNumric.test(formData1.remarks.trim())) {
    //   errors.remarks = "Enter a valid data";
    // }
    else if (
      formData1.remarks.trim().length < minLength ||
      formData1.remarks.trim().length > maxLength
    ) {
      errors.remarks = `Remarks must be between ${minLength} and ${maxLength} characters`;
    }

    return errors;
  };

  const handleBlur = async (e) => {
    let errors = {};

    const regexSequnceStartNumber = /^\d/;

    switch (e.target.name) {
      case "sequence_no":
        if (!e.target.value) {
          errors.sequence_no = "Sequence no is required";
        }

        break;

      case "sequence_start":
        if (
          (e.target.value.length > 0 &&
            !regexSequnceStartNumber.test(e.target.value.trim())) ||
          e.target.value.length != 6
        ) {
          errors.sequence_start = "Enter a valid data";
        } else {
          delete errors.sequence_start;
        }
        break;

      case "ref_property_no":
        if (!e.target.value) {
          errors.ref_property_no = "Refrence property no is required";
        }

        break;

      case "last_name":
        if (!e.target.value) {
          errors.last_name = "Last name is required";
        } else if (
          e.target.value.trim().length < minLength ||
          e.target.value.trim().length > maxLength
        ) {
          errors.last_name = `Last name must be between ${minLength} and ${maxLength} characters`;
        }
        break;

      case "first_name":
        if (!e.target.value) {
          errors.first_name = "First name is required";
        } else if (
          e.target.value.trim().length < minLength ||
          e.target.value.trim().length > maxLength
        ) {
          errors.first_name = `First name must be between ${minLength} and ${maxLength} characters`;
        }
        break;

      case "address":
        if (!e.target.value) {
          errors.address = "Address is required";
        } else if (
          e.target.value.trim().length < minLength ||
          e.target.value.trim().length > 30
        ) {
          errors.address = `Address name must be between ${minLength} and 30 characters`;
        }
        break;

      case "location":
        if (!e.target.value) {
          errors.location = "Location name is required";
        } else if (
          e.target.value.trim().length < minLength ||
          e.target.value.trim().length > 30
        ) {
          errors.location = `Location name must be between ${minLength} and 30 characters`;
        }
        break;

      case "remarks":
        if (!e.target.value) {
          errors.remarks = "Remarks is required";
        } else if (
          e.target.value.trim().length < minLength ||
          e.target.value.trim().length > maxLength
        ) {
          errors.remarks = `Remarks must be between ${minLength} and ${maxLength} characters`;
        }
        break;

      default:
        break;
    }

    await setErrors(errors);
  };

  const handleChange1 = (e) => {
    if (e && e.hasOwnProperty("value")) {
      const { name, value } = e;

      const validFields = [
        "country_id",
        "state_id",
        "district_id",
        "city_id",
        "zone_id",
        "taluka_id",
        "ward_id",
        "route_id",
        "auth_status",
        "property_status",
        "property_type",
        "property_sub_type",
        "usage_type",
        "usage_sub_type",
        "building_structure",
        "old_property_no",
        "title_id",
      ];

      if (validFields.includes(name)) {
        setFormData1({ ...formData1, [name]: e });
      }
    } else {
      const target = e?.target;

      if (!target) return;

      const { name, value, type, checked, files } = target;

      if (name === "authorised" || name === "legal") {
        setFormData1({ ...formData1, [name]: checked });
      } else if (
        (name === "property_image" || name === "property_layout") &&
        files?.[0]
      ) {
        setFormData1({
          ...formData1,
          [name]: {
            image: files[0],
            imageUrl: URL.createObjectURL(files[0]),
          },
        });
      } else {
        setFormData1({ ...formData1, [name]: value });
      }
    }
  };

  const Reload = async () => {
    Cookies.remove("createbillid");
    await setFormData1({
      ...formData1,
      property_no: "",
      sequence_no: "",
      old_property_no: "",
      old_property_number: "",
      ref_property_no: "",
      ward_id: "",
      pincode_id: "",
      zone_id: "",
      block_id: "",
      route_id: "",
      auth_status: "",
      property_status: "",
      authorised: false,
      legal: false,
      current_asses_date: "",
      order_date: "",
      first_asses_date: "",
      primary_asses_date: "",
      construction_end_date: "",
      last_asses_date: "",
      revised_asses_date: "",
      updated_asses_date: "",
      property_type: "",
      property_sub_type: "",
      usage_type: "",
      usage_sub_type: "",

      title_id: "",
      last_name: "",
      first_name: "",
      mid_name: "",
      org_name: "",
      contact_number: "",
      pan_number: "",
      email: "",
      aadhar: "",
      plot_no: "",
      house_no: "",
      street_name: "",
      building_name: "",
      address: "",
      location: "",
      city_id: "",
      country_id: "",
      state_id: "",
      district_id: "",
      taluka_id: "",
      floors: "",
      total_floors: "",
      remarks: "",
      building_structure: "",
    });
    await getAllData();
  };

  const handleChange2 = async (e) => {
    await setFormData1({ ...formData1, [e.target.name]: e.target.value });
    const response = await postData("/api/getcheckcreatebill", {
      name: e.target.value,
    });

    if (response.success) {
      await Cookies.set("createbillid", response.data?.id, { expires: 2 });
      await getAllData();
    } else {
      await Cookies.remove("createbillid");
      await setFormData1({
        ...formData1,
        [e.target.name]: e.target.value,

        sequence_no: "",
        old_property_no: "",
        old_property_number: "",
        ref_property_no: "",
        ward_id: "",
        pincode_id: "",
        zone_id: "",
        block_id: "",
        route_id: "",
        auth_status: "",
        property_status: "",
        authorised: false,
        legal: false,
        current_asses_date: "",
        order_date: "",
        first_asses_date: "",
        primary_asses_date: "",
        construction_end_date: "",
        last_asses_date: "",
        revised_asses_date: "",
        updated_asses_date: "",
        property_type: "",
        property_sub_type: "",
        usage_type: "",
        usage_sub_type: "",
        title_id: "",
        last_name: "",
        first_name: "",
        mid_name: "",
        org_name: "",
        contact_number: "",
        pan_number: "",
        email: "",
        aadhar: "",
        plot_no: "",
        house_no: "",
        street_name: "",
        building_name: "",
        address: "",
        location: "",
        city_id: "",
        country_id: "",
        state_id: "",
        district_id: "",
        taluka_id: "",
        floors: "",
        total_floors: "",
        remarks: "",
        building_structure: "",
      });
      await getAllData();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        setLoading(true);
        const response = await postData(
          // "/createbill/addconstructiondetails",
          "/createbill/propertyinfo/addpropertyinfo",
          formData1
        );

        if (response.success) {
          const data = new FormData();
          data.append("id", response.data?.id);

          if (formData1?.property_image?.image) {
            data.append(`property_image`, formData1.property_image.image);
          }

          if (formData1?.property_layout?.image) {
            data.append(`property_layout`, formData1.property_layout.image);
          }

          const upload = await postData(
            "/createbill/propertyinfo/upload-propertyinfo",
            data
          );

          Cookies.set("createbillid", response.data?.id, { expires: 2 });
          setFormData(response);
          setLoading(false);
          handleChange("next");
        } else {
          setLoading(false);
          setErrors(response.message);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    fontSize: "11px",
  };

  return (
    <>
      <div className="container">
        <div className="First-tab">
          <div className="tabepane-start">
            <div className="scroll-tab">
              <div className="button-holder me-2 text-end">
                <button className="btn btn-save me-2" onClick={Reload}>
                  {/* <img src={saveimg} className="save-img me-2" /> */}
                  Reset
                </button>
                <button
                  className="btn btn-save"
                  onClick={(e) => !isLoading && handleSubmit(e)}
                  disabled={isLoading}
                  aria-label="Save"
                >
                  <img
                    src={saveimg}
                    className="save-img me-2"
                    alt="Save Icon"
                  />
                  Save
                </button>
              </div>
            </div>

            {isLoading ? (
              <ColorRing
                className="blocks-wrapper"
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              />
            ) : (
              <form className="" onSubmit={handleSubmit} role="form">
                <div className="Property-Form">
                  <div className="container">
                    <div className="row mt-4">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("firstform.propertyinfo.propertyno")}{" "}
                          {/* <span className="reuiredstart">*</span> */}
                        </label>
                        <input
                          type="text"
                          id="inputPassword5"
                          name="property_no"
                          onChange={handleChange2}
                          onInput={handleBlur}
                          className="custom-select form-control"
                          value={formData1?.property_no}
                          aria-describedby="passwordHelpBlock"
                        />
                        {errors.property_no && (
                          <span style={errorStyle}>{errors.property_no}</span>
                        )}
                      </div>

                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("firstform.propertyinfo.sequence_start")}{" "}
                          {/* <span className="reuiredstart">*</span> */}
                        </label>
                        <input
                          type="text"
                          id="inputPassword5"
                          name="sequence_start"
                          onChange={handleChange1}
                          onInput={handleBlur}
                          className="custom-select form-control"
                          value={formData1?.sequence_start}
                          aria-describedby="passwordHelpBlock"
                        />
                        {errors.sequence_start && (
                          <span style={errorStyle}>
                            {errors.sequence_start}
                          </span>
                        )}
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("firstform.propertyinfo.sequenceno")}{" "}
                          <span className="reuiredstart">*</span>
                        </label>
                        <input
                          type="text"
                          name="sequence_no"
                          id="inputPassword5"
                          // disabled
                          className="custom-select form-control"
                          value={formData1?.sequence_no}
                          onChange={handleChange1}
                          onInput={handleBlur}
                          aria-describedby="passwordHelpBlock"
                        />
                        {errors.sequence_no && (
                          <span style={errorStyle}>{errors.sequence_no}</span>
                        )}
                      </div>

                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("firstform.propertyinfo.old_property_number")}{" "}
                          <span className="reuiredstart">*</span>
                        </label>
                        <input
                          type="text"
                          name="old_property_number"
                          id="inputPassword5"
                          // disabled
                          className="custom-select form-control"
                          value={formData1?.old_property_number}
                          onChange={handleChange1}
                          onInput={handleBlur}
                          aria-describedby="passwordHelpBlock"
                        />
                        {errors.old_property_number && (
                          <span style={errorStyle}>
                            {errors.old_property_number}
                          </span>
                        )}
                      </div>

                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("firstform.propertyinfo.oldpropno")}
                          <span className="reuiredstart">*</span>
                        </label>

                        <Select
                          name="old_property_no"
                          className="custom-select"
                          value={formData1?.old_property_no}
                          onChange={(event) => {
                            handleChange1(event);
                          }}
                          options={[
                            {
                              value: "New",
                              label: "New",
                              name: "old_property_no",
                            },
                            {
                              value: "Old",
                              label: "Old",
                              name: "old_property_no",
                            },
                          ]}
                        />
                        {errors.old_property_no && (
                          <span style={errorStyle}>
                            {errors.old_property_no}
                          </span>
                        )}
                      </div>

                      <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("firstform.propertyinfo.refprono")}
                          <span className="reuiredstart">*</span>
                        </label>
                        <input
                          type="text"
                          name="ref_property_no"
                          id="inputPassword5"
                          className="custom-select form-control"
                          value={formData1?.ref_property_no}
                          onChange={handleChange1}
                          onInput={handleBlur}
                          aria-describedby="passwordHelpBlock"
                          placeholder=" "
                        />
                        {errors.ref_property_no && (
                          <span style={errorStyle}>
                            {errors.ref_property_no}
                          </span>
                        )}
                      </div>

                      <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("firstform.propertyinfo.ward")}{" "}
                          <span className="reuiredstart">*</span>
                        </label>
                        <Select
                          name="ward_id"
                          className="custom-select"
                          value={formData1?.ward_id}
                          onFocus={() => {
                            handleClicks("ward_id");
                          }}
                          onChange={(event) => {
                            handleChange1(event);
                          }}
                          options={ward}
                        />

                        {errors.ward_id && (
                          <span style={errorStyle}>{errors.ward_id}</span>
                        )}
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("firstform.propertyinfo.zone")}{" "}
                          <span className="reuiredstart">*</span>
                        </label>
                        <Select
                          name="zone_id"
                          className="custom-select"
                          value={formData1?.zone_id}
                          onFocus={() => {
                            handleClicks("zone_id");
                          }}
                          onChange={(event) => {
                            handleChange1(event);
                          }}
                          options={zone}
                        />

                        {errors.zone_id && (
                          <span style={errorStyle}>{errors.zone_id}</span>
                        )}
                      </div>

                      <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("firstform.propertyinfo.route")}{" "}
                        </label>
                        <Select
                          name="route_id"
                          className="custom-select"
                          value={formData1?.route_id}
                          onFocus={(event) => {
                            handleClicks("route_id");
                          }}
                          onChange={(event) => {
                            handleChange1(event);
                          }}
                          options={route}
                        />

                        {errors.route_id && (
                          <span style={errorStyle}>{errors.route_id}</span>
                        )}
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("firstform.propertyinfo.authstatus")}
                        </label>
                        <Select
                          name="auth_status"
                          className="custom-select"
                          value={formData1?.auth_status}
                          onFocus={() => {
                            handleClicks("auth_status");
                          }}
                          onChange={(event) => {
                            handleChange1(event);
                          }}
                          options={authstatus}
                        />

                        {errors.auth_status && (
                          <span style={errorStyle}>{errors.auth_status}</span>
                        )}
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("firstform.propertyinfo.propertystatus")}
                        </label>
                        <Select
                          name="property_status"
                          className="custom-select"
                          value={formData1?.property_status}
                          onFocus={() => {
                            handleClicks("property_status");
                          }}
                          onChange={(event) => {
                            handleChange1(event);
                          }}
                          options={propertystatus}
                        />

                        {errors.property_status && (
                          <span style={errorStyle}>
                            {errors.property_status}
                          </span>
                        )}
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                        <label className="form-label">
                          {t("firstform.propertyinfo.authorised")} ?
                        </label>
                        <div className="col-2">
                          <input
                            type="checkbox"
                            aria-describedby="passwordHelpBlock"
                            name="authorised"
                            onChange={handleChange1}
                            checked={formData1?.authorised}
                          />
                        </div>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("firstform.propertyinfo.legal")}
                        </label>
                        <div className="col-2">
                          <input
                            type="checkbox"
                            name="legal"
                            onChange={handleChange1}
                            checked={formData1?.legal}
                            aria-describedby="passwordHelpBlock"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="line-bottom-class mt-3 "></div>
                    {/* Property owner details */}

                    {/* <div className="row"></div> */}
                    <div>
                      <div className="mt-3">
                        <h2>Property Owner Details</h2>
                      </div>
                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                          <div className="row">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                              <Select
                                name="title_id"
                                className="custom-select"
                                value={formData1?.title_id}
                                onFocus={() => {
                                  handleClicks("title_id");
                                }}
                                onChange={(event) => {
                                  handleChange1(event);
                                }}
                                options={title}
                              />

                              {errors.title_id && (
                                <span style={errorStyle}>
                                  {errors.title_id}
                                </span>
                              )}
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                              <input
                                type="text"
                                name="last_name"
                                id="inputPassword5"
                                className="form-control"
                                onChange={handleChange1}
                                onInput={handleBlur}
                                value={formData1?.last_name}
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors.last_name && (
                                <span style={errorStyle}>
                                  {errors.last_name}
                                </span>
                              )}
                              <div
                                id="passwordHelpBlock"
                                className="form-text text-center"
                              >
                                {t("firstform.propertyownerdetails.lastname")}{" "}
                                <span className="reuiredstart">*</span>
                              </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                              <input
                                type="text"
                                id="inputPassword5"
                                onChange={handleChange1}
                                name="first_name"
                                value={formData1?.first_name}
                                className="form-control"
                                onInput={handleBlur}
                                aria-describedby="passwordHelpBlock"
                                placeholder=" "
                              />

                              {errors.first_name && (
                                <span style={errorStyle}>
                                  {errors.first_name}
                                </span>
                              )}
                              <div
                                id="passwordHelpBlock"
                                className="form-text text-center"
                              >
                                {t("firstform.propertyownerdetails.firstname")}{" "}
                                <span className="reuiredstart">*</span>
                              </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                              <input
                                type="text"
                                id="inputPassword5"
                                onChange={handleChange1}
                                name="mid_name"
                                value={formData1?.mid_name}
                                onInput={handleBlur}
                                className="form-control"
                                aria-describedby="passwordHelpBlock"
                                placeholder=" "
                              />

                              {errors.mid_name && (
                                <span style={errorStyle}>
                                  {errors.mid_name}
                                </span>
                              )}
                              <div
                                id="passwordHelpBlock"
                                className="form-text text-center"
                              >
                                {t("firstform.propertyownerdetails.middlename")}
                              </div>
                            </div>

                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("firstform.propertyownerdetails.orgname")}
                              </label>
                              <input
                                type="text"
                                id="inputPassword5"
                                onChange={handleChange1}
                                name="org_name"
                                onInput={handleBlur}
                                value={formData1?.org_name}
                                className="form-control"
                                aria-describedby="passwordHelpBlock"
                                placeholder=" "
                              />

                              {errors.org_name && (
                                <span style={errorStyle}>
                                  {errors.org_name}
                                </span>
                              )}
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("firstform.propertyownerdetails.contactno")}
                              </label>
                              <input
                                type="text"
                                id="inputPassword5"
                                name="contact_number"
                                onInput={handleBlur}
                                value={formData1?.contact_number}
                                onChange={handleChange1}
                                maxLength={10}
                                className="form-control"
                                aria-describedby="passwordHelpBlock"
                                placeholder=" "
                              />

                              {errors.contact_number && (
                                <span style={errorStyle}>
                                  {errors.contact_number}
                                </span>
                              )}
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("firstform.propertyownerdetails.panno")}
                              </label>
                              <input
                                type="text"
                                id="inputPassword5"
                                name="pan_number"
                                onChange={handleChange1}
                                onInput={handleBlur}
                                value={formData1?.pan_number}
                                className="form-control"
                                aria-describedby="passwordHelpBlock"
                                placeholder=" "
                              />

                              {errors.pan_number && (
                                <span style={errorStyle}>
                                  {errors.pan_number}
                                </span>
                              )}
                            </div>
                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("firstform.propertyownerdetails.emailid")}
                              </label>
                              <input
                                type="email"
                                id="inputPassword5"
                                name="email"
                                value={formData1?.email}
                                onInput={handleBlur}
                                onChange={handleChange1}
                                className="form-control"
                                aria-describedby="passwordHelpBlock"
                                placeholder=" "
                              />

                              {errors.email && (
                                <span style={errorStyle}>{errors.email}</span>
                              )}
                            </div>
                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t(
                                  "firstform.propertyownerdetails.aadharcardno"
                                )}
                              </label>
                              <input
                                type="text"
                                id="inputPassword5"
                                onChange={handleChange1}
                                name="aadhar"
                                value={formData1?.aadhar}
                                className="form-control"
                                onInput={handleBlur}
                                maxLength={12}
                                aria-describedby="passwordHelpBlock"
                                placeholder=" "
                              />

                              {errors.aadhar && (
                                <span style={errorStyle}>{errors.aadhar}</span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 ">
                          {/* property Details */}
                          <div>
                            <div className=" ">
                              <h2 className="mb-4">Property Details</h2>
                            </div>
                            <div className="property-details">
                              <div className="row mt-3 ">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                  <div className="text-holder-form">
                                    <p>
                                      {t(
                                        "firstform.propertydetails.propertytype"
                                      )}{" "}
                                      <span className="reuiredstart">*</span>
                                    </p>{" "}
                                  </div>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                  <Select
                                    name="property_type"
                                    className="custom-select mb-2"
                                    value={formData1?.property_type}
                                    onFocus={() => {
                                      handleClicks("property_type");
                                    }}
                                    onChange={(event) => {
                                      handleChange1(event);
                                    }}
                                    options={propertytype}
                                  />

                                  {errors.property_type && (
                                    <span style={errorStyle}>
                                      {errors.property_type}
                                    </span>
                                  )}
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                  <div className="text-holder-form">
                                    <p>
                                      {t(
                                        "firstform.propertydetails.propertysubtype"
                                      )}{" "}
                                      <span className="reuiredstart">*</span>
                                    </p>
                                  </div>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                  <Select
                                    name="property_sub_type"
                                    className="custom-select mb-2"
                                    value={formData1?.property_sub_type}
                                    onFocus={() => {
                                      handleClicks("property_sub_type");
                                    }}
                                    onChange={(event) => {
                                      handleChange1(event);
                                    }}
                                    options={propertysubtype}
                                  />

                                  {errors.property_sub_type && (
                                    <span style={errorStyle}>
                                      {errors.property_sub_type}
                                    </span>
                                  )}
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                  <div className="text-holder-form">
                                    <p>
                                      {t("firstform.propertydetails.usagetype")}{" "}
                                      <span className="reuiredstart">*</span>
                                    </p>
                                  </div>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                  <Select
                                    name="usage_type"
                                    className="custom-select mb-2"
                                    value={formData1?.usage_type}
                                    onFocus={() => {
                                      handleClicks("usage_type");
                                    }}
                                    onChange={(event) => {
                                      handleChange1(event);
                                    }}
                                    options={usagetype}
                                  />

                                  {errors.usage_type && (
                                    <span style={errorStyle}>
                                      {errors.usage_type}
                                    </span>
                                  )}
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                  <div className="text-holder-form">
                                    <p>
                                      {t("firstform.propertydetails.Subtype")}{" "}
                                      <span className="reuiredstart">*</span>
                                    </p>
                                  </div>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                  <Select
                                    name="usage_sub_type"
                                    className="custom-select mb-2"
                                    value={formData1?.usage_sub_type}
                                    onFocus={() => {
                                      handleClicks("usage_sub_type");
                                    }}
                                    onChange={(event) => {
                                      handleChange1(event);
                                    }}
                                    options={usagesubtype}
                                  />

                                  {errors.usage_sub_type && (
                                    <span style={errorStyle}>
                                      {errors.usage_sub_type}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="line-bottom-class mt-3 "></div>

                    {/* Property Address */}
                    <div>
                      <div>
                        <h2 className="mt-3">Property Address</h2>
                      </div>
                      <div className="row">
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            {t("firstform.propertyaddress.plotno")}
                          </label>
                          <input
                            type="text"
                            id="inputPassword5"
                            onChange={handleChange1}
                            onInput={handleBlur}
                            name="plot_no"
                            value={formData1?.plot_no}
                            className="form-control"
                            aria-describedby="passwordHelpBlock"
                            placeholder=" "
                          />

                          {errors.plot_no && (
                            <span style={errorStyle}>{errors.plot_no}</span>
                          )}
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            {t("firstform.propertyaddress.houseno")}
                          </label>
                          <input
                            type="text"
                            id="inputPassword5"
                            onChange={handleChange1}
                            onInput={handleBlur}
                            name="house_no"
                            value={formData1?.house_no}
                            className="form-control"
                            aria-describedby="passwordHelpBlock"
                            placeholder=" "
                          />

                          {errors.house_no && (
                            <span style={errorStyle}>{errors.house_no}</span>
                          )}
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            {t("firstform.propertyaddress.streetname")}
                          </label>
                          <input
                            type="text"
                            id="inputPassword5"
                            name="street_name"
                            onChange={handleChange1}
                            onInput={handleBlur}
                            value={formData1?.street_name}
                            className="form-control"
                            aria-describedby="passwordHelpBlock"
                            placeholder=" "
                          />

                          {errors.street_name && (
                            <span style={errorStyle}>{errors.street_name}</span>
                          )}
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            {t("firstform.propertyaddress.buildingname")}
                          </label>
                          <input
                            type="text"
                            id="inputPassword5"
                            name="building_name"
                            onChange={handleChange1}
                            onInput={handleBlur}
                            value={formData1?.building_name}
                            className="form-control"
                            aria-describedby="passwordHelpBlock"
                            placeholder=" "
                          />

                          {errors.building_name && (
                            <span style={errorStyle}>
                              {errors.building_name}
                            </span>
                          )}
                        </div>
                        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            {t("firstform.propertyaddress.address")}{" "}
                            <span className="reuiredstart">*</span>
                          </label>
                          <input
                            type="text"
                            id="inputPassword5"
                            name="address"
                            value={formData1?.address}
                            onChange={handleChange1}
                            onInput={handleBlur}
                            className="form-control"
                            aria-describedby="passwordHelpBlock"
                            placeholder=" "
                          />

                          {errors.address && (
                            <span style={errorStyle}>{errors.address}</span>
                          )}
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            {t("firstform.propertyaddress.location")}{" "}
                            <span className="reuiredstart">*</span>
                          </label>
                          <input
                            type="text"
                            id="inputPassword5"
                            className="form-control"
                            name="location"
                            value={formData1?.location}
                            onInput={handleBlur}
                            onChange={handleChange1}
                            aria-describedby="passwordHelpBlock"
                          />
                          {errors.location && (
                            <span style={errorStyle}>{errors.location}</span>
                          )}
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            {t("firstform.propertyaddress.country")}{" "}
                            <span className="reuiredstart">*</span>
                          </label>
                          <Select
                            name="country_id"
                            isDisabled
                            className="custom-select mb-2"
                            value={formData1?.country_id}
                            onFocus={() => {
                              handleClicks("country_id");
                            }}
                            onChange={(event) => {
                              handleChange1(event);
                            }}
                            options={country}
                          />

                          {errors.country_id && (
                            <span style={errorStyle}>{errors.country_id}</span>
                          )}
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            {t("firstform.propertyaddress.state")}{" "}
                            <span className="reuiredstart">*</span>
                          </label>
                          <Select
                            name="state_id"
                            className="custom-select mb-2"
                            isDisabled
                            value={formData1?.state_id}
                            onFocus={() => {
                              handleClicks("state_id");
                            }}
                            onChange={(event) => {
                              handleChange1(event);
                            }}
                            options={state}
                          />

                          {errors.state_id && (
                            <span style={errorStyle}>{errors.state_id}</span>
                          )}
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            {t("firstform.propertyaddress.districts")}{" "}
                            <span className="reuiredstart">*</span>
                          </label>
                          <Select
                            name="district_id"
                            className="custom-select mb-2"
                            isDisabled
                            value={formData1?.district_id}
                            onFocus={() => {
                              handleClicks("district_id");
                            }}
                            onChange={(event) => {
                              handleChange1(event);
                            }}
                            options={district}
                          />

                          {errors.district_id && (
                            <span style={errorStyle}>{errors.district_id}</span>
                          )}
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            {t("firstform.propertyaddress.taluka")}{" "}
                            <span className="reuiredstart">*</span>
                          </label>
                          <Select
                            name="taluka_id"
                            className="custom-select mb-2"
                            isDisabled
                            value={formData1?.taluka_id}
                            onFocus={() => {
                              handleClicks("taluka_id");
                            }}
                            onChange={(event) => {
                              handleChange1(event);
                            }}
                            options={taluka}
                          />

                          {errors.taluka_id && (
                            <span style={errorStyle}>{errors.taluka_id}</span>
                          )}
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            {t("firstform.propertyaddress.city")}{" "}
                            <span className="reuiredstart">*</span>
                          </label>
                          <Select
                            name="city_id"
                            className="custom-select mb-2"
                            isDisabled
                            value={formData1?.city_id}
                            onFocus={() => {
                              handleClicks("city_id");
                            }}
                            onChange={(event) => {
                              handleChange1(event);
                            }}
                            options={city}
                          />

                          {errors.city_id && (
                            <span style={errorStyle}>{errors.city_id}</span>
                          )}
                        </div>

                        <div className="line-bottom-class mt-3 "></div>

                        {/* Property Images*/}
                        <div>
                          <div>
                            <h2 className="mt-3">Property Image & Layout</h2>
                          </div>
                          <div className="row">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("firstform.propertydetails.property_image")}
                                {/* <span className="reuiredstart">*</span> */}
                              </label>
                              <input
                                type="file"
                                name="property_image"
                                id="inputPassword5"
                                className="form-control"
                                accept="image/*"
                                // onClick={handleClicks}
                                onChange={handleChange1}
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors.property_image && (
                                <span style={errorStyle}>
                                  {errors.property_image}
                                </span>
                              )}
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                              {(formData1?.property_image?.imageUrl ||
                                formData1?.property_image?.image) && (
                                <div className="image-preview-container">
                                  <img
                                    src={
                                      formData1?.property_image?.imageUrl ||
                                      IMG_URL + formData1?.property_image?.image
                                    }
                                    alt="Preview"
                                    className="image-preview-container"
                                    style={{
                                      width: "120px",
                                      height: "120px",
                                    }}
                                  />
                                </div>
                              )}
                            </div>

                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("firstform.propertydetails.property_layout")}
                                {/* <span className="reuiredstart">*</span> */}
                              </label>
                              <input
                                type="file"
                                name="property_layout"
                                id="inputPassword5"
                                className="form-control"
                                accept="image/*"
                                // onClick={handleClicks}
                                onChange={handleChange1}
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors.property_layout && (
                                <span style={errorStyle}>
                                  {errors.property_layout}
                                </span>
                              )}
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                              {(formData1?.property_layout?.imageUrl ||
                                formData1?.property_layout?.image) && (
                                <div className="image-preview-container">
                                  <img
                                    src={
                                      formData1?.property_layout?.imageUrl ||
                                      IMG_URL +
                                        formData1?.property_layout?.image
                                    }
                                    alt="Preview"
                                    className="image-preview-container"
                                    style={{
                                      width: "120px",
                                      height: "120px",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Assessment Date Details */}
                        <div>
                          <div>
                            <h2 className="mt-3">Assessment Date Details</h2>
                          </div>
                          <div className="row">
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t(
                                  "firstform.assessmentdatedetails.currentassdate"
                                )}{" "}
                                <span className="reuiredstart">*</span>
                              </label>
                              <input
                                type="date"
                                max={new Date().toISOString().split("T")[0]}
                                name="current_asses_date"
                                id="inputPassword5"
                                className="form-control"
                                value={formData1?.current_asses_date}
                                // onClick={handleClicks}
                                onChange={handleChange1}
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors.current_asses_date && (
                                <span style={errorStyle}>
                                  {errors.current_asses_date}
                                </span>
                              )}
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t("firstform.assessmentdatedetails.orderdate")}{" "}
                              </label>
                              <input
                                type="date"
                                name="order_date"
                                max={new Date().toISOString().split("T")[0]}
                                id="inputPassword5"
                                value={formData1?.order_date}
                                className="form-control"
                                onChange={handleChange1}
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors.order_date && (
                                <span style={errorStyle}>
                                  {errors.order_date}
                                </span>
                              )}
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t(
                                  "firstform.assessmentdatedetails.firstassdate"
                                )}
                              </label>
                              <input
                                type="date"
                                name="first_asses_date"
                                id="inputPassword5"
                                max={new Date().toISOString().split("T")[0]}
                                value={
                                  formData1?.first_asses_date
                                    ? new Date(formData1.first_asses_date)
                                        .toISOString()
                                        .split("T")[0]
                                    : ""
                                }
                                className="form-control"
                                onChange={handleChange1}
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors.first_asses_date && (
                                <span style={errorStyle}>
                                  {errors.first_asses_date}
                                </span>
                              )}
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t(
                                  "firstform.assessmentdatedetails.primaryassdate"
                                )}
                              </label>
                              <input
                                type="date"
                                max={new Date().toISOString().split("T")[0]}
                                name="primary_asses_date"
                                id="inputPassword5"
                                value={formData1?.primary_asses_date}
                                className="form-control"
                                onChange={handleChange1}
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors.primary_asses_date && (
                                <span style={errorStyle}>
                                  {errors.primary_asses_date}
                                </span>
                              )}
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t(
                                  "firstform.assessmentdatedetails.lastassdate"
                                )}
                              </label>
                              <input
                                type="date"
                                max={new Date().toISOString().split("T")[0]}
                                name="last_asses_date"
                                id="inputPassword5"
                                value={formData1?.last_asses_date}
                                className="form-control"
                                onChange={handleChange1}
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors.last_asses_date && (
                                <span style={errorStyle}>
                                  {errors.last_asses_date}
                                </span>
                              )}
                            </div>

                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t(
                                  "firstform.assessmentdatedetails.constructionenddate"
                                )}
                              </label>
                              <input
                                type="date"
                                name="construction_end_date"
                                max={new Date().toISOString().split("T")[0]}
                                id="inputPassword5"
                                value={formData1?.construction_end_date}
                                className="form-control"
                                onChange={handleChange1}
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors.construction_end_date && (
                                <span style={errorStyle}>
                                  {errors.construction_end_date}
                                </span>
                              )}
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t(
                                  "firstform.assessmentdatedetails.revisedassdate"
                                )}
                              </label>
                              <input
                                type="date"
                                name="revised_asses_date"
                                id="inputPassword5"
                                max={new Date().toISOString().split("T")[0]}
                                value={formData1?.revised_asses_date}
                                className="form-control"
                                onChange={handleChange1}
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors.revised_asses_date && (
                                <span style={errorStyle}>
                                  {errors.revised_asses_date}
                                </span>
                              )}
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                {t(
                                  "firstform.assessmentdatedetails.updatedassdate"
                                )}
                              </label>
                              <input
                                type="date"
                                name="updated_asses_date"
                                max={new Date().toISOString().split("T")[0]}
                                id="inputPassword5"
                                value={formData1?.updated_asses_date}
                                className="form-control"
                                onChange={handleChange1}
                                aria-describedby="passwordHelpBlock"
                              />
                              {errors.updated_asses_date && (
                                <span style={errorStyle}>
                                  {errors.updated_asses_date}
                                </span>
                              )}
                            </div>
                            <div className="line-bottom-class mt-3 "></div>
                            {/* Building Structure */}
                            <div className="mb-5">
                              <div>
                                <h2 className="mt-3">Building Structure</h2>
                              </div>
                              <div className="row">
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 ">
                                  <label
                                    htmlFor="inputPassword5"
                                    className="form-label"
                                  >
                                    {t(
                                      "firstform.buildingstructure.buildingstructure"
                                    )}{" "}
                                    {/* <span className="reuiredstart">*</span> */}
                                  </label>
                                  <Select
                                    name="building_structure"
                                    menuPlacement="top"
                                    className="custom-select mb-2 "
                                    value={formData1?.building_structure}
                                    onFocus={() => {
                                      handleClicks("building_structure");
                                    }}
                                    onChange={(event) => {
                                      handleChange1(event);
                                    }}
                                    options={billingstructure}
                                  />

                                  {errors.building_structure && (
                                    <span style={errorStyle}>
                                      {errors.building_structure}
                                    </span>
                                  )}
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                  <label
                                    htmlFor="inputPassword5"
                                    className="form-label"
                                  >
                                    {t("firstform.buildingstructure.floors")}{" "}
                                    {/* <span className="reuiredstart">*</span> */}
                                  </label>
                                  <input
                                    type="text"
                                    id="inputPassword5"
                                    name="floors"
                                    onChange={handleChange1}
                                    onInput={handleBlur}
                                    value={formData1?.floors}
                                    className="form-control"
                                    aria-describedby="passwordHelpBlock"
                                    placeholder=" "
                                  />

                                  {errors.floors && (
                                    <span style={errorStyle}>
                                      {errors.floors}
                                    </span>
                                  )}
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                  <label
                                    htmlFor="inputPassword5"
                                    className="form-label"
                                  >
                                    {t(
                                      "firstform.buildingstructure.totalfloors"
                                    )}{" "}
                                    {/* <span className="reuiredstart">*</span> */}
                                  </label>
                                  <input
                                    type="text"
                                    id="inputPassword5"
                                    name="total_floors"
                                    value={formData1?.total_floors}
                                    onChange={handleChange1}
                                    onInput={handleBlur}
                                    className="form-control"
                                    aria-describedby="passwordHelpBlock"
                                    placeholder=" "
                                  />

                                  {errors.total_floors && (
                                    <span style={errorStyle}>
                                      {errors.total_floors}
                                    </span>
                                  )}
                                </div>
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                  <label
                                    htmlFor="inputPassword5"
                                    className="form-label"
                                  >
                                    {t("firstform.buildingstructure.remarks")}
                                    <span className="reuiredstart">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    id="inputPassword5"
                                    className="form-control"
                                    name="remarks"
                                    value={formData1?.remarks}
                                    onChange={handleChange1}
                                    onInput={handleBlur}
                                    aria-describedby="passwordHelpBlock"
                                    placeholder=" "
                                  />

                                  {errors.remarks && (
                                    <span style={errorStyle}>
                                      {errors.remarks}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1;
